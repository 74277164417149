<script setup lang="ts">
import { openDialog, useSelectBillingAddressDialog } from '~/ts/dialog'
import { route } from '@/composables/route'
import { useProperty } from '@/composables/useProperty'
import { sleep } from '~/ts/utils/promise'
import { deleteBillingAddress, setFavoriteBillingAddress } from '~/ts/payment/billing-addresses'

type AssignBillingAddressToQuotationData = Domain.BookingCaptainJet.Data.AssignBillingAddressToQuotationData
type BillingAddressData = Domain.BookingCaptainJet.Data.BillingAddressData

const { isOpen, close, properties } = useSelectBillingAddressDialog()

const form = useForm<Partial<AssignBillingAddressToQuotationData>>({
	billing_address_id: properties.value?.quotation.billing_address?.id,
})

const addresses = useProperty<BillingAddressData[]>('billingAddresses')
const canSave = computed(() => !!form.billing_address_id || !properties.value?.quotation?.id)

// When the quotation changes, assign it to the form.
watch(() => properties.value?.quotation, () => form.billing_address_id = properties.value?.quotation.billing_address?.id)

// When we open the modal, if there is no address, prompt to
// create one. Also, fill the selected billing address
// with the user's favorite one if it exists.
whenever(isOpen, () => {
	if (!addresses.value?.length && properties.value?.newIfEmpty) {
		return addNewBillingAddress()
	}

	form.billing_address_id ??= addresses.value?.find(({ is_favorite }) => is_favorite)?.id ?? addresses.value.at(0)?.id
})

// If there is no address anymore, we open the dialog
// to create one, which will be auto-selected.
whenever(() => isOpen.value && addresses.value?.length === 0, () => editBillingAddress())

function isBillingAddressSelected(address: BillingAddressData) {
	return address.id === form.billing_address_id
}

function selectBillingAddress(address?: BillingAddressData) {
	if (address) {
		form.billing_address_id = address.id
	}
}

function confirmSelectedBillingAddress() {
	if (!canSave.value) {
		return
	}

	form.put(route('captainjet.web.quotations.billing-address.store', { quotation: properties.value?.quotation.id }), {
		// TODO For some reason the request is sometimes cancelled, so we cannot use onSuccess there.
		// I suspect an Inertia bug.
		onFinish: () => {
			close()
			form.reset()
			properties.value?.onSelect?.()
		},
	})
}

async function editBillingAddress(billingAddress?: BillingAddressData) {
	close()
	// Timeout is necessary: https://github.com/tailwindlabs/headlessui/issues/1744
	await sleep(250)
	openDialog('edit-billing-address', {
		address: billingAddress,
		onClose: () => {
			if (addresses.value?.length) {
				setTimeout(() => openDialog('select-billing-address', properties.value), 250)
			}
		},
		onSave: (addressId) => {
			const select = addressId
				? addresses.value?.find(({ id }) => id === addressId)
				: addresses.value?.at(-1)

			selectBillingAddress(select)
		},
	})
}

function addNewBillingAddress() {
	editBillingAddress()
}
</script>

<template>
	<BaseDialog
		:show="isOpen"
		title="Select billing address"
		title-size="sm"
		@close="() => close"
	>
		<form @submit.prevent="confirmSelectedBillingAddress">
			<div class="border-t border-turquoise-100 p-6">
				<div class="-mx-4 max-h-[50vh] space-y-4 overflow-y-auto overflow-x-hidden pl-4 pr-3 lg:max-h-[35vh]">
					<div v-if="addresses?.length" class="space-y-3.5">
						<BookingBillingAddress
							v-for="address in addresses"
							:key="address.id"
							:address="address"
							:selected="isBillingAddressSelected(address)"
							:can-select="true"
							@set-favorite="setFavoriteBillingAddress(address)"
							@toggle="selectBillingAddress(address)"
							@remove="deleteBillingAddress(address)"
							@edit="editBillingAddress(address)"
						/>
					</div>
				</div>

				<div class="mt-4 flex justify-center">
					<BaseButton
						class="space-x-2 px-6 text-sm sm:text-base"
						variant="secondary"
						size="md"
						icon="Plus"
						icon-class="w-3.5 h-3.5"
						@click="addNewBillingAddress()"
					>
						Add new billing address
					</BaseButton>
				</div>

				<BaseButton
					type="submit"
					size="lg"
					class="mt-6 w-full font-display text-lg tracking-tight"
					variant="primary"
					:disabled="!canSave"
					:loading="form.processing"
				>
					Save
				</BaseButton>
			</div>
		</form>
	</BaseDialog>
</template>
