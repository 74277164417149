import axios from 'axios'

import type { Message } from './types'

import { route } from '@/composables/route'

/** Fetches messages from the API. */
export async function fetchMessages(token: string, id?: number) {
	const response = await axios.get<Message[]>(route('captainjet.web.api.chat.messages.show', { token, id }))
	return response.data ?? []
}
