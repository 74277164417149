<template>
	<svg viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m1 1 5.5 4.889L12 1"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
		/>
	</svg>
</template>
