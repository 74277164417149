<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

import { useProperty } from '@/composables/useProperty'
import pilatusFlight from '~/images/captainjet/company-links/pilatus-flight.webp'
import WhatsAppIcon from '@/components/CompanyLinks/Icon/WhatsApp.vue'
import PhoneIcon from '@/components/CompanyLinks/Icon/Phone.vue'
import MailIcon from '@/components/CompanyLinks/Icon/Mail.vue'

const breakpoints = useBreakpoints(breakpointsTailwind)
const phoneNumber = useProperty('security.features.phone_number')
const phoneNumberClean = computed(() => phoneNumber.value?.replaceAll(' ', ''))
const phoneNumberWhatsApp = computed(() => phoneNumberClean.value?.replaceAll('+', ''))
const charterEmails = useProperty('security.features.charter_emails')
const charterEmail = computed(() => charterEmails.value[0])
</script>

<template>
	<div class="group relative flex flex-col overflow-hidden rounded-2xl px-6 py-5">
		<div class="absolute inset-0 transition-transform duration-500 ease-out group-hover:scale-[108%]">
			<img
				:src="pilatusFlight"
				alt=""
				class="absolute inset-0 size-full -scale-x-100 object-cover sm:scale-x-100"
				:style="{
					objectPosition: breakpoints.sm ? 'right 30%' : 'right center',
				}"
			/>
		</div>
		<div class="relative flex flex-1 flex-col justify-between gap-y-24 text-white sm:gap-y-12">
			<div class="flex flex-col gap-y-3">
				<h3 class="text-lg font-medium uppercase leading-5 tracking-[3.6px] sm:text-sm">
					Reach out to us
				</h3>
				<span class="font-inter text-[40px] font-medium leading-[109%]">At your disposal 24/7</span>
			</div>
			<div class="flex flex-col gap-5 sm:flex-row">
				<CompanyLinksContactButton
					:icon="WhatsAppIcon"
					name="WhatsApp"
					:href="`https://wa.me/${phoneNumberWhatsApp}`"
				/>
				<CompanyLinksContactButton
					:icon="PhoneIcon"
					name="Phone"
					:href="`tel:${phoneNumberClean}`"
				/>
				<CompanyLinksContactButton
					:icon="MailIcon"
					name="Email"
					:href="`mailto:${charterEmail}`"
				/>
			</div>
		</div>
	</div>
</template>
