<template>
	<div class="relative overflow-hidden bg-white py-7 sm:py-10 lg:pb-18 lg:pt-14">
		<MarketingContainer class="relative">
			<div class="relative z-10 mx-auto flex max-w-2xl flex-col lg:max-w-none">
				<div>
					<p class="text-center font-inter text-3xl font-medium text-blue-500">
						CaptainJet is part of a leading <span class="font-bold">aviation group in Europe</span>.
					</p>
					<div class="lg:grid-6 mt-8 grid grid-cols-2 justify-items-center gap-4 text-blue-500 sm:mt-14 lg:mt-8 lg:grid-cols-6 lg:gap-8">
						<a href="https://jetfly.com/">
							<IconLogoJetfly class="h-20 shrink-0" />
						</a>
						<a href="https://www.jetfly-technik.com/">
							<IconLogoJetflyTechnik class="h-20 shrink-0" />
						</a>
						<a href="https://fly7.com/">
							<IconLogoFly7 class="h-20 shrink-0" />
						</a>
						<a href="https://fly7-training.com/">
							<IconLogoFly7Training class="h-20 shrink-0" />
						</a>
						<InertiaLink href="/about">
							<IconLogoCaptianJet class="h-20 shrink-0" />
						</InertiaLink>
						<a href="https://www.mylittleplane.com/">
							<IconLogoMyLittlePlane class="h-20 shrink-0" />
						</a>
					</div>
				</div>
				<!-- Desktop ticket -->
				<div class="ticket-shadow relative mr-[311px] mt-14 hidden rounded-l-2xl rounded-r-sm lg:block">
					<!-- Right side -->
					<div
						class="absolute top-0 h-full w-[312px] pl-4"
						:style="{ left: 'calc(100% - 1px)' }"
					>
						<!-- Ticket shape -->
						<svg class="ticket-shadow absolute inset-0 size-full fill-white" viewBox="0 0 311 227" preserveAspectRatio="none">
							<path d="M31.32.01c0 8.84-7.18 16-16.04 16S0 8.84 0 .01v226.95c0-8.83 7.68-16 16.54-16s16.04 7.17 16.04 16h251.16c8.99 0 13.48 0 16.9-1.74a16.02 16.02 0 0 0 7.02-7c1.75-3.41 1.75-7.9 1.75-16.85l.75-175.76c0-8.96 0-13.44-1.75-16.86-1.54-3.01-4-5.46-7.01-7C297.97.02 293.47.02 284.49.02H31.32Z" />
						</svg>
						<!-- Tear line -->
						<div class="absolute inset-y-[8%] left-[5.1%] w-1 border-l-2 border-dashed border-turquoise-100" />
						<!-- Content -->
						<GroupTicketStubContent class="relative flex h-full flex-col justify-center" />
					</div>
					<!-- Left side -->
					<div class="relative flex min-h-[227px] items-center gap-16 rounded-l-2xl rounded-r-sm bg-white py-11 pl-16 pr-14">
						<IconAircraftFlying class="-my-5 w-44 shrink-0 text-turquoise-100" />
						<div class="flex flex-col font-nova text-base font-medium text-blue-400">
							<p class="uppercase leading-8 tracking-[0.155em] text-warning-500">
								Get ready for your next private jet trip
							</p>
							<p class="mt-4">
								Booking your private charter flight through our app is easy, quick and hassle-free.
							</p>
							<p class="mt-2">
								Looking for assistance? Our friendly and reliable brokers are available around the clock through Whatsapp, phone, email and chat.
							</p>
						</div>
					</div>
				</div>
				<!-- Mobile ticket -->
				<div class="ticket-shadow relative mb-[212px] mt-14 w-full max-w-[440px] self-center rounded-b-sm rounded-t-2xl lg:hidden">
					<!-- Bottom end -->
					<div
						class="absolute left-0 h-[213px] w-full pt-4"
						:style="{ top: 'calc(100% - 1px)' }"
					>
						<!-- Ticket shape -->
						<svg class="ticket-shadow absolute inset-0 size-full fill-white" viewBox="0 0 384 212" preserveAspectRatio="none">
							<path d="M383.11 31.24c-8.83 0-16-7.18-16-16.04s7.17-15.2 16-15.2H.16c8.84 0 16 7.6 16 16.46 0 8.86-7.16 16.04-16 16.04v152.24c0 8.99 0 13.48 1.75 16.91a16.02 16.02 0 0 0 6.99 7.01c3.42 1.75 7.9 1.75 16.86 1.75l331.75.75c8.97 0 13.45 0 16.87-1.75 3-1.54 5.46-4 6.99-7.01 1.74-3.43 1.74-7.92 1.74-16.9V31.23Z" />
						</svg>
						<!-- Tear line -->
						<div class="absolute inset-x-[5.5%] top-[7%] h-1 border-t-2 border-dashed border-turquoise-100" />
						<!-- Content -->
						<GroupTicketStubContent class="relative flex h-full flex-col justify-center" />
					</div>
					<!-- Top end -->
					<div class="relative flex min-h-[227px] flex-col gap-8 rounded-b-sm rounded-t-2xl bg-white px-7 pb-6 pt-14">
						<IconAircraftFlying class="-my-12 w-44 shrink-0 self-center text-turquoise-100" />
						<div class="flex flex-col font-nova text-base font-medium text-blue-400">
							<p class="uppercase leading-8 tracking-[0.155em] text-warning-500">
								Get ready for your next trip
							</p>
							<p class="mt-4">
								Booking your private charter flight through our app is easy, quick and hassle-free.
							</p>
							<p class="mt-2">
								Looking for assistance? Our friendly and reliable brokers are available around the clock through Whatsapp, phone, email and chat.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="absolute inset-0 hidden lg:block">
				<IconPattern
					class="-ml-40 -mt-4 h-[1100px] w-[1140px] -translate-y-1/3 rotate-[-15deg]"
				/>
			</div>
		</MarketingContainer>
	</div>
</template>

<style lang="postcss">
div.ticket-shadow {
	box-shadow:
		0px 0px 1px 0px #00000032,
		0px 20px 80px 0px #00000024;
}
svg.ticket-shadow {
	filter: drop-shadow(0px 0px 1px #00000032)
		drop-shadow(0px 20px 80px #00000024);
}
</style>
