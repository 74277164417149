<script setup lang="ts">
import { route } from '@/composables/route'
import Settings from '@/layouts/settings.vue'
import { openDialog } from '~/ts/dialog'
import { deleteBillingAddress, setFavoriteBillingAddress } from '~/ts/payment/billing-addresses'
import { deleteCreditCard, setDefaultCreditCard } from '~/ts/payment/credit-cards'

type PaymentMethodData = Domain.Payment.Data.PaymentMethodData
type BillingAddressData = Domain.BookingCaptainJet.Data.BillingAddressData
type PaymentData = Domain.Payment.Data.PaymentData

defineProps<{
	cards: PaymentMethodData[]
	addresses: BillingAddressData[]
	invoices: Paginator<PaymentData>
}>()
</script>


			<script lang="ts">
			import layout from '@/layouts/default.vue'
			export default { layout }
			</script>
			<template>
		
	<Settings current="payment">
		<div class="space-y-4">
			<!-- Credit cards -->
			<SettingsCard v-auto-animate title="Credit cards">
				<!-- Credit card list -->
				<div v-if="cards.length > 0" v-auto-animate class="grid gap-4 pb-2 sm:grid-cols-2">
					<CreditCard
						v-for="card in cards"
						:key="card.id"
						:card="card"
						@delete="deleteCreditCard(card)"
						@set-default="setDefaultCreditCard(card)"
					/>
				</div>

				<!-- No credit card -->
				<div v-else class="flex flex-col items-center justify-center">
					<IllustrationCreditCard class="w-24" />
					<p class="mt-2 text-center text-blue-300">
						You do not have a registered credit card yet
					</p>
				</div>

				<!-- Add credit card -->
				<div class="mt-4 flex justify-center">
					<BaseButton
						:variant="cards.length > 0 ? 'secondary' : 'primary'"
						size="xs"
						icon="Plus"
						icon-class="h-3.5 w-3.5 shrink-0"
						class="px-6 tracking-tight"
						@click="openDialog('new-credit-card')"
					>
						Add new credit card
					</BaseButton>
				</div>
			</SettingsCard>

			<!-- Billing addresses -->
			<SettingsCard v-auto-animate title="Billing addresses">
				<!-- Billing addresses list -->
				<div v-if="addresses.length > 0" v-auto-animate class="grid gap-4 pb-2 sm:grid-cols-2">
					<BookingBillingAddress
						v-for="address in addresses"
						:key="address.id"
						:address="address"
						@set-favorite="setFavoriteBillingAddress(address)"
						@remove="deleteBillingAddress(address)"
						@edit="openDialog('edit-billing-address', { address })"
					/>
				</div>

				<!-- No billing address -->
				<div v-else class="flex flex-col items-center justify-center">
					<IllustrationBillingAddress class="w-24" />
					<p class="mt-2 text-center text-blue-300">
						You do not have any billing address yet
					</p>
				</div>

				<!-- Add billing address -->
				<div class="mt-4 flex justify-center">
					<BaseButton
						:variant="addresses.length > 0 ? 'secondary' : 'primary'"
						size="xs"
						icon="Plus"
						icon-class="h-3.5 w-3.5 shrink-0"
						class="px-6 tracking-tight"
						@click="openDialog('edit-billing-address')"
					>
						Add a billing address
					</BaseButton>
				</div>
			</SettingsCard>

			<!-- Invoices -->
			<SettingsCard v-auto-animate title="Invoices">
				<!-- Invoice list -->
				<div v-if="invoices.data.length > 0" v-auto-animate class="space-y-4">
					<SettingsInvoice
						v-for="invoice in invoices.data"
						:key="invoice.description + invoice.date"
						:invoice="invoice"
					/>
				</div>

				<!-- No invoices -->
				<div v-else>
					<div class="flex flex-col items-center justify-center">
						<IllustrationInvoices class="w-24" />
						<p class="mt-2 text-center text-blue-300">
							You do not have any invoice yet
						</p>
					</div>

					<!-- Book a flight -->
					<div class="mt-4 flex justify-center">
						<BaseButton
							as="InertiaLink"
							variant="primary"
							size="xs"
							class="px-6 tracking-tight"
							:href="route('captainjet.web.index')"
						>
							Book your next trip
						</BaseButton>
					</div>
				</div>
			</SettingsCard>
		</div>
	</Settings>
</template>
