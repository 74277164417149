<script setup lang="ts">
import Slider from '@vueform/slider'

const $props = defineProps<{ modelValue: number }>()

defineEmits(['update:modelValue'])

function addTickmarks() {
	const parent = document.querySelector('.slider-connects')

	for (let i = 1; i < 5; i++) {
		const el = document.createElement('div')
		el.className = 'input-slider-tickmark'
		const left = Math.round(i * (parent!.clientWidth / 5))
		el.setAttribute('style', `left:${left}px;`)
		parent?.appendChild(el)
	}
}

onMounted(() => {
	addTickmarks()
})

watch(() => $props.modelValue, (val) => {
	setTimeout(() => {
		const tickmark = document.querySelector('.slider-connects .input-slider-tickmark')
		if (val === 0 && !tickmark) {
			addTickmarks()
		}
	}, 0)
})
</script>

<template>
	<Slider
		:model-value="modelValue"
		:lazy="false"
		:tooltips="false"
		:min="0"
		:max="100"
		@update:model-value="(value: boolean) => $emit('update:modelValue', value)"
	/>
</template>

<style lang="postcss">
.slider-target,
.slider-target * {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	@apply select-none touch-none;
}

.slider-target {
	@apply relative;
}

.slider-base,
.slider-connects {
	@apply h-full w-full z-10 relative;
}

.slider-connects {
	@apply h-full w-full z-0 absolute right-0 top-0 overflow-hidden border border-turquoise-100;
}

.slider-connect,
.slider-origin {
	@apply h-full w-full z-10 absolute right-0 top-0 will-change-transform origin-top-left transform-gpu;
}

.slider-txt-dir-rtl.slider-horizontal .slider-origin {
	@apply right-auto left-0;
}

.slider-horizontal .slider-origin {
	@apply h-0;
}

.slider-touch-area {
	@apply w-full h-full;
}

.slider-state-tap .slider-connect,
.slider-state-tap .slider-origin {
	@apply transition-transform;
}

.slider-horizontal {
	@apply h-2;
}

.slider-base {
	@apply bg-white;
}

.slider-base,
.slider-connects {
	@apply rounded-full;
}

.slider-connect {
	@apply cursor-pointer bg-turquoise-500;
}

.slider-handle {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	box-shadow: 0px 2px 4px rgba(78, 97, 101, 0.18);
	@apply absolute  -top-2.5 -right-3.5 focus:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-600 transition-colors duration-75 cursor-pointer rounded-full w-7 h-7 border border-turquoise-200 hover:border-turquoise-300 bg-white after:h-2 after:w-2 after:rounded-full after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-turquoise-500 after:z-20;
}

.input-slider-tickmark {
	@apply absolute h-1.5 w-0.5 bg-turquoise-300 z-20 cursor-pointer;
}
</style>
