<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 440">
		<mask
			id="search-header-bg-a"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
		>
			<path fill="#45413F" d="M0 0h1440v439.209H0z" />
		</mask>
		<g mask="url(#search-header-bg-a)">
			<path fill="#BED9DF" d="M1440-125.666H0V510.13h1440z" />
			<path
				transform="matrix(.96266 .27071 -.43091 .9024 34.528 -245.858)"
				fill="url(#search-header-bg-b)"
				d="M0 0h1610.01v794.89H0z"
				style="mix-blend-mode: multiply"
			/>
		</g>
		<defs>
			<linearGradient
				id="search-header-bg-b"
				x1="422.896"
				y1="710.213"
				x2="632.333"
				y2="-98.128"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset=".344" stop-color="#2D91A8" />
				<stop offset="1" stop-color="#fff" stop-opacity="0" />
			</linearGradient>
		</defs>
	</svg>
</template>
