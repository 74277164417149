<script lang="ts" setup>
import { router } from '@inertiajs/vue3'
import { useModal } from 'momentum-modal'

import BaseInertiaDialog from '@/components/BaseInertiaDialog.vue'
import { route } from '@/composables/route'
import { useWizard } from '@/composables/useWizard'
import { match } from '~/ts/utils/match'

type LegData = Domain.BookingCaptainJet.Data.LegData
type PassengerData = Domain.BookingCaptainJet.Data.PassengerData
type TravelDocumentData = Domain.BookingCaptainJet.Data.TravelDocumentData
type AttachPassengerToLegData = Domain.BookingCaptainJet.Data.AttachPassengerToLegData

const props = defineProps<{
	back?: string
	leg: LegData
	passengers: Paginator<PassengerData>
}>()

/*
|--------------------------------------------------------------------------
| Initial passenger selection
|--------------------------------------------------------------------------
*/

const searchQuery = ref('')
const selectedPassengerIds = ref<Set<number>>(new Set(...[props.leg.passengers?.map(({ id }) => id)]))
const selectedPassengers = computed(() => props.passengers.data.filter((passenger) => selectedPassengerIds.value.has(passenger.id)))

// This search works by separating the query into keyword. If all of the keywords
// match at least one of a passenger's term, then the passenger is matched.
const filteredPassengers = computed(() => props.passengers.data.filter((passenger: PassengerData) => {
	const terms: Array<keyof PassengerData> = ['full_name', 'species', 'gender', 'type']
	const queries = searchQuery.value.toLowerCase().split(' ')

	return queries.every((query) =>
		terms.some((term) => String(passenger[term] ?? '').toLowerCase().includes(query)),
	)
}))

function createPassenger() {
	router.visit(route('captainjet.web.leg.passengers.create', {
		leg: props.leg,
	}), { preserveScroll: true })
}

function togglePassenger(passenger: PassengerData) {
	if (selectedPassengerIds.value.has(passenger.id)) {
		selectedPassengerIds.value.delete(passenger.id)
	} else {
		selectedPassengerIds.value.add(passenger.id)
	}
}

function editPassenger(passenger: PassengerData) {
	selectedPassengers.value.push(passenger)
}

function deletePassenger(passenger: PassengerData) {
	router.delete(route('captainjet.web.passenger.destroy', { passenger, leg: props.leg.id }))
}

function isPassengerSelected(passenger: PassengerData) {
	return selectedPassengerIds.value.has(passenger.id)
}

/*
|--------------------------------------------------------------------------
| Document selection
|--------------------------------------------------------------------------
*/

const selectedDocuments = ref<Record<number, number | undefined>>({})

function isDocumentSelected(passenger: PassengerData, document: TravelDocumentData) {
	return selectedDocuments.value[passenger.id] === document.id
}

function toggleDocument(passenger: PassengerData, document?: TravelDocumentData) {
	if (!document) {
		return
	}

	selectedDocuments.value[passenger.id] = isDocumentSelected(passenger, document)
		? undefined
		: document.id
}

/*
|--------------------------------------------------------------------------
| Wizard
|--------------------------------------------------------------------------
*/

const wizard = useWizard([
	'select-passengers',
	'select-documents',
] as const)

const metadata = computed(() => match(wizard.current.value, {
	'select-passengers': {
		title: 'Add passenger',
		subtitle: `${selectedPassengerIds.value.size}/${props.leg.passenger_count}`,
		canContinue: selectedPassengerIds.value.size === props.leg.passenger_count,
		continue: () => wizard.goTo('select-documents'),
	},
	'select-documents': {
		title: 'Select document',
		canContinue: [...selectedPassengerIds.value].every((id) => !!selectedDocuments.value[id]),
		back: () => wizard.goTo('select-passengers'),
		continue: () => {
			const passengers: AttachPassengerToLegData[] = Object.entries(selectedDocuments.value).map(([passengerId, documentId]) => ({
				id: Number(passengerId)!,
				travel_document_id: documentId!,
			}))

			useModal().close()

			router.post(route('captainjet.web.leg.passengers.attach', { leg: props.leg, back: props.back }), {
				passengers: passengers as any,
			}, { preserveScroll: true })
		},
	},
}))
</script>

<template>
	<BaseInertiaDialog
		title-size="sm"
		:title="metadata.title"
		:subtitle="metadata.subtitle"
	>
		<template #back>
			<button
				v-if="metadata.back"
				class="absolute left-6 flex items-center justify-center space-x-2 text-sm text-blue-200"
				type="button"
				@click="metadata.back"
			>
				<icon-chevron-narrow-left class="w-3.5" />
				<span>Back</span>
			</button>
		</template>

		<div class="flex min-h-[460px] flex-col border-t border-turquoise-100">
			<template v-if="wizard.active('select-passengers')">
				<!-- No contacts -->
				<div v-if="!passengers.data.length" class="flex flex-1 flex-col items-center justify-center">
					<illustration-passengers class="h-18" />
					<p class="mt-6 tracking-tighter text-blue-300">
						You have no contact yet
					</p>
					<base-button
						size="md"
						class="mt-4 space-x-2 px-4 text-15"
						icon="Plus"
						icon-class="w-3.5 h-3.5"
						variant="primary"
						@click="createPassenger"
					>
						Create passenger
					</base-button>
				</div>

				<!-- Contacts -->
				<div v-else class="flex flex-1 flex-col">
					<!-- input -->
					<div class="px-5 py-4">
						<div class="group relative h-10 w-full">
							<input
								v-model="searchQuery"
								class="size-full rounded-full border border-turquoise-100 bg-turquoise-50 pl-11 pr-5 font-medium text-blue-400 transition-colors duration-75 placeholder-shown:text-sm placeholder-shown:font-normal placeholder-shown:italic placeholder-shown:text-blue-200 focus:border-turquoise-500 group-hover:border-turquoise-300 focus:group-hover:border-turquoise-500"
								placeholder="Search name, surname"
								type="text"
							/>
							<div class="pointer-events-none absolute inset-y-0 left-4 flex items-center text-blue-200">
								<icon-search2 class="size-4 shrink-0" />
							</div>
						</div>
					</div>

					<!-- Contact selection -->
					<div class="flex max-h-[310px] flex-1 flex-col overflow-y-auto border-y border-turquoise-100 px-6 py-3.5">
						<div class="flex-1">
							<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
								{{ `${filteredPassengers.length} contact${filteredPassengers.length === 1 ? '' : 's'}` }}
							</span>

							<div class="mt-3 space-y-3.5">
								<BookingPassengerEntry
									v-for="(passenger, index) in filteredPassengers"
									:key="index"
									:passenger="passenger"
									:selected="isPassengerSelected(passenger)"
									@toggle="togglePassenger(passenger)"
									@edit="editPassenger(passenger)"
									@remove="deletePassenger(passenger)"
								/>
							</div>
						</div>
					</div>

					<div class="flex space-x-4 px-6 py-4">
						<base-button
							variant="secondary"
							class="flex-1 space-x-2.5"
							icon="Plus"
							icon-class="w-3.5 h-3.5"
							@click="createPassenger"
						>
							Create passenger
						</base-button>
						<base-button
							class="flex-1"
							variant="primary"
							:disabled="!metadata.canContinue"
							@click="metadata.continue"
						>
							<div class="absolute inset-y-0 right-5 flex items-center">
								<icon-chevron-right class="h-3.5" />
							</div>
							<span class="mr-2">Continue</span>
						</base-button>
					</div>
				</div>
			</template>

			<template v-if="wizard.active('select-documents')">
				<div class="flex flex-1 flex-col border-b p-4">
					<div
						v-for="(passenger, i) in selectedPassengers"
						:key="passenger.id"
						:class="{
							'mt-3': i !== 0,
							'border-b': i < selectedPassengers.length - 1,
						}"
					>
						<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200" v-text="passenger.full_name" />
						<div class="mb-4 mt-3 flex flex-col space-y-3">
							<BookingPassengerDocument
								v-for="(document, id) in passenger.travel_documents"
								:key="id + document.type"
								class="w-full"
								:document="document"
								:selected="isDocumentSelected(passenger, document)"
								:is-removable="false"
								@toggle="toggleDocument(passenger, document)"
							/>
						</div>
					</div>
				</div>

				<!-- Continue -->
				<div class="flex space-x-4 px-6 py-4">
					<base-button
						class="flex-1"
						variant="primary"
						:disabled="!metadata.canContinue"
						@click="metadata.continue"
					>
						Save
					</base-button>
				</div>
			</template>
		</div>
	</BaseInertiaDialog>
</template>
