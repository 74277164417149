<script setup lang="ts">
import { router, useForm } from '@inertiajs/vue3'
import { SwitchGroup, SwitchLabel } from '@headlessui/vue'

import { useEditBillingAddressDialog } from '~/ts/dialog'
import { route } from '@/composables/route'

type StoreBillingAddressData = Domain.BookingCaptainJet.Data.StoreBillingAddressData

const { isOpen, close, properties } = useEditBillingAddressDialog()
const isEditing = computed(() => !!properties.value?.address)
const title = computed(() => isEditing.value ? 'Update billing address' : 'New billing address')

const form = useForm<StoreBillingAddressData & { is_favorite: boolean }>({
	first_name: '',
	last_name: '',
	company_name: '',
	company_address: '',
	vat_number: '',
	is_favorite: false,
})

watch(isOpen, () => {
	const address = properties.value?.address

	if (!address) {
		form.reset()
		return
	}

	form.first_name = address.first_name
	form.last_name = address.last_name
	form.company_name = address.company_name
	form.company_address = address.company_address
	form.vat_number = address.vat_number
	form.is_favorite = address.is_favorite
})

const canSubmit = computed(() => {
	const isEmpty = form.first_name === ''
		|| form.last_name === ''
		|| form.company_address === ''

	return isEditing.value
		? form.isDirty
		: !isEmpty
})

function saveBillingAddress() {
	if (!canSubmit.value) {
		return
	}

	const url = isEditing.value
		? route('captainjet.web.billing-address.update', { billingAddress: properties.value?.address!.id })
		: route('captainjet.web.billing-address.store')

	router.visit(url, {
		method: isEditing.value ? 'put' : 'post',
		preserveState: true,
		data: form.data(),
		onSuccess: () => {
			closeDialog()
			properties?.value?.onSave?.(properties.value?.address?.id)
		},
	})
}

function closeDialog() {
	properties.value?.onClose?.()
	close()
	form.reset()
}
</script>

<template>
	<BaseDialog
		:show="isOpen"
		:title="title"
		title-size="sm"
		@close="closeDialog"
	>
		<form @submit.prevent="saveBillingAddress">
			<div class="space-y-5 border-t border-turquoise-100 p-6">
				<div class="space-y-3.5">
					<BaseInput
						id="first_name"
						v-model="form.first_name"
						name="first_name"
						label="First name*"
						type="text"
						:error="form.errors.first_name"
						:clearable="form.first_name !== ''"
						@clear="form.first_name = ''; form.clearErrors('first_name')"
					/>

					<BaseInput
						id="last_name"
						v-model="form.last_name"
						name="last_name"
						label="Last name*"
						type="text"
						:error="form.errors.last_name"
						:clearable="form.last_name !== ''"
						@clear="form.last_name = ''; form.clearErrors('last_name')"
					/>

					<BaseInput
						id="company_name"
						v-model="form.company_name"
						name="company_name"
						label="Company name"
						type="text"
						:error="form.errors.company_name"
						:clearable="form.company_name !== ''"
						@clear="form.company_name = ''; form.clearErrors('company_name')"
					/>

					<BaseInput
						id="company_address"
						v-model="form.company_address"
						name="company_address"
						label="Address*"
						type="textarea"
						:error="form.errors.company_address"
						:clearable="form.company_address !== ''"
						@clear="form.company_address = ''; form.clearErrors('company_address')"
					/>

					<BaseInput
						id="vat_number"
						v-model="form.vat_number"
						name="vat_number"
						label="VAT number"
						type="text"
						:error="form.errors.vat_number"
						:clearable="form.vat_number !== ''"
						@clear="form.vat_number = ''; form.clearErrors('vat_number')"
					/>
				</div>

				<SwitchGroup as="div" class="flex items-center justify-between rounded-xl border border-turquoise-100 px-4">
					<SwitchLabel as="div" class="flex grow cursor-default items-center space-x-2 py-5 font-medium tracking-tighter text-blue-500">
						<icon-star-outline class="size-5" />
						<span>Favorite address</span>
					</SwitchLabel>

					<input-switch v-model="form.is_favorite" label="Favorite address" />
				</SwitchGroup>

				<BaseButton
					variant="primary"
					type="submit"
					size="lg"
					class="w-full font-display text-lg font-bold tracking-tight"
					:disabled="!canSubmit"
					:loading="form.processing"
				>
					Save
				</BaseButton>
			</div>
		</form>
	</BaseDialog>
</template>
