<template>
	<svg
		width="284"
		height="284"
		viewBox="0 0 284 284"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M10 10H18V18H10V10Z" fill="currentColor" />
		<path d="M10 18H18V26H10V18Z" fill="currentColor" />
		<path d="M10 26H18V34H10V26Z" fill="currentColor" />
		<path d="M10 34H18V42H10V34Z" fill="currentColor" />
		<path d="M10 42H18V50H10V42Z" fill="currentColor" />
		<path d="M10 50H18V58H10V50Z" fill="currentColor" />
		<path d="M10 58H18V66H10V58Z" fill="currentColor" />
		<path d="M10 82H18V90H10V82Z" fill="currentColor" />
		<path d="M10 90H18V98H10V90Z" fill="currentColor" />
		<path d="M10 98H18V106H10V98Z" fill="currentColor" />
		<path d="M10 106H18V114H10V106Z" fill="currentColor" />
		<path d="M10 114H18V122H10V114Z" fill="currentColor" />
		<path d="M10 138H18V146H10V138Z" fill="currentColor" />
		<path d="M10 154H18V162H10V154Z" fill="currentColor" />
		<path d="M10 178H18V186H10V178Z" fill="currentColor" />
		<path d="M10 194H18V202H10V194Z" fill="currentColor" />
		<path d="M10 218H18V226H10V218Z" fill="currentColor" />
		<path d="M10 226H18V234H10V226Z" fill="currentColor" />
		<path d="M10 234H18V242H10V234Z" fill="currentColor" />
		<path d="M10 242H18V250H10V242Z" fill="currentColor" />
		<path d="M10 250H18V258H10V250Z" fill="currentColor" />
		<path d="M10 258H18V266H10V258Z" fill="currentColor" />
		<path d="M10 266H18V274H10V266Z" fill="currentColor" />
		<path d="M18 10H26V18H18V10Z" fill="currentColor" />
		<path d="M18 58H26V66H18V58Z" fill="currentColor" />
		<path d="M18 82H26V90H18V82Z" fill="currentColor" />
		<path d="M18 90H26V98H18V90Z" fill="currentColor" />
		<path d="M18 98H26V106H18V98Z" fill="currentColor" />
		<path d="M18 106H26V114H18V106Z" fill="currentColor" />
		<path d="M18 130H26V138H18V130Z" fill="currentColor" />
		<path d="M18 154H26V162H18V154Z" fill="currentColor" />
		<path d="M18 170H26V178H18V170Z" fill="currentColor" />
		<path d="M18 202H26V210H18V202Z" fill="currentColor" />
		<path d="M18 218H26V226H18V218Z" fill="currentColor" />
		<path d="M18 266H26V274H18V266Z" fill="currentColor" />
		<path d="M26 10H34V18H26V10Z" fill="currentColor" />
		<path d="M26 26H34V34H26V26Z" fill="currentColor" />
		<path d="M26 34H34V42H26V34Z" fill="currentColor" />
		<path d="M26 42H34V50H26V42Z" fill="currentColor" />
		<path d="M26 58H34V66H26V58Z" fill="currentColor" />
		<path d="M26 82H34V90H26V82Z" fill="currentColor" />
		<path d="M26 90H34V98H26V90Z" fill="currentColor" />
		<path d="M26 98H34V106H26V98Z" fill="currentColor" />
		<path d="M26 106H34V114H26V106Z" fill="currentColor" />
		<path d="M26 130H34V138H26V130Z" fill="currentColor" />
		<path d="M26 146H34V154H26V146Z" fill="currentColor" />
		<path d="M26 162H34V170H26V162Z" fill="currentColor" />
		<path d="M26 170H34V178H26V170Z" fill="currentColor" />
		<path d="M26 194H34V202H26V194Z" fill="currentColor" />
		<path d="M26 218H34V226H26V218Z" fill="currentColor" />
		<path d="M26 234H34V242H26V234Z" fill="currentColor" />
		<path d="M26 242H34V250H26V242Z" fill="currentColor" />
		<path d="M26 250H34V258H26V250Z" fill="currentColor" />
		<path d="M26 266H34V274H26V266Z" fill="currentColor" />
		<path d="M34 10H42V18H34V10Z" fill="currentColor" />
		<path d="M34 26H42V34H34V26Z" fill="currentColor" />
		<path d="M34 34H42V42H34V34Z" fill="currentColor" />
		<path d="M34 42H42V50H34V42Z" fill="currentColor" />
		<path d="M34 58H42V66H34V58Z" fill="currentColor" />
		<path d="M34 74H42V82H34V74Z" fill="currentColor" />
		<path d="M34 82H42V90H34V82Z" fill="currentColor" />
		<path d="M34 98H42V106H34V98Z" fill="currentColor" />
		<path d="M34 106H42V114H34V106Z" fill="currentColor" />
		<path d="M34 114H42V122H34V114Z" fill="currentColor" />
		<path d="M34 122H42V130H34V122Z" fill="#E94E4E" />
		<path d="M34 146H42V154H34V146Z" fill="currentColor" />
		<path d="M34 154H42V162H34V154Z" fill="currentColor" />
		<path d="M34 162H42V170H34V162Z" fill="currentColor" />
		<path d="M34 202H42V210H34V202Z" fill="currentColor" />
		<path d="M34 218H42V226H34V218Z" fill="currentColor" />
		<path d="M34 234H42V242H34V234Z" fill="currentColor" />
		<path d="M34 242H42V250H34V242Z" fill="currentColor" />
		<path d="M34 250H42V258H34V250Z" fill="currentColor" />
		<path d="M34 266H42V274H34V266Z" fill="currentColor" />
		<path d="M42 10H50V18H42V10Z" fill="currentColor" />
		<path d="M42 26H50V34H42V26Z" fill="currentColor" />
		<path d="M42 34H50V42H42V34Z" fill="currentColor" />
		<path d="M42 42H50V50H42V42Z" fill="currentColor" />
		<path d="M42 58H50V66H42V58Z" fill="currentColor" />
		<path d="M42 90H50V98H42V90Z" fill="currentColor" />
		<path d="M42 114H50V122H42V114Z" fill="currentColor" />
		<path d="M42 122H50V130H42V122Z" fill="currentColor" />
		<path d="M42 146H50V154H42V146Z" fill="currentColor" />
		<path d="M42 154H50V162H42V154Z" fill="currentColor" />
		<path d="M42 170H50V178H42V170Z" fill="currentColor" />
		<path d="M42 186H50V194H42V186Z" fill="currentColor" />
		<path d="M42 194H50V202H42V194Z" fill="currentColor" />
		<path d="M42 218H50V226H42V218Z" fill="currentColor" />
		<path d="M42 234H50V242H42V234Z" fill="currentColor" />
		<path d="M42 242H50V250H42V242Z" fill="currentColor" />
		<path d="M42 250H50V258H42V250Z" fill="currentColor" />
		<path d="M42 266H50V274H42V266Z" fill="currentColor" />
		<path d="M50 10H58V18H50V10Z" fill="currentColor" />
		<path d="M50 58H58V66H50V58Z" fill="currentColor" />
		<path d="M50 98H58V106H50V98Z" fill="currentColor" />
		<path d="M50 106H58V114H50V106Z" fill="currentColor" />
		<path d="M50 130H58V138H50V130Z" fill="currentColor" />
		<path d="M50 138H58V146H50V138Z" fill="currentColor" />
		<path d="M50 146H58V154H50V146Z" fill="currentColor" />
		<path d="M50 154H58V162H50V154Z" fill="currentColor" />
		<path d="M50 162H58V170H50V162Z" fill="currentColor" />
		<path d="M50 170H58V178H50V170Z" fill="currentColor" />
		<path d="M50 178H58V186H50V178Z" fill="currentColor" />
		<path d="M50 202H58V210H50V202Z" fill="currentColor" />
		<path d="M50 218H58V226H50V218Z" fill="currentColor" />
		<path d="M50 266H58V274H50V266Z" fill="currentColor" />
		<path d="M58 10H66V18H58V10Z" fill="currentColor" />
		<path d="M58 18H66V26H58V18Z" fill="currentColor" />
		<path d="M58 26H66V34H58V26Z" fill="currentColor" />
		<path d="M58 34H66V42H58V34Z" fill="currentColor" />
		<path d="M58 42H66V50H58V42Z" fill="currentColor" />
		<path d="M58 50H66V58H58V50Z" fill="currentColor" />
		<path d="M58 58H66V66H58V58Z" fill="currentColor" />
		<path d="M58 74H66V82H58V74Z" fill="currentColor" />
		<path d="M58 90H66V98H58V90Z" fill="currentColor" />
		<path d="M58 106H66V114H58V106Z" fill="currentColor" />
		<path d="M58 122H66V130H58V122Z" fill="currentColor" />
		<path d="M58 138H66V146H58V138Z" fill="currentColor" />
		<path d="M58 154H66V162H58V154Z" fill="currentColor" />
		<path d="M58 170H66V178H58V170Z" fill="currentColor" />
		<path d="M58 186H66V194H58V186Z" fill="currentColor" />
		<path d="M58 202H66V210H58V202Z" fill="currentColor" />
		<path d="M58 218H66V226H58V218Z" fill="currentColor" />
		<path d="M58 226H66V234H58V226Z" fill="currentColor" />
		<path d="M58 234H66V242H58V234Z" fill="currentColor" />
		<path d="M58 242H66V250H58V242Z" fill="currentColor" />
		<path d="M58 250H66V258H58V250Z" fill="currentColor" />
		<path d="M58 258H66V266H58V258Z" fill="currentColor" />
		<path d="M58 266H66V274H58V266Z" fill="currentColor" />
		<path d="M66 82H74V90H66V82Z" fill="currentColor" />
		<path d="M66 106H74V114H66V106Z" fill="currentColor" />
		<path d="M66 114H74V122H66V114Z" fill="currentColor" />
		<path d="M66 146H74V154H66V146Z" fill="currentColor" />
		<path d="M66 170H74V178H66V170Z" fill="currentColor" />
		<path d="M66 186H74V194H66V186Z" fill="currentColor" />
		<path d="M66 194H74V202H66V194Z" fill="currentColor" />
		<path d="M74 10H82V18H74V10Z" fill="currentColor" />
		<path d="M74 18H82V26H74V18Z" fill="currentColor" />
		<path d="M74 34H82V42H74V34Z" fill="currentColor" />
		<path d="M74 42H82V50H74V42Z" fill="currentColor" />
		<path d="M74 50H82V58H74V50Z" fill="currentColor" />
		<path d="M74 58H82V66H74V58Z" fill="currentColor" />
		<path d="M74 98H82V106H74V98Z" fill="currentColor" />
		<path d="M74 114H82V122H74V114Z" fill="currentColor" />
		<path d="M74 122H82V130H74V122Z" fill="currentColor" />
		<path d="M74 130H82V138H74V130Z" fill="currentColor" />
		<path d="M74 138H82V146H74V138Z" fill="currentColor" />
		<path d="M74 146H82V154H74V146Z" fill="currentColor" />
		<path d="M74 170H82V178H74V170Z" fill="currentColor" />
		<path d="M74 194H82V202H74V194Z" fill="currentColor" />
		<path d="M74 210H82V218H74V210Z" fill="currentColor" />
		<path d="M74 242H82V250H74V242Z" fill="currentColor" />
		<path d="M82 10H90V18H82V10Z" fill="currentColor" />
		<path d="M82 26H90V34H82V26Z" fill="currentColor" />
		<path d="M82 34H90V42H82V34Z" fill="currentColor" />
		<path d="M82 50H90V58H82V50Z" fill="currentColor" />
		<path d="M82 66H90V74H82V66Z" fill="currentColor" />
		<path d="M82 98H90V106H82V98Z" fill="currentColor" />
		<path d="M82 106H90V114H82V106Z" fill="currentColor" />
		<path d="M82 114H90V122H82V114Z" fill="currentColor" />
		<path d="M82 130H90V138H82V130Z" fill="currentColor" />
		<path d="M82 146H90V154H82V146Z" fill="currentColor" />
		<path d="M82 154H90V162H82V154Z" fill="currentColor" />
		<path d="M82 162H90V170H82V162Z" fill="currentColor" />
		<path d="M82 170H90V178H82V170Z" fill="currentColor" />
		<path d="M82 186H90V194H82V186Z" fill="currentColor" />
		<path d="M82 194H90V202H82V194Z" fill="currentColor" />
		<path d="M82 202H90V210H82V202Z" fill="currentColor" />
		<path d="M82 234H90V242H82V234Z" fill="currentColor" />
		<path d="M82 242H90V250H82V242Z" fill="currentColor" />
		<path d="M82 250H90V258H82V250Z" fill="currentColor" />
		<path d="M82 258H90V266H82V258Z" fill="currentColor" />
		<path d="M90 18H98V26H90V18Z" fill="currentColor" />
		<path d="M90 50H98V58H90V50Z" fill="currentColor" />
		<path d="M90 58H98V66H90V58Z" fill="currentColor" />
		<path d="M90 66H98V74H90V66Z" fill="currentColor" />
		<path d="M90 74H98V82H90V74Z" fill="currentColor" />
		<path d="M90 82H98V90H90V82Z" fill="currentColor" />
		<path d="M90 90H98V98H90V90Z" fill="currentColor" />
		<path d="M90 98H98V106H90V98Z" fill="currentColor" />
		<path d="M90 122H98V130H90V122Z" fill="currentColor" />
		<path d="M90 130H98V138H90V130Z" fill="currentColor" />
		<path d="M90 146H98V154H90V146Z" fill="currentColor" />
		<path d="M90 162H98V170H90V162Z" fill="currentColor" />
		<path d="M90 178H98V186H90V178Z" fill="currentColor" />
		<path d="M90 186H98V194H90V186Z" fill="currentColor" />
		<path d="M90 194H98V202H90V194Z" fill="currentColor" />
		<path d="M90 210H98V218H90V210Z" fill="currentColor" />
		<path d="M90 226H98V234H90V226Z" fill="currentColor" />
		<path d="M90 234H98V242H90V234Z" fill="currentColor" />
		<path d="M90 242H98V250H90V242Z" fill="currentColor" />
		<path d="M90 250H98V258H90V250Z" fill="currentColor" />
		<path d="M98 18H106V26H98V18Z" fill="currentColor" />
		<path d="M98 34H106V42H98V34Z" fill="currentColor" />
		<path d="M98 42H106V50H98V42Z" fill="currentColor" />
		<path d="M98 74H106V82H98V74Z" fill="currentColor" />
		<path d="M98 98H106V106H98V98Z" fill="currentColor" />
		<path d="M98 106H106V114H98V106Z" fill="currentColor" />
		<path d="M98 122H106V130H98V122Z" fill="currentColor" />
		<path d="M98 154H106V162H98V154Z" fill="currentColor" />
		<path d="M98 162H106V170H98V162Z" fill="currentColor" />
		<path d="M98 186H106V194H98V186Z" fill="currentColor" />
		<path d="M98 194H106V202H98V194Z" fill="currentColor" />
		<path d="M98 210H106V218H98V210Z" fill="currentColor" />
		<path d="M98 226H106V234H98V226Z" fill="currentColor" />
		<path d="M98 242H106V250H98V242Z" fill="currentColor" />
		<path d="M98 250H106V258H98V250Z" fill="currentColor" />
		<path d="M98 258H106V266H98V258Z" fill="currentColor" />
		<path d="M98 266H106V274H98V266Z" fill="currentColor" />
		<path d="M106 10H114V18H106V10Z" fill="currentColor" />
		<path d="M106 26H114V34H106V26Z" fill="currentColor" />
		<path d="M106 42H114V50H106V42Z" fill="currentColor" />
		<path d="M106 58H114V66H106V58Z" fill="currentColor" />
		<path d="M106 66H114V74H106V66Z" fill="currentColor" />
		<path d="M106 74H114V82H106V74Z" fill="currentColor" />
		<path d="M106 82H114V90H106V82Z" fill="currentColor" />
		<path d="M106 90H114V98H106V90Z" fill="currentColor" />
		<path d="M106 98H114V106H106V98Z" fill="currentColor" />
		<path d="M106 106H114V114H106V106Z" fill="currentColor" />
		<path d="M106 122H114V130H106V122Z" fill="currentColor" />
		<path d="M106 130H114V138H106V130Z" fill="currentColor" />
		<path d="M106 138H114V146H106V138Z" fill="currentColor" />
		<path d="M106 186H114V194H106V186Z" fill="currentColor" />
		<path d="M106 194H114V202H106V194Z" fill="currentColor" />
		<path d="M106 202H114V210H106V202Z" fill="currentColor" />
		<path d="M106 210H114V218H106V210Z" fill="currentColor" />
		<path d="M106 226H114V234H106V226Z" fill="currentColor" />
		<path d="M106 234H114V242H106V234Z" fill="currentColor" />
		<path d="M106 258H114V266H106V258Z" fill="currentColor" />
		<path d="M106 266H114V274H106V266Z" fill="currentColor" />
		<path d="M114 18H122V26H114V18Z" fill="currentColor" />
		<path d="M114 26H122V34H114V26Z" fill="currentColor" />
		<path d="M114 34H122V42H114V34Z" fill="currentColor" />
		<path d="M114 50H122V58H114V50Z" fill="currentColor" />
		<path d="M114 66H122V74H114V66Z" fill="currentColor" />
		<path d="M114 90H122V98H114V90Z" fill="currentColor" />
		<path d="M114 98H122V106H114V98Z" fill="currentColor" />
		<path d="M114 106H122V114H114V106Z" fill="currentColor" />
		<path d="M114 122H122V130H114V122Z" fill="currentColor" />
		<path d="M114 146H122V154H114V146Z" fill="currentColor" />
		<path d="M114 154H122V162H114V154Z" fill="currentColor" />
		<path d="M114 170H122V178H114V170Z" fill="currentColor" />
		<path d="M114 178H122V186H114V178Z" fill="currentColor" />
		<path d="M114 186H122V194H114V186Z" fill="currentColor" />
		<path d="M114 202H122V210H114V202Z" fill="currentColor" />
		<path d="M114 210H122V218H114V210Z" fill="currentColor" />
		<path d="M114 226H122V234H114V226Z" fill="currentColor" />
		<path d="M114 234H122V242H114V234Z" fill="currentColor" />
		<path d="M114 242H122V250H114V242Z" fill="currentColor" />
		<path d="M114 258H122V266H114V258Z" fill="currentColor" />
		<path d="M114 266H122V274H114V266Z" fill="currentColor" />
		<path d="M122 10H130V18H122V10Z" fill="currentColor" />
		<path d="M122 58H130V66H122V58Z" fill="currentColor" />
		<path d="M122 66H130V74H122V66Z" fill="currentColor" />
		<path d="M122 82H130V90H122V82Z" fill="currentColor" />
		<path d="M122 114H130V122H122V114Z" fill="currentColor" />
		<path d="M122 122H130V130H122V122Z" fill="currentColor" />
		<path d="M122 130H130V138H122V130Z" fill="currentColor" />
		<path d="M122 146H130V154H122V146Z" fill="currentColor" />
		<path d="M122 154H130V162H122V154Z" fill="currentColor" />
		<path d="M122 162H130V170H122V162Z" fill="currentColor" />
		<path d="M122 170H130V178H122V170Z" fill="currentColor" />
		<path d="M122 178H130V186H122V178Z" fill="currentColor" />
		<path d="M122 194H130V202H122V194Z" fill="currentColor" />
		<path d="M122 210H130V218H122V210Z" fill="currentColor" />
		<path d="M122 250H130V258H122V250Z" fill="currentColor" />
		<path d="M122 258H130V266H122V258Z" fill="currentColor" />
		<path d="M130 10H138V18H130V10Z" fill="currentColor" />
		<path d="M130 18H138V26H130V18Z" fill="currentColor" />
		<path d="M130 34H138V42H130V34Z" fill="currentColor" />
		<path d="M130 42H138V50H130V42Z" fill="currentColor" />
		<path d="M130 66H138V74H130V66Z" fill="currentColor" />
		<path d="M130 74H138V82H130V74Z" fill="currentColor" />
		<path d="M130 90H138V98H130V90Z" fill="currentColor" />
		<path d="M130 106H138V114H130V106Z" fill="currentColor" />
		<path d="M130 114H138V122H130V114Z" fill="currentColor" />
		<path d="M130 122H138V130H130V122Z" fill="currentColor" />
		<path d="M130 130H138V138H130V130Z" fill="currentColor" />
		<path d="M130 154H138V162H130V154Z" fill="currentColor" />
		<path d="M130 162H138V170H130V162Z" fill="currentColor" />
		<path d="M130 170H138V178H130V170Z" fill="currentColor" />
		<path d="M130 178H138V186H130V178Z" fill="currentColor" />
		<path d="M130 202H138V210H130V202Z" fill="currentColor" />
		<path d="M130 210H138V218H130V210Z" fill="currentColor" />
		<path d="M130 218H138V226H130V218Z" fill="currentColor" />
		<path d="M130 234H138V242H130V234Z" fill="currentColor" />
		<path d="M130 250H138V258H130V250Z" fill="currentColor" />
		<path d="M130 258H138V266H130V258Z" fill="currentColor" />
		<path d="M138 26H146V34H138V26Z" fill="currentColor" />
		<path d="M138 42H146V50H138V42Z" fill="currentColor" />
		<path d="M138 50H146V58H138V50Z" fill="currentColor" />
		<path d="M138 58H146V66H138V58Z" fill="currentColor" />
		<path d="M138 66H146V74H138V66Z" fill="currentColor" />
		<path d="M138 90H146V98H138V90Z" fill="currentColor" />
		<path d="M138 130H146V138H138V130Z" fill="currentColor" />
		<path d="M138 138H146V146H138V138Z" fill="currentColor" />
		<path d="M138 146H146V154H138V146Z" fill="currentColor" />
		<path d="M138 154H146V162H138V154Z" fill="currentColor" />
		<path d="M138 186H146V194H138V186Z" fill="currentColor" />
		<path d="M138 194H146V202H138V194Z" fill="currentColor" />
		<path d="M138 210H146V218H138V210Z" fill="currentColor" />
		<path d="M138 218H146V226H138V218Z" fill="currentColor" />
		<path d="M138 242H146V250H138V242Z" fill="currentColor" />
		<path d="M138 250H146V258H138V250Z" fill="currentColor" />
		<path d="M138 266H146V274H138V266Z" fill="currentColor" />
		<path d="M146 26H154V34H146V26Z" fill="currentColor" />
		<path d="M146 42H154V50H146V42Z" fill="currentColor" />
		<path d="M146 66H154V74H146V66Z" fill="currentColor" />
		<path d="M146 98H154V106H146V98Z" fill="currentColor" />
		<path d="M146 106H154V114H146V106Z" fill="currentColor" />
		<path d="M146 122H154V130H146V122Z" fill="currentColor" />
		<path d="M146 130H154V138H146V130Z" fill="currentColor" />
		<path d="M146 138H154V146H146V138Z" fill="currentColor" />
		<path d="M146 154H154V162H146V154Z" fill="currentColor" />
		<path d="M146 170H154V178H146V170Z" fill="currentColor" />
		<path d="M146 178H154V186H146V178Z" fill="currentColor" />
		<path d="M146 186H154V194H146V186Z" fill="currentColor" />
		<path d="M146 194H154V202H146V194Z" fill="currentColor" />
		<path d="M146 234H154V242H146V234Z" fill="currentColor" />
		<path d="M146 242H154V250H146V242Z" fill="currentColor" />
		<path d="M146 266H154V274H146V266Z" fill="currentColor" />
		<path d="M154 18H162V26H154V18Z" fill="currentColor" />
		<path d="M154 42H162V50H154V42Z" fill="currentColor" />
		<path d="M154 58H162V66H154V58Z" fill="currentColor" />
		<path d="M154 106H162V114H154V106Z" fill="currentColor" />
		<path d="M154 138H162V146H154V138Z" fill="currentColor" />
		<path d="M154 146H162V154H154V146Z" fill="currentColor" />
		<path d="M154 162H162V170H154V162Z" fill="currentColor" />
		<path d="M154 178H162V186H154V178Z" fill="currentColor" />
		<path d="M154 194H162V202H154V194Z" fill="currentColor" />
		<path d="M154 210H162V218H154V210Z" fill="currentColor" />
		<path d="M154 218H162V226H154V218Z" fill="currentColor" />
		<path d="M154 226H162V234H154V226Z" fill="currentColor" />
		<path d="M154 234H162V242H154V234Z" fill="currentColor" />
		<path d="M154 250H162V258H154V250Z" fill="currentColor" />
		<path d="M154 258H162V266H154V258Z" fill="currentColor" />
		<path d="M162 18H170V26H162V18Z" fill="currentColor" />
		<path d="M162 34H170V42H162V34Z" fill="currentColor" />
		<path d="M162 82H170V90H162V82Z" fill="currentColor" />
		<path d="M162 90H170V98H162V90Z" fill="currentColor" />
		<path d="M162 122H170V130H162V122Z" fill="currentColor" />
		<path d="M162 130H170V138H162V130Z" fill="currentColor" />
		<path d="M162 138H170V146H162V138Z" fill="currentColor" />
		<path d="M162 154H170V162H162V154Z" fill="currentColor" />
		<path d="M162 194H170V202H162V194Z" fill="currentColor" />
		<path d="M162 202H170V210H162V202Z" fill="currentColor" />
		<path d="M162 218H170V226H162V218Z" fill="currentColor" />
		<path d="M162 226H170V234H162V226Z" fill="currentColor" />
		<path d="M162 250H170V258H162V250Z" fill="currentColor" />
		<path d="M162 266H170V274H162V266Z" fill="currentColor" />
		<path d="M170 26H178V34H170V26Z" fill="currentColor" />
		<path d="M170 42H178V50H170V42Z" fill="currentColor" />
		<path d="M170 50H178V58H170V50Z" fill="currentColor" />
		<path d="M170 58H178V66H170V58Z" fill="currentColor" />
		<path d="M170 82H178V90H170V82Z" fill="currentColor" />
		<path d="M170 90H178V98H170V90Z" fill="currentColor" />
		<path d="M170 106H178V114H170V106Z" fill="currentColor" />
		<path d="M170 130H178V138H170V130Z" fill="currentColor" />
		<path d="M170 138H178V146H170V138Z" fill="currentColor" />
		<path d="M170 154H178V162H170V154Z" fill="currentColor" />
		<path d="M170 162H178V170H170V162Z" fill="currentColor" />
		<path d="M170 170H178V178H170V170Z" fill="currentColor" />
		<path d="M170 178H178V186H170V178Z" fill="currentColor" />
		<path d="M170 186H178V194H170V186Z" fill="currentColor" />
		<path d="M170 218H178V226H170V218Z" fill="currentColor" />
		<path d="M170 226H178V234H170V226Z" fill="currentColor" />
		<path d="M178 10H186V18H178V10Z" fill="currentColor" />
		<path d="M178 50H186V58H178V50Z" fill="currentColor" />
		<path d="M178 66H186V74H178V66Z" fill="currentColor" />
		<path d="M178 74H186V82H178V74Z" fill="currentColor" />
		<path d="M178 90H186V98H178V90Z" fill="currentColor" />
		<path d="M178 122H186V130H178V122Z" fill="currentColor" />
		<path d="M178 130H186V138H178V130Z" fill="currentColor" />
		<path d="M178 138H186V146H178V138Z" fill="currentColor" />
		<path d="M178 154H186V162H178V154Z" fill="currentColor" />
		<path d="M178 162H186V170H178V162Z" fill="currentColor" />
		<path d="M178 170H186V178H178V170Z" fill="currentColor" />
		<path d="M178 178H186V186H178V178Z" fill="currentColor" />
		<path d="M178 194H186V202H178V194Z" fill="currentColor" />
		<path d="M178 202H186V210H178V202Z" fill="currentColor" />
		<path d="M178 210H186V218H178V210Z" fill="currentColor" />
		<path d="M178 226H186V234H178V226Z" fill="currentColor" />
		<path d="M178 250H186V258H178V250Z" fill="currentColor" />
		<path d="M186 10H194V18H186V10Z" fill="currentColor" />
		<path d="M186 26H194V34H186V26Z" fill="currentColor" />
		<path d="M186 34H194V42H186V34Z" fill="currentColor" />
		<path d="M186 58H194V66H186V58Z" fill="currentColor" />
		<path d="M186 82H194V90H186V82Z" fill="currentColor" />
		<path d="M186 90H194V98H186V90Z" fill="currentColor" />
		<path d="M186 114H194V122H186V114Z" fill="currentColor" />
		<path d="M186 130H194V138H186V130Z" fill="currentColor" />
		<path d="M186 154H194V162H186V154Z" fill="currentColor" />
		<path d="M186 162H194V170H186V162Z" fill="currentColor" />
		<path d="M186 218H194V226H186V218Z" fill="currentColor" />
		<path d="M186 226H194V234H186V226Z" fill="currentColor" />
		<path d="M186 242H194V250H186V242Z" fill="currentColor" />
		<path d="M186 258H194V266H186V258Z" fill="currentColor" />
		<path d="M194 10H202V18H194V10Z" fill="currentColor" />
		<path d="M194 18H202V26H194V18Z" fill="currentColor" />
		<path d="M194 66H202V74H194V66Z" fill="currentColor" />
		<path d="M194 74H202V82H194V74Z" fill="currentColor" />
		<path d="M194 98H202V106H194V98Z" fill="currentColor" />
		<path d="M194 106H202V114H194V106Z" fill="currentColor" />
		<path d="M194 122H202V130H194V122Z" fill="currentColor" />
		<path d="M194 130H202V138H194V130Z" fill="currentColor" />
		<path d="M194 138H202V146H194V138Z" fill="currentColor" />
		<path d="M194 146H202V154H194V146Z" fill="currentColor" />
		<path d="M194 170H202V178H194V170Z" fill="currentColor" />
		<path d="M194 178H202V186H194V178Z" fill="currentColor" />
		<path d="M194 186H202V194H194V186Z" fill="currentColor" />
		<path d="M194 194H202V202H194V194Z" fill="currentColor" />
		<path d="M194 202H202V210H194V202Z" fill="currentColor" />
		<path d="M194 210H202V218H194V210Z" fill="currentColor" />
		<path d="M194 226H202V234H194V226Z" fill="currentColor" />
		<path d="M194 242H202V250H194V242Z" fill="currentColor" />
		<path d="M194 250H202V258H194V250Z" fill="currentColor" />
		<path d="M194 258H202V266H194V258Z" fill="currentColor" />
		<path d="M202 10H210V18H202V10Z" fill="currentColor" />
		<path d="M202 26H210V34H202V26Z" fill="currentColor" />
		<path d="M202 34H210V42H202V34Z" fill="currentColor" />
		<path d="M202 42H210V50H202V42Z" fill="currentColor" />
		<path d="M202 50H210V58H202V50Z" fill="currentColor" />
		<path d="M202 58H210V66H202V58Z" fill="currentColor" />
		<path d="M202 74H210V82H202V74Z" fill="currentColor" />
		<path d="M202 82H210V90H202V82Z" fill="currentColor" />
		<path d="M202 98H210V106H202V98Z" fill="currentColor" />
		<path d="M202 106H210V114H202V106Z" fill="currentColor" />
		<path d="M202 114H210V122H202V114Z" fill="currentColor" />
		<path d="M202 170H210V178H202V170Z" fill="currentColor" />
		<path d="M202 178H210V186H202V178Z" fill="currentColor" />
		<path d="M202 186H210V194H202V186Z" fill="currentColor" />
		<path d="M202 194H210V202H202V194Z" fill="currentColor" />
		<path d="M202 202H210V210H202V202Z" fill="currentColor" />
		<path d="M202 210H210V218H202V210Z" fill="currentColor" />
		<path d="M202 218H210V226H202V218Z" fill="currentColor" />
		<path d="M202 226H210V234H202V226Z" fill="currentColor" />
		<path d="M202 234H210V242H202V234Z" fill="currentColor" />
		<path d="M202 258H210V266H202V258Z" fill="currentColor" />
		<path d="M202 266H210V274H202V266Z" fill="currentColor" />
		<path d="M210 98H218V106H210V98Z" fill="currentColor" />
		<path d="M210 106H218V114H210V106Z" fill="currentColor" />
		<path d="M210 122H218V130H210V122Z" fill="currentColor" />
		<path d="M210 146H218V154H210V146Z" fill="currentColor" />
		<path d="M210 162H218V170H210V162Z" fill="currentColor" />
		<path d="M210 186H218V194H210V186Z" fill="currentColor" />
		<path d="M210 194H218V202H210V194Z" fill="currentColor" />
		<path d="M210 202H218V210H210V202Z" fill="currentColor" />
		<path d="M210 234H218V242H210V234Z" fill="currentColor" />
		<path d="M210 250H218V258H210V250Z" fill="currentColor" />
		<path d="M210 266H218V274H210V266Z" fill="currentColor" />
		<path d="M218 10H226V18H218V10Z" fill="currentColor" />
		<path d="M218 18H226V26H218V18Z" fill="currentColor" />
		<path d="M218 26H226V34H218V26Z" fill="currentColor" />
		<path d="M218 34H226V42H218V34Z" fill="currentColor" />
		<path d="M218 42H226V50H218V42Z" fill="currentColor" />
		<path d="M218 50H226V58H218V50Z" fill="currentColor" />
		<path d="M218 58H226V66H218V58Z" fill="currentColor" />
		<path d="M218 82H226V90H218V82Z" fill="currentColor" />
		<path d="M218 90H226V98H218V90Z" fill="currentColor" />
		<path d="M218 98H226V106H218V98Z" fill="currentColor" />
		<path d="M218 106H226V114H218V106Z" fill="currentColor" />
		<path d="M218 114H226V122H218V114Z" fill="currentColor" />
		<path d="M218 122H226V130H218V122Z" fill="currentColor" />
		<path d="M218 130H226V138H218V130Z" fill="currentColor" />
		<path d="M218 138H226V146H218V138Z" fill="currentColor" />
		<path d="M218 154H226V162H218V154Z" fill="currentColor" />
		<path d="M218 186H226V194H218V186Z" fill="currentColor" />
		<path d="M218 194H226V202H218V194Z" fill="currentColor" />
		<path d="M218 202H226V210H218V202Z" fill="currentColor" />
		<path d="M218 218H226V226H218V218Z" fill="currentColor" />
		<path d="M218 234H226V242H218V234Z" fill="currentColor" />
		<path d="M218 242H226V250H218V242Z" fill="currentColor" />
		<path d="M226 10H234V18H226V10Z" fill="currentColor" />
		<path d="M226 58H234V66H226V58Z" fill="currentColor" />
		<path d="M226 74H234V82H226V74Z" fill="currentColor" />
		<path d="M226 154H234V162H226V154Z" fill="currentColor" />
		<path d="M226 162H234V170H226V162Z" fill="currentColor" />
		<path d="M226 170H234V178H226V170Z" fill="currentColor" />
		<path d="M226 194H234V202H226V194Z" fill="currentColor" />
		<path d="M226 202H234V210H226V202Z" fill="currentColor" />
		<path d="M226 234H234V242H226V234Z" fill="currentColor" />
		<path d="M226 250H234V258H226V250Z" fill="currentColor" />
		<path d="M226 258H234V266H226V258Z" fill="currentColor" />
		<path d="M226 266H234V274H226V266Z" fill="currentColor" />
		<path d="M234 10H242V18H234V10Z" fill="currentColor" />
		<path d="M234 26H242V34H234V26Z" fill="currentColor" />
		<path d="M234 34H242V42H234V34Z" fill="currentColor" />
		<path d="M234 42H242V50H234V42Z" fill="currentColor" />
		<path d="M234 58H242V66H234V58Z" fill="currentColor" />
		<path d="M234 74H242V82H234V74Z" fill="currentColor" />
		<path d="M234 106H242V114H234V106Z" fill="currentColor" />
		<path d="M234 130H242V138H234V130Z" fill="currentColor" />
		<path d="M234 138H242V146H234V138Z" fill="currentColor" />
		<path d="M234 162H242V170H234V162Z" fill="currentColor" />
		<path d="M234 202H242V210H234V202Z" fill="currentColor" />
		<path d="M234 210H242V218H234V210Z" fill="currentColor" />
		<path d="M234 218H242V226H234V218Z" fill="currentColor" />
		<path d="M234 226H242V234H234V226Z" fill="currentColor" />
		<path d="M234 234H242V242H234V234Z" fill="currentColor" />
		<path d="M234 242H242V250H234V242Z" fill="currentColor" />
		<path d="M234 250H242V258H234V250Z" fill="currentColor" />
		<path d="M234 258H242V266H234V258Z" fill="currentColor" />
		<path d="M234 266H242V274H234V266Z" fill="currentColor" />
		<path d="M242 10H250V18H242V10Z" fill="currentColor" />
		<path d="M242 26H250V34H242V26Z" fill="currentColor" />
		<path d="M242 34H250V42H242V34Z" fill="currentColor" />
		<path d="M242 42H250V50H242V42Z" fill="currentColor" />
		<path d="M242 58H250V66H242V58Z" fill="currentColor" />
		<path d="M242 74H250V82H242V74Z" fill="currentColor" />
		<path d="M242 122H250V130H242V122Z" fill="currentColor" />
		<path d="M242 178H250V186H242V178Z" fill="currentColor" />
		<path d="M242 186H250V194H242V186Z" fill="currentColor" />
		<path d="M242 194H250V202H242V194Z" fill="currentColor" />
		<path d="M242 202H250V210H242V202Z" fill="currentColor" />
		<path d="M242 218H250V226H242V218Z" fill="currentColor" />
		<path d="M242 250H250V258H242V250Z" fill="currentColor" />
		<path d="M242 258H250V266H242V258Z" fill="currentColor" />
		<path d="M250 10H258V18H250V10Z" fill="currentColor" />
		<path d="M250 26H258V34H250V26Z" fill="currentColor" />
		<path d="M250 34H258V42H250V34Z" fill="currentColor" />
		<path d="M250 42H258V50H250V42Z" fill="currentColor" />
		<path d="M250 58H258V66H250V58Z" fill="currentColor" />
		<path d="M250 90H258V98H250V90Z" fill="currentColor" />
		<path d="M250 114H258V122H250V114Z" fill="currentColor" />
		<path d="M250 130H258V138H250V130Z" fill="currentColor" />
		<path d="M250 138H258V146H250V138Z" fill="currentColor" />
		<path d="M250 146H258V154H250V146Z" fill="currentColor" />
		<path d="M250 154H258V162H250V154Z" fill="currentColor" />
		<path d="M250 186H258V194H250V186Z" fill="currentColor" />
		<path d="M250 210H258V218H250V210Z" fill="currentColor" />
		<path d="M250 250H258V258H250V250Z" fill="currentColor" />
		<path d="M250 266H258V274H250V266Z" fill="currentColor" />
		<path d="M258 10H266V18H258V10Z" fill="currentColor" />
		<path d="M258 58H266V66H258V58Z" fill="currentColor" />
		<path d="M258 74H266V82H258V74Z" fill="currentColor" />
		<path d="M258 82H266V90H258V82Z" fill="currentColor" />
		<path d="M258 122H266V130H258V122Z" fill="currentColor" />
		<path d="M258 130H266V138H258V130Z" fill="currentColor" />
		<path d="M258 138H266V146H258V138Z" fill="currentColor" />
		<path d="M258 146H266V154H258V146Z" fill="currentColor" />
		<path d="M258 202H266V210H258V202Z" fill="currentColor" />
		<path d="M258 218H266V226H258V218Z" fill="currentColor" />
		<path d="M258 266H266V274H258V266Z" fill="currentColor" />
		<path d="M266 10H274V18H266V10Z" fill="currentColor" />
		<path d="M266 18H274V26H266V18Z" fill="currentColor" />
		<path d="M266 26H274V34H266V26Z" fill="currentColor" />
		<path d="M266 34H274V42H266V34Z" fill="currentColor" />
		<path d="M266 42H274V50H266V42Z" fill="currentColor" />
		<path d="M266 50H274V58H266V50Z" fill="currentColor" />
		<path d="M266 58H274V66H266V58Z" fill="currentColor" />
		<path d="M266 74H274V82H266V74Z" fill="currentColor" />
		<path d="M266 82H274V90H266V82Z" fill="currentColor" />
		<path d="M266 90H274V98H266V90Z" fill="currentColor" />
		<path d="M266 106H274V114H266V106Z" fill="currentColor" />
		<path d="M266 114H274V122H266V114Z" fill="currentColor" />
		<path d="M266 130H274V138H266V130Z" fill="currentColor" />
		<path d="M266 146H274V154H266V146Z" fill="currentColor" />
		<path d="M266 154H274V162H266V154Z" fill="currentColor" />
		<path d="M266 178H274V186H266V178Z" fill="currentColor" />
		<path d="M266 186H274V194H266V186Z" fill="currentColor" />
		<path d="M266 194H274V202H266V194Z" fill="currentColor" />
		<path d="M266 202H274V210H266V202Z" fill="currentColor" />
		<path d="M266 210H274V218H266V210Z" fill="currentColor" />
		<path d="M266 226H274V234H266V226Z" fill="currentColor" />
		<path d="M266 242H274V250H266V242Z" fill="currentColor" />
		<path d="M266 250H274V258H266V250Z" fill="currentColor" />
	</svg>
</template>
