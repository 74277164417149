<script setup lang="ts">
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'

import type { Notification } from '~/ts/notifications/types'

import { match } from '~/ts/utils/match'

const props = defineProps<{ notification: Notification }>()

defineEmits(['dismiss'])

/** Gets icon data depending on the notification type. */
const icon = computed(() => {
	return {
		component: match(props.notification.type, {
			success: CheckCircleIcon,
			error: XCircleIcon,
			warning: ExclamationCircleIcon,
			info: InformationCircleIcon,
		}),
		color: match(props.notification.type, {
			success: 'text-green-600',
			error: 'text-red-600',
			warning: 'text-orange-600',
			info: 'text-turquoise-600',
		}),
	}
})
</script>

<template>
	<div class="pointer-events-auto w-full overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-black/5">
		<div class="p-4">
			<div class="flex items-start" :class="{ 'items-center': !notification.content }">
				<div class="shrink-0">
					<component
						:is="icon.component"
						class="size-6"
						:class="icon.color"
						aria-hidden="true"
					/>
				</div>
				<div class="ml-3 w-0 flex-1 space-y-1" :class="{ 'pt-0.5': !!notification.content }">
					<p
						v-if="notification.title"
						class="text-sm font-medium text-gray-900"
						v-text="notification.title"
					/>
					<p
						v-if="notification.content"
						class="text-sm text-gray-500"
						:class="{ 'font-medium': !notification.title }"
						v-text="notification.content"
					/>
				</div>
				<div class="ml-4 flex shrink-0 self-center">
					<button
						v-if="notification.closeable !== false"
						class="flex justify-center rounded-md bg-white text-gray-400 transition hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
						@click.prevent.stop.capture="$emit('dismiss')"
					>
						<span class="sr-only" v-text="'Close'" />
						<IconX class="h-3" aria-hidden="true" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
