<template>
	<svg viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M17.4141 9.86097C16.9997 10.2653 17.1379 10.8044 17.4141 11.0739C17.5522 11.2087 17.8284 11.3435 17.9665 11.3435C18.2428 11.3435 18.519 11.2087 18.6571 11.0739C19.3477 10.2653 20.3145 8.10891 18.6571 5.95252C18.2428 5.41342 18.2428 5.0091 18.2428 4.60478C18.519 3.52659 19.6239 2.71794 20.0382 2.4484C20.4526 2.17885 20.5907 1.63975 20.3145 1.23543C20.0382 0.831106 19.4858 0.696332 19.0714 0.96588C18.7952 1.10065 16.9997 2.31362 16.5854 4.20046C16.4473 5.14388 16.5854 6.08729 17.276 7.03071C18.519 8.51323 17.5522 9.72619 17.4141 9.86097Z" fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.3148 12.6912H2.22182C1.25502 12.6912 0.564453 13.3651 0.564453 14.3085V16.5997C0.564453 17.5431 1.25502 18.2169 2.22182 18.2169H20.3148C20.8672 18.2169 21.4197 17.6779 21.4197 17.1388V13.6346C21.2816 13.0955 20.8672 12.6912 20.3148 12.6912ZM2.35994 14.4433H14.2377V16.4649H2.35994V14.4433ZM19.4861 16.3301H17.2763V14.3085H19.4861V16.3301Z"
			fill="currentColor"
		/>
		<path d="M21.0052 4.33529C21.4196 4.06574 21.972 4.06574 22.2483 4.47006C24.0438 6.49167 23.3532 8.64806 22.6626 9.4567C22.5245 9.72625 22.2483 9.86102 21.972 9.86102C21.6958 9.86102 21.5577 9.86102 21.4196 9.72625C21.0052 9.4567 20.8671 8.91761 21.1434 8.51328C21.153 8.49449 21.166 8.4711 21.1814 8.44336C21.387 8.07325 22.0235 6.92738 20.8671 5.54825C20.5909 5.14393 20.5909 4.60484 21.0052 4.33529Z" fill="currentColor" />
	</svg>
</template>
