<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import countries from '~/ts/phone/countries'
import { match } from '~/ts/utils/match'
import Flag from '@/components/Icon/Flag.vue'

type CreateTravelDocumentData = Domain.BookingCaptainJet.Data.CreateTravelDocumentData
type TravelDocumentData = Domain.BookingCaptainJet.Data.TravelDocumentData

const props = defineProps<{
	create?: CreateTravelDocumentData
	document?: TravelDocumentData
	selected?: boolean
	isRemovable?: boolean
}>()

defineEmits<{
	(e: 'remove'): void
	(e: 'toggle'): void
}>()

const type = computed(() => props.create?.type ?? props.document?.type)
const country_iso_code = computed(() => props.create?.country_iso_code ?? props.document?.country?.iso_code)
const country = computed(() => countries.find((country) => country_iso_code.value?.toLowerCase() === country.iso2.toLowerCase()))
const title = computed(() => match(type.value ?? '', {
	gun_permit: 'Gun permit',
	id_card: 'ID Card',
	passport: 'Passport',
	default: '-',
}))
</script>

<template>
	<div
		:class="[
			{ 'bg-turquoise-100 text-turquoise-600': selected },
			{ 'bg-turquoise-50 text-blue-500': !selected },
			'relative cursor-pointer rounded-xl border border-turquoise-100 px-4  py-3 focus:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-600',
		]"
		tabindex="0"
		@click.stop="$emit('toggle')"
	>
		<div class="flex items-center space-x-2.5">
			<Flag
				v-if="country"
				:code="country?.iso2.toLowerCase()"
				:name="country?.name"
				class="mr-1 h-3 overflow-hidden rounded-sm"
			/>
			<span class="font-medium tracking-tighter" v-text="title" />
		</div>

		<IconTrue v-if="selected" class="absolute right-5 top-5 h-2.5" />

		<Menu
			v-else-if="isRemovable !== false"
			v-slot="{ open }"
			as="div"
			class="absolute right-4 top-3"
		>
			<MenuButton
				tabindex="-1"
				:class="[
					{ 'text-turquoise-400': open },
					{ 'text-blue-500 hover:text-blue-400': !open },
					'flex size-6 items-center justify-center rounded-full',
				]"
				@click.stop=""
			>
				<IconDotsHorizontal class="w-4" />
			</MenuButton>

			<transition
				v-show="open"
				enter-active-class="transition duration-100 ease-out"
				enter-from-class="scale-95 opacity-0 origin-top"
				enter-to-class="scale-100 opacity-100"
				leave-active-class="transition duration-75 ease-in origin-top"
				leave-from-class="scale-100 opacity-100"
				leave-to-class="scale-95 opacity-0"
			>
				<MenuItems class="absolute -right-4 z-20 divide-y divide-turquoise-100 rounded-2xl bg-white shadow focus:outline-none">
					<MenuItem v-slot="{ active }">
						<button
							type="button"
							:class="[
								{ 'text-danger-400': active },
								{ 'text-danger-500': !active },
								'flex items-center space-x-2 p-4 text-base font-medium',
							]"
							@click.stop="$emit('remove')"
						>
							<IconTrash class="size-4" />
							<span>Remove</span>
						</button>
					</MenuItem>
				</MenuItems>
			</transition>
		</Menu>
	</div>
</template>
