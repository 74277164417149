<template>
	<svg
		width="21"
		height="21"
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_16957_72770)">
			<path d="M2.83498 2.72699C2.59802 3.10854 2.5199 3.50046 2.5199 3.91611C2.52162 8.30399 2.52162 12.6922 2.5199 17.0806C2.5183 17.1781 2.52191 17.2757 2.53069 17.3728C2.56349 17.675 2.63903 17.9646 2.80261 18.2166L10.8778 10.144L2.83498 2.72699ZM3.63607 1.96431L11.6535 9.36103C12.5569 8.45678 13.4473 7.56462 14.3433 6.66814C14.3058 6.64569 14.257 6.61548 14.2087 6.58829C13.8124 6.36902 13.4159 6.15005 13.0191 5.93136C10.6495 4.62325 8.28005 3.31486 5.91073 2.00618C5.16704 1.59484 4.42206 1.56377 3.63607 1.96431ZM12.4891 10.1306C13.6545 11.2058 14.806 12.2697 15.9619 13.3285C15.9956 13.3592 16.0914 13.3609 16.1346 13.3367C16.7354 13.0124 17.3336 12.6837 17.9293 12.3505C18.4679 12.0483 18.8516 11.6115 18.9949 10.999C19.225 10.0149 18.8223 9.14306 17.9008 8.62512C17.11 8.18227 16.3128 7.75108 15.5225 7.30737C15.4099 7.24436 15.3499 7.25903 15.2614 7.34708C14.3789 8.23536 13.4939 9.12105 12.6065 10.0042C12.5702 10.0413 12.5366 10.0797 12.4891 10.1306Z" fill="transparent" />
			<path d="M4.45119 19.3201C4.20819 19.2385 3.96518 19.1578 3.72304 19.0745C3.69278 19.061 3.66388 19.0447 3.63672 19.0258L11.7301 10.9307L15.0134 13.9607C14.5999 14.1894 14.2041 14.4091 13.8079 14.6275C11.1874 16.0743 8.56528 17.5191 5.94159 18.9619C5.65024 19.1211 5.3166 19.2027 5.00237 19.3201H4.45119Z" fill="currentColor" />
			<path d="M2.83515 2.72705L10.878 10.1441L2.80408 18.2154C2.64049 17.9633 2.56496 17.6742 2.53215 17.3716C2.52337 17.2744 2.51977 17.1769 2.52136 17.0794C2.52136 12.6915 2.52136 8.30333 2.52136 3.91488C2.52007 3.50052 2.59819 3.10861 2.83515 2.72705Z" fill="currentColor" />
			<path d="M3.63623 1.96417C4.42222 1.56362 5.1672 1.5947 5.91088 2.00733C8.27934 3.31688 10.6488 4.62484 13.0193 5.93122C13.4161 6.15019 13.8126 6.36917 14.2088 6.58815C14.258 6.61534 14.306 6.64555 14.3435 6.668C13.4475 7.56448 12.557 8.45664 11.6536 9.36089L3.63623 1.96417Z" fill="currentColor" />
			<path d="M12.4893 10.1306C12.5367 10.0796 12.5704 10.0412 12.6062 10.0054C13.4916 9.11943 14.3766 8.23374 15.2611 7.34833C15.3475 7.26201 15.4096 7.24561 15.5223 7.30862C16.3126 7.75233 17.1098 8.18352 17.9005 8.62637C18.8199 9.1413 19.2247 10.0149 18.9947 11.0003C18.8514 11.6115 18.4664 12.0474 17.929 12.3504C17.3342 12.6851 16.7364 13.0142 16.1356 13.338C16.0924 13.3621 15.9971 13.3604 15.963 13.3298C14.8062 12.2697 13.6546 11.2057 12.4893 10.1306Z" fill="currentColor" />
		</g>
		<defs>
			<clipPath id="clip0_16957_72770">
				<rect
					width="16.5376"
					height="17.64"
					fill="transparent"
					transform="translate(2.52002 1.68018)"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
