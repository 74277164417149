<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import type { CreateLegData } from '~/ts/legs'

const props = defineProps<{
	id: string
	modelValue?: CreateLegData
}>()

const emit = defineEmits(['update:modelValue'])
const leg = useVModel(props, 'modelValue', emit)

const total = computed(() => Object.entries(props.modelValue ?? {}).reduce((acc, [key, value]) => {
	if (key.endsWith('_count')) {
		return acc + Number(value)
	}

	return acc
}, 0))
</script>

<template>
	<Popover v-slot="{ open }" class="relative">
		<PopoverButton :id="id" class="group w-full text-left font-medium text-blue-500 focus-visible:ring-2 focus-visible:ring-turquoise-600">
			<div class="flex items-center justify-between">
				<span class="font-medium text-blue-500" v-text="total" />
				<IconChevronDown :class="open ? 'rotate-180 text-turquoise-400' : 'group-hover:text-turquoise-400'" class="w-3 text-turquoise-500 transition-all" />
			</div>
		</PopoverButton>

		<transition
			enter-active-class="transition duration-100 ease-out origin-top"
			enter-from-class="scale-95 opacity-0"
			enter-to-class="scale-100 opacity-100"
			leave-active-class="transition duration-75 ease-in origin-top"
			leave-from-class="scale-100 opacity-100"
			leave-to-class="scale-95 opacity-0"
		>
			<PopoverPanel class="absolute inset-x-0 z-10 -mx-5 mt-3 overflow-hidden rounded-2xl bg-white shadow focus:outline-none sm:left-auto sm:w-96 lg:left-1/2 lg:right-auto lg:mx-0 lg:-translate-x-1/2">
				<div class="py-3.5 text-center font-semibold uppercase tracking-widest text-blue-500">
					Passengers
				</div>
				<div class="border-t border-turquoise-100 pb-2 pt-1">
					<LegInputPaxCounter v-model="leg!.female_count">
						Women
					</LegInputPaxCounter>
					<LegInputPaxCounter
						v-model="leg!.male_count"
						label="Men"
					>
						Men
					</LegInputPaxCounter>
					<LegInputPaxCounter
						v-model="leg!.children_count"
						label="Children"
					>
						Children
						<span class="font-normal text-blue-300">(2 - 12 y.o.)</span>
					</LegInputPaxCounter>
					<LegInputPaxCounter
						v-model="leg!.baby_count"
						label="Baby"
					>
						Baby
						<span class="font-normal text-blue-300">(under 2 yo)</span>
					</LegInputPaxCounter>
					<LegInputPaxCounter
						v-model="leg!.pet_count"
						label="Pets"
					>
						Pets
					</LegInputPaxCounter>
				</div>
			</PopoverPanel>
		</transition>
	</Popover>
</template>
