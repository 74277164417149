<script setup lang="ts">
import { parseDateTime } from '~/ts/utils/datetime'
import { match } from '~/ts/utils/match'
import { useProperty } from '@/composables/useProperty'

type LegData = Domain.BookingCaptainJet.Data.LegData

const props = withDefaults(defineProps<{
	leg: LegData
	type?: 'archived' | 'upcoming' | 'cancelled' | 'normal'
	badgeClass?: string
	badgeText?: string
	badgeIcon?: string
}>(), {
	type: 'normal',
})

const dateClass = computed(() => match(props.type, {
	archived: 'text-blue-100',
	upcoming: 'text-green-500',
	cancelled: 'text-red-500',
	normal: 'text-blue-400',
}))

const useTwelveHourClock = useProperty('security.user.use_twelve_hour_clock') ?? false
</script>

<template>
	<div class="relative flex flex-col rounded-xl bg-white lg:flex-row">
		<slot name="modal" />

		<!-- Mobile header -->
		<div class="flex items-center justify-between rounded-t-xl bg-turquoise-100 py-1 pl-4 pr-2 text-turquoise-600 lg:hidden">
			<span class="text-sm font-medium leading-6 tracking-tighter" v-text="parseDateTime(leg.departs_at_local_time, { ignoreTimezone: true, useTwelveHourClock }).date" />
			<div
				v-if="badgeText"
				:class="[
					badgeClass,
					'flex cursor-default items-center space-x-1.5 rounded-md px-2 py-1 text-xs font-medium',
				]"
			>
				<Icon v-if="badgeIcon" :name="badgeIcon" class="h-3.5 lg:h-4" />
				<span v-text="badgeText" />
			</div>
		</div>

		<!-- Main block -->
		<div class="flex-1 lg:flex">
			<div class="flex flex-1">
				<!-- Date (desktop only) -->
				<div
					:class="[
						dateClass,
						'hidden w-[120px] px-10 font-medium tracking-tighter lg:flex lg:flex-col lg:items-center lg:justify-center',
					]"
				>
					<p class="text-2xl font-bold" v-text="parseDateTime(leg.departs_at_local_time, { ignoreTimezone: true, dateFormat: 'D', useTwelveHourClock }).date" />
					<p class="text-xs" v-text="parseDateTime(leg.departs_at_local_time, { ignoreTimezone: true, dateFormat: 'MMM. YY', useTwelveHourClock }).date" />
				</div>

				<!-- Airports -->
				<div class="relative flex flex-1 p-4 lg:border-l lg:border-dashed lg:border-turquoise-100 lg:pl-8">
					<!-- Mobile dots -->
					<div class="absolute -left-2 top-0 hidden h-2 w-4 rounded-b-full bg-turquoise-50 lg:block" />
					<div class="absolute -left-2 bottom-0 hidden h-2 w-4 rounded-t-full bg-turquoise-50 lg:block" />

					<!-- Desktop dots -->
					<div class="flex flex-col items-center justify-between pt-1">
						<div class="size-3.5 rounded-full border-[3px] border-warning-500 bg-white" />
						<div class="flex-1 border-l-2 border-dotted border-turquoise-300" />
						<div class="size-3.5 rounded-full border-[3px] border-warning-500 bg-white" />
					</div>

					<!-- Airports -->
					<div class="ml-4 flex flex-col space-y-2 tracking-tighter text-blue-500 lg:ml-5">
						<div>
							<p class="font-medium leading-5" v-text="leg.departure_airport?.name" />
							<p v-if="leg.departure_airport?.served_city_and_served_city_governing_district_code" class="text-15 text-blue-300" v-text="leg.departure_airport?.served_city_and_served_city_governing_district_code" />
						</div>
						<div>
							<p class="font-medium leading-5" v-text="leg.arrival_airport?.name" />
							<p v-if="leg.arrival_airport?.served_city_and_served_city_governing_district_code" class="text-15 text-blue-300" v-text="leg.arrival_airport?.served_city_and_served_city_governing_district_code" />
						</div>
					</div>
				</div>
			</div>

			<!-- Side content -->
			<div
				class="flex flex-col items-end justify-center border-t border-dashed p-3 lg:border-0 lg:p-4"
				:class="{
					'sm:justify-evenly lg:justify-between': badgeText,
					'sm:justify-center': !badgeText,
				}"
			>
				<!-- Badge -->
				<div
					v-if="badgeText"
					:class="[
						badgeClass,
						'hidden cursor-default items-center space-x-1.5 rounded-md px-2 py-1 text-xs font-medium lg:flex',
					]"
				>
					<Icon :name="`Icon${badgeIcon}`" class="h-3.5 lg:h-4" />
					<span v-text="badgeText" />
				</div>

				<!-- Content -->
				<slot />
			</div>
		</div>
	</div>
</template>
