<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper'
import CaptainIcon from '../Icon/Captain.vue'

import type { Data as ImageData } from '@/components/ImageGallery.vue'
import 'swiper/css'
import 'swiper/css/pagination'

const images = Object.values(import.meta.glob<string>('~/images/captainjet/about/team/*/1x.jpg', { eager: true, query: '?url', import: 'default' }))
const images2x = Object.values(import.meta.glob<string>('~/images/captainjet/about/team/*/2x.jpg', { eager: true, query: '?url', import: 'default' }))

interface Member extends ImageData {
	name: string
	description: string
	location: string
	locationUrl: string
}

const members: Member[] = [
	{
		name: 'Frida Berglund',
		description:
        'With a degree in Marketing Management and extensive experience in the luxury fashion industry, Frida has a keen eye for details. Committed to making each customer\'s experience special, she is fluent in all Scandinavian languages. Her spare time reflects a passion for exploring diverse food cultures and traveling, showcasing her dedication to exceptional service.',
		src: '',
		label: 'Frida Berglund',
		location: 'Lausanne, Switzerland',
		locationUrl: 'https://goo.gl/maps/Zx5Nk2bvxh7hd53D6',
	},
	{
		name: 'Isabella Koenigsberger',
		description:
        'Isabella, with a business consulting background and extensive experience onboard superyachts in luxury destinations like Miami and the Caribbean, is dedicated to customer service. Passionate about extreme sports, including kitesurfing and skiing, she goes the extra mile to deliver exceptional travel experiences, leveraging her sporting ambition and endurance.',
		src: '',
		label: 'Isabella Koenigsberger',
		location: 'Munich, Germany',
		locationUrl: 'https://maps.app.goo.gl/NvLD8n18rtN7wEB47',
	},
	{
		name: 'Sébastien Muller',
		description:
        'Joining CaptainJet in 2023 allowed Sébastien Muller, a seasoned private banker, to effortlessly combine his love for aviation with his knowledge of finance. His experience in private banking offers a strategic financial viewpoint to aviation brokery industry, guaranteeing a methodical and customer-focused approach. Outside of his career, Sébastien\'s involvement in the horse sport, translates into a sophisticated appreciation of dexterity, flexibility, and attention to detail—aspects that are highly valued in the charter industry. His unique set of skills not only ensures a flawless flight but also improves the customer experience, which makes him an invaluable help when handling the intricacies of luxury travel.',
		src: '',
		label: 'Sébastien Muller',
		location: 'Lausanne, Switzerland',
		locationUrl: 'https://goo.gl/maps/Zx5Nk2bvxh7hd53D6',
	},
	{
		name: 'Anna Haus',
		description: 'One of the first to join the team back in 2013, Anna shifted from Hospitality industry to Business Aviation assuming position of Sales Manager. She has worked and lived in Kazakhstan, South of France, USA and Hong Kong. Anna has re-joined CaptainJet in 2021 as Team Assistant and combines her previous experience of sales, customer service and problem-solving skills to assure that daily operations run smooth.',
		src: '',
		label: 'Anna Haus',
		location: 'Zürich, Switzerland',
		locationUrl: 'https://maps.app.goo.gl/iESQMM2DV6SSC3XPA',
	},
	{
		name: 'Roxane Cottone',
		description:
        'Roxane, a customer service-oriented professional, joined Fly 7 in 2015, few years after graduating from École Hôtelière de Lausanne. Beginning her career as an Operation and Sales Officer, Roxane\'s dedication to excellence led her to achieve a Diploma of Advanced Study in Aviation Management in 2019, just before joining the CaptainJet Team.\n\n Roxane unique combination of operational expertise and aviation knowledge positions her as a trusted advisor in aircraft type selection and airport operations. With Roxane on your side, you can expect personalized solutions that make your aviation experience seamless and enjoyable.',
		src: '',
		label: 'Roxane Cottone',
		location: 'Lausanne, Switzerland',
		locationUrl: 'https://goo.gl/maps/Zx5Nk2bvxh7hd53D6',
	},
	{
		name: 'Yves Roch',
		description: 'Yves has been CEO at CaptainJet since 2011. He has always been passionate about aviation and is always keen on overseeing new projects and tools. This explains why he was highly motivated to be part of the CaptainJet team.',
		src: '',
		label: 'Yves Roch',
		location: 'Lausanne, Switzerland',
		locationUrl: 'https://goo.gl/maps/Zx5Nk2bvxh7hd53D6',
	},
	{
		name: 'Olena Strelkovska',
		description:
        'After working for Emirates\' airlines exclusive loyalty program, Olena joined the team at its inception. Solution driver and technology-oriented, she contributes to reinventing the aviation brokerage industry with CaptainJet. Thanks to her unparalleled versatility and unique sense of service, she has organized thousands of flights on all continents since 2017.',
		src: '',
		label: 'Olena Strelkovska',
		location: 'Leudelange, Luxembourg',
		locationUrl: 'https://goo.gl/maps/y1kCLabcA4jxdwT18',
	},
]
members.forEach((member, i) => {
	member.src = images[i]
	member.srcSet = `${images2x[i]} 2x`
})

const memberIndex = ref(members.length - 1)

const swiperInstance = ref<any>()
</script>

<template>
	<div class="overflow-hidden bg-white pb-5 pt-9 lg:py-16">
		<MarketingContainer class="relative flex justify-center gap-[104px] px-6 lg:justify-end">
			<IllustrationAbstractLines class="absolute -left-72 -top-56 hidden w-[1136px] lg:block" />
			<ImageGallery
				v-if="members"
				class="relative hidden w-[300px] lg:flex"
				:datas="members"
				orientation="left"
				:index="memberIndex"
				:label-icon="h(CaptainIcon)"
				:fade="true"
				:on-index-change="value => memberIndex = value"
			/>
			<div class="relative z-10 lg:flex lg:max-w-[50%] lg:items-start lg:justify-end">
				<div class="mx-auto w-full shrink-0 lg:mx-0 lg:text-left xl:w-auto xl:max-w-xl">
					<h2 class="font-inter text-3xl font-medium text-blue-500 lg:font-bold">
						The team
					</h2>
					<div class="mt-9 grid gap-y-8 text-turquoise-500 lg:grid-cols-2 lg:gap-x-7">
						<div class="flex items-center gap-4 lg:flex-col lg:items-start">
							<IconSupportCheck class="size-6 shrink-0" />
							<p>
								We are a team of experienced and trustworthy brokers based in Europe.
							</p>
						</div>
						<div class="flex items-center gap-4 lg:flex-col lg:items-start">
							<IconSearchLocation class="size-6 shrink-0" />
							<p>
								We are here to guide you along your journey and pay attention to details.
							</p>
						</div>
						<div class="flex items-center gap-4 lg:flex-col lg:items-start">
							<IconTicketStar class="size-6 shrink-0" />
							<p>
								We are confident you will always receive the best deal with an efficient solution.
							</p>
						</div>
					</div>

					<div class="mt-14 hidden flex-col items-start lg:flex">
						<div class="flex items-center space-x-2.5">
							<IconCaptain class="size-6 shrink-0 text-blue-200" />
							<p class="font-inter font-bold text-blue-500" v-text="members[memberIndex].name" />
						</div>
						<a
							v-if="members[memberIndex].locationUrl"
							class="mt-1.5 flex items-center gap-x-2 font-inter text-sm font-medium text-blue-300 transition-colors ease-out hover:text-blue-200"
							:href="members[memberIndex].locationUrl"
							target="_blank"
						>
							<span class="underline">{{ members[memberIndex].location }}</span>
							<IconArrowUpRight class="size-2 shrink-0" />
						</a>
						<p class="mt-4 whitespace-pre-line text-blue-300" v-text="members[memberIndex].description" />
					</div>
				</div>
			</div>
		</MarketingContainer>

		<div class="mt-5 lg:hidden">
			<Swiper
				:slides-per-view="1.2"
				:space-between="16"
				:centered-slides="true"
				:loop="true"
				:modules="[Pagination]"
				:pagination="{ clickable: true }"
				@swiper="(swiper) => swiperInstance = swiper"
			>
				<SwiperSlide
					v-for="member in members"
					:key="member.name"
					v-slot="{ isActive }"
					class="self-stretch"
				>
					<div class="h-full pt-52">
						<div class="flex h-full flex-col items-center rounded-xl p-6 transition" :class="isActive ? 'bg-blue-600' : 'bg-blue-300'">
							<div class="-mt-52 size-72">
								<img class="size-full overflow-hidden rounded-2xl bg-turquoise-400 object-cover object-top lg:object-center" :src="member.src" :alt="member.name" />
							</div>
							<div class="mt-6 flex flex-col items-start">
								<IconCaptain class="size-6 shrink-0 text-white" />
								<span class="mt-2.5 font-bold text-white" v-text="member.name" />
								<a
									v-if="member.locationUrl"
									class="mt-1.5 flex items-center gap-x-2 font-inter text-sm font-medium text-turquoise-400"
									:href="member.locationUrl"
									target="_blank"
								>
									<span class="underline">{{ member.location }}</span>
									<IconArrowUpRight class="size-2 shrink-0" />
								</a>
								<p class="mt-4 font-medium text-blue-100" v-text="member.description" />
							</div>
						</div>
					</div>
				</SwiperSlide>

				<div class="flex items-center justify-between px-3 text-turquoise-500">
					<button class="relative z-10 p-8" type="button" @click="swiperInstance.slidePrev()">
						<IconChevronLeft class="h-4" />
					</button>
					<button class="relative z-10 p-8" type="button" @click="swiperInstance.slideNext()">
						<IconChevronLeft class="h-4 rotate-180" />
					</button>
				</div>
			</Swiper>
		</div>
	</div>
</template>

<style lang="postcss">
.swiper-slide {
	height: unset !important;
}

.swiper-pagination {
	@apply mb-4;
}

.swiper-pagination-bullet {
	@apply !transition-all !bg-blue-200;
}

.swiper-pagination-bullet-active {
	@apply !bg-blue-400 !w-5 !rounded-full;
}
</style>
