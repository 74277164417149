import axios from 'axios'

import { route } from '@/composables/route'

type Airport = Resource.Airport

const MAX_AIRPORTS_BY_PLACE = 3

interface LocationRequestResponse {
	places: Resource.Place[]
	airports: Resource.Airport[]
	order: Array<'places' | 'airports'>
}

const store = reactive({
	airportsNearPlaces: new Map<string, Resource.Airport[]>(),
	airports: new Map<string, Resource.Airport>(),
	locations: new Map<string, LocationRequestResponse>(),
})

export async function fetchLocations(query?: string): Promise<LocationRequestResponse> {
	if (!query) {
		return { places: [], airports: [], order: ['places', 'airports'] }
	}

	if (store.locations.has(query)) {
		return store.locations.get(query)!
	}

	try {
		const response = await axios.get<LocationRequestResponse>(route('captainjet.web.api.locations', { query }))
		store.locations.set(query, response.data)

		return response.data
	} catch {
	}

	return { places: [], airports: [], order: ['places', 'airports'] }
}

export async function getAirportNearPlace(place?: string): Promise<Resource.Airport[]> {
	if (!place) {
		return []
	}

	if (store.airportsNearPlaces.has(place)) {
		return toRaw(store.airportsNearPlaces.get(place) ?? [])
	}

	try {
		const response = await axios.get<Paginator<Resource.Airport>>(route('captainjet.web.api.places.airports.index', { city: place, limit: MAX_AIRPORTS_BY_PLACE, page: 1 }))
		store.airportsNearPlaces.set(place, response.data.data)

		return response.data.data
	} catch {
	}

	return []
}

export async function getAirportByIcao(icaoCode?: string) {
	if (!icaoCode) {
		return
	}

	if (store.airports.has(icaoCode)) {
		return toRaw(store.airports.get(icaoCode))
	}

	try {
		const response = await axios.get<Resource.Airport>(route('captainjet.web.api.airports.show', [icaoCode]))
		store.airports.set(icaoCode, response.data)

		return response.data
	} catch (error) {
		console.error(error)
	}
}

export async function loadMoreAirportsNearPlace(place?: string): Promise<Resource.Airport[]> {
	if (!place || !store.airportsNearPlaces.has(place)) {
		return []
	}

	try {
		const page = (store.airportsNearPlaces.get(place)!.length / MAX_AIRPORTS_BY_PLACE) + 1
		const response = await axios.get<Paginator<Airport>>(route('captainjet.web.api.places.airports.index', { city: place, limit: MAX_AIRPORTS_BY_PLACE, page }))
		store.airportsNearPlaces.set(place, [
			...store.airportsNearPlaces.get(place)!,
			...response.data.data,
		])

		return response.data.data
	} catch {
	}

	return []
}
