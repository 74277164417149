<script setup lang="ts">
import type { Vector2 } from '~/ts/utils/math'

import { magnitude } from '~/ts/utils/math'

const images = Object.values(import.meta.glob<string>('~/images/captainjet/marketing/features/*/1x.webp', { eager: true, query: '?url', import: 'default' }))
const images2x = Object.values(import.meta.glob<string>('~/images/captainjet/marketing/features/*/2x.webp', { eager: true, query: '?url', import: 'default' }))
const imageSrcSets = images.map((val, i) => {
	return `${val} 1x, ${images2x[i]} 2x`
})

const mouse = useMouse()
const floating = [
	ref<HTMLElement>(),
	ref<HTMLElement>(),
	ref<HTMLElement>(),
	ref<HTMLElement>(),
	ref<HTMLElement>(),
]

const repulse = {
	range: 550,
	amount: 13,
}

useRafFn(() => {
	const bodyRect = document.body.getBoundingClientRect()

	floating.forEach((el) => {
		if (el.value) {
			const rect = el.value.getBoundingClientRect()
			const dist: Vector2 = {
				x: (rect.x + 0.5 * rect.width) - (mouse.x.value + bodyRect.x),
				y: (rect.y + 0.5 * rect.height) - (mouse.y.value + bodyRect.y),
			}
			const proximity = 1 - Math.min(1, magnitude(dist.x, dist.y) / repulse.range)
			const angle = Math.atan(dist.y / dist.x)

			el.value.style.transform = `
				translateX(${proximity * Math.abs(Math.cos(angle)) * Math.sign(dist.x) * repulse.amount}px)
				translateY(${proximity * Math.abs(Math.sin(angle)) * Math.sign(dist.y) * repulse.amount}px)
			`
		}
	})
})
</script>

<template>
	<div class="bg-white pb-64 pt-16 lg:pb-[180px] lg:pt-12">
		<MarketingContainer>
			<div class="flex flex-col  items-center justify-between lg:flex-row lg:items-start">
				<!-- Images -->
				<div class="relative h-[292px] w-[273px] lg:h-[465px] lg:w-[435px]">
					<!-- Static -->
					<img class="absolute right-0 top-0 h-[61.2%] w-[54.8%] rounded-xl object-cover lg:rounded-2xl" :srcset="imageSrcSets[0]" alt="Open door on Pilatus jet at sunset" />
					<img class="absolute left-0 top-[20.65%] h-[50.3%] w-[38.2%] rounded-xl object-cover lg:rounded-2xl" :srcset="imageSrcSets[1]" alt="Luxury seating inside Pilatus jet" />
					<img class="absolute bottom-0 right-[20.46%] h-[32.3%] w-[34.5%] rounded-xl object-cover lg:rounded-2xl" :srcset="imageSrcSets[2]" alt="Boarding a jet in the sunshine" />
					<!-- 'Floating' -->
					<img
						:ref="floating[0]"
						class="absolute bottom-[23.66%] left-[-8.28%] h-[14.8%] w-[19.8%] rounded-xl object-cover transition-transform duration-200 ease-linear lg:rounded-2xl"
						:srcSet="imageSrcSets[3]"
						alt="Pilatus jet flying above the ocean"
					/>
					<img
						:ref="floating[1]"
						class="absolute bottom-[6.67%] right-[12.18%] h-[14%] w-[12.5%] rounded-xl object-cover transition-transform duration-200 ease-linear lg:rounded-2xl"
						:srcSet="imageSrcSets[4]"
						alt="Pilatus jet flying through clouds"
					/>
					<img
						:ref="floating[2]"
						class="absolute right-[-6.67%] top-[18.5%] h-[15%] w-[16.1%] rounded-xl object-cover shadow-[0_17px_24px_rgba(133,184,197,0.4)] transition-transform duration-200 ease-linear lg:rounded-2xl"
						:srcSet="imageSrcSets[5]"
						alt="CaptainJet app icon"
					/>
					<!-- Text -->
					<div :ref="floating[3]" class="absolute left-[21.8%] top-[11.8%] flex items-center gap-1 rounded-full bg-warning-100 px-1.5 text-11 font-medium leading-5 text-warning-600 transition-transform duration-200 ease-linear">
						<Icon name="IconStarOutline" class="size-3.5" />
						<span>Top rated operator</span>
					</div>
					<div :ref="floating[4]" class="absolute bottom-[27.7%] right-[-36px] flex items-center gap-1 rounded-full bg-warning-100 px-1.5 text-11 font-medium leading-5 text-warning-600 transition-transform duration-200 ease-linear">
						<Icon name="IconDocumentDeal" class="size-3.5" />
						<span>Great deal</span>
					</div>
				</div>
				<!-- Feature list -->
				<div class="features1 mt-14 max-w-lg space-y-11 lg:mt-0 lg:space-y-10 lg:pl-10 xl:-translate-x-10 xl:pl-0">
					<div>
						<div class="flex items-center space-x-2.5">
							<IconMeal2 class="size-4.5 shrink-0 text-blue-200" />
							<p class="font-inter font-bold text-blue-500">
								Enjoy a bespoke service
							</p>
						</div>
						<p class="mt-4 font-medium text-blue-300">
							Create your personal private charter experience: select the aircraft, indicate any special requests, and get a tailor-made service from your broker.
						</p>
					</div>
					<div>
						<div class="flex items-center space-x-2.5">
							<IconSearchAircraft class="size-4.5 shrink-0 text-blue-200" />
							<p class="font-inter font-bold text-blue-500">
								Flight and operator auditing
							</p>
						</div>
						<p class="mt-4 font-medium text-blue-300">
							Your safety and peace of mind are of the utmost importance: we thoroughly inspect our contractors to ensure we never charter a private jet our pilots won’t fly their own families in.
						</p>
					</div>
					<div>
						<div class="flex items-center space-x-2.5">
							<IconSupportPerson class="size-4.5 shrink-0 text-blue-200" />
							<p class="font-inter font-bold text-blue-500">
								At your disposal 24/7
							</p>
						</div>
						<p class="mt-4 font-medium text-blue-300">
							Our experienced brokers are always happy to take care of your flight requests, answer inquiries and ensure you enjoy a custom-tailored, comfortable private charter flight that matches your wishes.
						</p>
					</div>
					<div>
						<div class="flex items-center space-x-2.5">
							<IconDocumentDeal class="size-4.5 shrink-0 text-blue-200" />
							<p class="font-inter font-bold text-blue-500">
								Instant flight estimates
							</p>
						</div>
						<p class="mt-4 font-medium text-blue-300">
							Submit your flight request through the app or website and get an instant availability and price estimate in real time.
						</p>
					</div>
					<div>
						<div class="flex items-center space-x-2.5">
							<IconLock class="size-4.5 shrink-0 text-blue-200" />
							<p class="font-inter font-bold text-blue-500">
								Cutting-edge technology
							</p>
						</div>
						<p class="mt-4 font-medium text-blue-300">
							Smooth service, innovation and convenience are the hallmarks of our approach to digitizing the private jet travel industry.
						</p>
					</div>
				</div>
			</div>
		</MarketingContainer>
	</div>
</template>
