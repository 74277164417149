<template>
	<svg
		class="h-4"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 17 17"
	>
		<path d="M13.296 10.373a17.924 17.924 0 0 0-4.488-.655 2.8 2.8 0 0 0 2.518-2.34h.297a.488.488 0 0 0 .166.025.488.488 0 0 0 .165-.026h.772a.55.55 0 0 0 .551-.551V5.3A4.798 4.798 0 0 0 8.484.5a4.803 4.803 0 0 0-4.796 4.797v1.525a.55.55 0 0 0 .9.426.55.55 0 0 0 .93-.4V5.212a.55.55 0 0 0-.551-.551c-.044 0-.085.007-.125.015a3.698 3.698 0 0 1 3.642-3.073 3.704 3.704 0 0 1 3.661 3.186.554.554 0 0 0-.794.092A2.795 2.795 0 0 0 8.57 2.334h-.173a2.797 2.797 0 0 0-2.793 2.794v1.808a2.798 2.798 0 0 0 2.55 2.782 17.7 17.7 0 0 0-2.065.159H6.08c-.812.11-1.62.275-2.414.496a2.768 2.768 0 0 0-2.011 2.654v2.922a.55.55 0 0 0 .551.551h12.556a.55.55 0 0 0 .552-.551v-2.922c0-1.232-.831-2.323-2.018-2.654Zm-4.8 1.904-1.228-1.415c.838-.063 1.68-.06 2.514.007l-1.287 1.408ZM6.708 6.936V5.128c0-.93.757-1.691 1.69-1.691h.174c.93 0 1.69.757 1.69 1.69v1.144H8.488a.55.55 0 1 0 0 1.102h1.716a1.693 1.693 0 0 1-1.631 1.25H8.4a1.69 1.69 0 0 1-1.691-1.687Zm-3.951 6.09c0-.738.496-1.393 1.209-1.591.65-.18 1.309-.324 1.97-.423l1.996 2.301v2.084H5.404v-.694a.55.55 0 1 0-1.103 0v.694H2.758v-2.37Zm11.453 2.371h-1.573v-.694a.55.55 0 1 0-1.103 0v.694h-2.5v-2.076l2.096-2.294c.628.1 1.253.235 1.87.408.714.198 1.21.853 1.21 1.591v2.371Z" fill="currentColor" />
	</svg>
</template>
