import axios from 'axios'
import { FileStatus } from 'filepond'
import createFilepond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { whenever } from '@vueuse/core'

import type { FilePondFile, FilePondServerConfigProps } from 'filepond'

import { match } from '~/ts/utils/match'
import { route } from '@/composables/route'

interface SignedStorageResponse {
	url: string
	key: string
}

interface FilepondOptions {
	onError?: (error: string) => void
}

type Status = 'error' | 'loading' | 'idle'

export interface FileWrapper extends FilePondFile {
	previewUrl?: string
	remove: () => void
	retry: () => void
}

function status(file: FileWrapper): Status {
	if ([FileStatus.PROCESSING_ERROR, FileStatus.LOAD_ERROR].includes(file.status)) {
		return 'error'
	}

	if ([FileStatus.LOADING, FileStatus.PROCESSING, FileStatus.PROCESSING_QUEUED].includes(file.status)) {
		return 'loading'
	}

	return 'idle'
}

export function useFilepond(options: FilepondOptions = {}) {
	const previewCache = new Map<string, string>()
	const FilePond = createFilepond(
		FilePondPluginFileValidateType,
	)
	const pond = ref<typeof FilePond>()
	const files = ref<FileWrapper[]>([])

	function browse() {
		pond.value?.browse()
	}

	function getPreviewUrl(id: string, file: File) {
		try {
			if (!file.type.includes('image')) {
				return
			}

			if (previewCache.has(id)) {
				return previewCache.get(id)
			}

			const preview = URL.createObjectURL(file)
			previewCache.set(id, preview)
			return preview
		} catch {}
	}

	/** Gets the style for the attachments' previews. */
	function getPreviewStyle(file: FileWrapper): string | undefined {
		if (!file.previewUrl) {
			return
		}

		const gradient = match(status(file), {
			loading: 'linear-gradient(180deg, rgba(35,70,107,1) 0%, rgba(35,70,107,0.75) 45%)',
			idle: 'linear-gradient(180deg, rgba(35,70,107,1) 0%, rgba(35,70,107,0.2) 45%)',
			error: 'linear-gradient(180deg, rgba(107,35,35,1) 0%, rgba(107,35,35,0.2) 45%)',
		})

		return `background: ${gradient}, center / cover no-repeat url(${file.previewUrl})`
	}

	// Simulates reactivity by updating a reactive `files` variable
	// whenever a change is made. TODO - update status only on existing
	// files?
	function update() {
		files.value = pond.value?.getFiles().map((file: FilePondFile) => {
			return {
				id: file.id,
				filename: file.filename,
				fileSize: file.fileSize,
				fileType: file.fileType,
				serverId: file.serverId,
				status: file.status,
				file: file.file,
				remove: () => pond.value?.removeFile(file.id),
				retry: () => pond.value?.processFile(file.id),
				previewUrl: getPreviewUrl(file.id, file.file as File),
			}
		})
	}

	const server: FilePondServerConfigProps['server'] = {
		// https://pqina.nl/filepond/docs/api/server/#process-1
		process: async (fieldname, file, metadata, load, error, progress, abort) => {
			const controller = new AbortController()

			try {
				const response = await axios.post<SignedStorageResponse>(route('captainjet.web.api.signed-upload'), {}, { withCredentials: true })
				await axios.request({
					method: 'PUT',
					url: response.data.url,
					data: new File([file], file.name, { type: file.type }),
					headers: { 'Content-Type': file.type },
					onUploadProgress: (event) =>
						progress(
							event.lengthComputable,
							event.loaded,
							event.total,
						),
				})

				load(response.data.key)
			} catch (ex: any) {
				error(ex?.message ?? ex)
				options?.onError?.(ex?.message)
			}

			return {
				abort: () => {
					controller.abort()
					abort()
				},
			}
		},
	}

	whenever(pond, () => {
		pond.value!._pond.onupdatefiles = update
		pond.value!._pond.onactivatefile = update
		pond.value!._pond.oninitfile = update
		pond.value!._pond.onprocessfile = update
		pond.value!._pond.onprocessfilestart = update
		pond.value!._pond.onaddfileprogress = update
		pond.value!._pond.onwarning = update
		pond.value!._pond.onerror = update
	})

	return {
		server,
		pond,
		FilePond,
		update,
		browse,
		files,
		status,
		getPreviewStyle,
	}
}
