<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.042 7.383v3.477l.682.091c1.022.092 1.874.915 1.874 1.83s-.767 1.739-1.874 1.83l-.682-.091v3.477c0 .823.682 1.464 1.448 1.464h5.197V5.919H2.49c-.766 0-1.448.64-1.448 1.464Zm1.533.183h3.579v10.248H2.575v-1.83c1.449-.458 2.556-1.739 2.556-3.294 0-1.556-1.022-2.837-2.556-3.294v-1.83Z"
			fill="currentColor"
		/>
		<path d="M17.399 10.677c-.256-.092-.511-.183-.767-.183-.426 0-.766.183-1.022.366-.256.274-.511.549-.596 1.006h2.13l-.17.55h-2.046v.457h1.875l-.17.549h-1.534c.085.457.34.732.596 1.006.256.275.596.366 1.023.366.255 0 .51-.091.766-.183.256-.091.426-.274.597-.549l.51.458a3.81 3.81 0 0 1-.936.732c-.341.183-.682.274-1.108.274-.596 0-1.193-.183-1.619-.549-.51-.366-.766-.915-.937-1.555h-.766v-.55h.681v-.457h-.681v-.549h.766c.17-.64.511-1.19.938-1.555.425-.366.937-.55 1.618-.55.34 0 .767.092 1.108.275.426.183.681.366.937.732l-.597.458c-.17-.275-.34-.458-.596-.55Z" fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.317 5.919H8.88V19.46h12.438c1.449 0 2.641-1.19 2.641-2.654V8.572a2.655 2.655 0 0 0-2.64-2.653Zm1.108 10.888c0 .55-.511 1.007-1.108 1.007H10.413V7.566h10.904c.597 0 1.108.457 1.108 1.006v8.235Z"
			fill="currentColor"
		/>
	</svg>
</template>
