<script setup lang="ts">
import type { Step } from './Steps.vue'

const props = defineProps<{
	steps: Step[]
}>()

const images = ref()
const scrollable = ref<HTMLElement>()
const panels = ref()
const currentIndex = ref(0)

function handleScroll(event: Event) {
	requestAnimationFrame(() => {
		// Calculate scroll progress
		const target = event.target as HTMLElement
		const scrollProgress = target.scrollLeft / (target.scrollWidth - target.clientWidth)

		// Sync the images carousel with the main scrollable
		const imagesScrollable = images.value.scrollable
		imagesScrollable.scrollLeft = scrollProgress * (imagesScrollable.scrollWidth - imagesScrollable.clientWidth)

		// Update current index
		currentIndex.value = Math.round((props.steps.length - 1) * scrollProgress)
	})
}

function scrollToStep(index: number) {
	if (scrollable.value && panels.value) {
		const panel = panels.value[index]
		const panelRect = panel.container.getBoundingClientRect()

		scrollable.value.scroll({
			left: panelRect.x + scrollable.value.scrollLeft,
			behavior: 'smooth',
		})
	}
}

function scrollSteps(change: number) {
	scrollToStep(currentIndex.value + change)
}
</script>

<template>
	<section class="flex flex-col bg-blue-600 pb-[15px] lg:hidden">
		<MarketingMobileStepsImages
			ref="images"
			:steps="props.steps"
		/>
		<div
			ref="scrollable"
			class="scrollable flex w-screen snap-x snap-mandatory overflow-x-scroll scrollbar-hide"
			@scroll="(event) => handleScroll(event)"
		>
			<div class="flex shrink-0 gap-6 px-6">
				<MarketingMobileStepsPanel
					v-for="(step, i) in props.steps"
					:key="i"
					ref="panels"
					:icon="step.mainIcon"
					:title="step.title"
					:paragraphs="step.paragraphs"
				/>
			</div>
		</div>
		<MarketingMobileStepsNav
			:count="props.steps.length"
			:selected-index="currentIndex"
			:on-click-previous="() => scrollSteps(-1)"
			:on-click-index="scrollToStep"
			:on-click-next="() => scrollSteps(1)"
		/>
	</section>
</template>

<style scoped lang="postcss">
.scrollable {
	--margin-top: 24px;
	--mobile-imgs-height: 380px;

	/* Extend the scrollable hit-box up and over the enslaved
	phone images, so the user can also scroll by swiping these too. */
	padding-top: calc(var(--margin-top) + var(--mobile-imgs-height));
	margin-top: calc(-1 * var(--mobile-imgs-height));
}
</style>
