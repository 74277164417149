import axios from 'axios'
import { useOneSignal } from '@onesignal/onesignal-vue3'
import { invokeWithTimeout } from '../utils/promise'

import { route } from '@/composables/route'

type ConversationData = Domain.Chat.Data.ConversationData

export const token = useStorage<string>('chat:token', null)

/** Resolves a token to a conversation. */
export async function resolveToken(initial?: string, playerId?: string): Promise<string> {
	const url = route('captainjet.web.api.chat.conversation.resolve', {
		token: initial,
		player_id: playerId,
	})

	const response = await axios.get<ConversationData>(url)
	return response.data.token
}

/** Gets the token for the app-wide chat. */
export async function resolveUserToken(initial?: string): Promise<string> {
	const playerId = await getPlayerId()

	try {
		token.value = await resolveToken(initial ?? token.value, playerId)
	} catch (error: any) {
		token.value = null

		// If the token was not found, it means that somehow the conversation
		// was erased. In that case, we create another one to replace it.
		if (error?.response?.status === 404) {
			return await resolveUserToken()
		}
	}

	return token.value
}

async function getPlayerId() {
	const { showNativePrompt, getUserId } = useOneSignal()

	await invokeWithTimeout(showNativePrompt, 250)
	return await invokeWithTimeout(getUserId, 250) || undefined
}
