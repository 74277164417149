<script setup lang="ts">
import { SwitchGroup, SwitchLabel } from '@headlessui/vue'
import clone from 'lodash.clonedeep'

import type { SpecialRequests } from '~/ts/estimates'

import { metadata } from '~/ts/estimates'

const props = defineProps<{
	modelValue: SpecialRequests
	show: boolean
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', filters: SpecialRequests): void
	(e: 'close'): void
}>()

const requests = ref({} as SpecialRequests)

function synchronizeWithProps() {
	requests.value = clone(props.modelValue)
}

function confirm() {
	emit('update:modelValue', clone(requests.value))
	emit('close')
}

onMounted(synchronizeWithProps)
whenever(() => props.show, synchronizeWithProps)
</script>

<template>
	<BaseDialog
		:show="show"
		title="Special requests"
		title-size="sm"
		@close="emit('close')"
	>
		<div class="divide-y divide-turquoise-50 border-t border-turquoise-100">
			<div class="px-6 py-4">
				<div class="text-xs font-medium uppercase leading-5 tracking-widest text-blue-200">
					Transfer
				</div>
				<div class="mt-2 flex flex-wrap items-center gap-x-2 gap-y-3">
					<SelectableButton
						v-for="(option, key) in metadata.transfers"
						:key="key"
						class="space-x-2.5 px-4.5 text-sm"
						:icon="option.icon"
						icon-class="w-4 h-4"
						:checked="requests.transfers[key]"
						:title="option.label"
						@click="requests.transfers[key] = !requests.transfers[key]"
					>
						{{ option.label }}
					</SelectableButton>
				</div>
			</div>

			<div class="px-6 py-4">
				<div class="text-xs font-medium uppercase leading-5 tracking-widest text-blue-200">
					Bulk luggage
				</div>
				<div class="mt-2 flex flex-wrap items-center gap-x-2 gap-y-3">
					<SelectableButton
						v-for="(option, key) in metadata.luggage"
						:key="key"
						class="space-x-2.5 px-4.5 text-sm"
						:icon="option.icon"
						icon-class="w-4 h-4"
						:checked="requests.luggage[key]"
						:title="option.label"
						@click="requests.luggage[key] = !requests.luggage[key]"
					>
						{{ option.label }}
					</SelectableButton>
				</div>
			</div>

			<div class="px-6 py-4">
				<div class="text-xs font-medium uppercase leading-5 tracking-widest text-blue-200">
					Caterings
				</div>
				<div class="mt-2 flex flex-wrap items-center gap-x-2 gap-y-3">
					<SelectableButton
						v-for="(option, key) in metadata.caterings"
						:key="key"
						class="space-x-2.5 px-4.5 text-sm"
						:icon="option.icon"
						icon-class="w-4 h-4"
						:checked="requests.caterings[key]"
						:title="option.label"
						@click="requests.caterings[key] = !requests.caterings[key]"
					>
						{{ option.label }}
					</SelectableButton>
				</div>
			</div>

			<div class="px-6 py-4">
				<SwitchGroup as="div" class="flex items-center justify-between space-x-9">
					<SwitchLabel as="div" class="flex cursor-default items-center space-x-2 text-sm font-medium text-blue-400">
						<IconAttendant class="size-5 shrink-0 text-blue-200" />
						<span>Flight Attendant</span>
					</SwitchLabel>

					<InputSwitch v-model="requests.flightAttendant" label="Flight Attendant" />
				</SwitchGroup>
			</div>

			<div class="p-5 pb-0">
				<label for="additional_notes" class="text-xs font-medium uppercase leading-5 tracking-widest text-blue-200">
					Anything else?
				</label>
				<textarea
					id="additional_notes"
					v-model="requests.additionalDetails"
					name="additional_notes"
					class="mt-2 h-44 w-[26rem] resize-none rounded-xl border border-turquoise-100 bg-turquoise-50 p-3.5 font-medium text-blue-500 hover:border-turquoise-200 focus:border-turquoise-400"
				/>
			</div>
		</div>

		<div class="p-5">
			<BaseButton
				type="submit"
				size="lg"
				class="w-full"
				variant="primary"
				@click="confirm"
			>
				Confirm
			</BaseButton>
		</div>
	</BaseDialog>
</template>
