<script setup lang="ts">
import {
	Dialog,
	DialogDescription,
	DialogPanel,
	DialogTitle,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue'
import { router } from '@inertiajs/vue3'

import { route } from '@/composables/route'
import guideFrontImg1x from '~/images/captainjet/marketing/guide/front/1x.webp'
import guideFrontImg2x from '~/images/captainjet/marketing/guide/front/2x.webp'
import guideBackImg1x from '~/images/captainjet/marketing/guide/back/1x.webp'
import guideBackImg2x from '~/images/captainjet/marketing/guide/back/2x.webp'

const jetbookForm = useForm({
	email: '',
	accepts_promotional_content: false,
})

const isJetbookDialogOpen = ref(false)
const disabled = computed(() => !jetbookForm.email)

function submitJetbookForm() {
	jetbookForm.post(route('captainjet.web.marketing.jetbook'), {
		preserveScroll: true,
		onSuccess: () => {
			isJetbookDialogOpen.value = true
			jetbookForm.reset()
		},
	})
}

function downloadJetbook() {
	router.post(route('captainjet.web.marketing.jetbook.download'))
}
</script>

<template>
	<div class="bg-turquoise-200 py-8 lg:py-14">
		<MarketingContainer>
			<div class="mx-auto max-w-2xl lg:flex lg:max-w-none lg:items-center lg:justify-between">
				<div class="flex justify-center">
					<div class="relative flex">
						<div class="relative z-10 h-60 w-44 md:h-80 md:w-56">
							<img
								class="size-full object-cover"
								:src="guideFrontImg1x"
								:srcSet="`${guideFrontImg2x} 2x`"
								alt="My Little Jet Book: PC-14 page"
							/>
						</div>
						<div class="-ml-9 mt-6 h-60 w-44 md:-ml-14 md:mt-9 md:h-80 md:w-56">
							<img
								class="size-full object-cover"
								:src="guideBackImg1x"
								:srcSet="`${guideBackImg2x} 2x`"
								alt="My Little Jet Book: Front cover"
							/>
						</div>
					</div>
				</div>
				<form class="mt-11 lg:ml-24 lg:mt-0" @submit.prevent="submitJetbookForm">
					<p class="text-center font-inter text-3xl font-medium text-blue-500 lg:text-left lg:text-2xl">
						Receive your
						<span class="font-bold">free jet book</span>.
					</p>
					<p class="mt-8 text-center font-medium text-blue-400 lg:mt-6 lg:text-left">
						Choosing the right jet for your next trip can be a daunting task. Learn more about various private aircraft from Pilatus PC-12 to BBJ and what they have to offer by downloading My Little Jet Book for free!
					</p>
					<div v-auto-animate class="mx-auto mt-8 max-w-xl lg:mx-0 lg:mt-10">
						<span v-if="jetbookForm.errors.email" class="mb-4 block text-sm font-medium text-red-600" v-text="jetbookForm.errors.email" />
						<div
							class="overflow-hidden rounded-xl lg:relative lg:h-11"
							:class="{
								'ring-2 ring-red-600/30': jetbookForm.errors.email,
								'focus-within:ring-2 focus-within:ring-turquoise-400 focus-within:ring-offset-2 focus-within:ring-offset-turquoise-200': !jetbookForm.errors.email,
							}"
						>
							<input
								v-model="jetbookForm.email"
								class="h-11 w-full px-6 font-medium text-blue-500 transition placeholder-shown:text-blue-300 lg:h-full"
								placeholder="Enter your email"
								type="email"
							/>
							<button
								type="submit"
								:disabled="disabled"
								class="mt-4 h-11 w-full bg-blue-500 px-5 font-semibold text-white transition-colors hover:bg-blue-400 focus-visible:bg-blue-700 disabled:bg-blue-100 disabled:text-gray-500 lg:absolute lg:inset-y-0 lg:right-0 lg:z-10 lg:mt-0 lg:h-full lg:w-auto"
							>
								Receive my book
							</button>
						</div>
						<div class="mt-5 flex">
							<input
								id="accept-offer"
								v-model="jetbookForm.accepts_promotional_content"
								class="form-checkbox size-4.5 rounded-[3px] border-turquoise-400 text-blue-500 focus:ring-0 focus:ring-offset-0 focus-visible:border-blue-500 focus-visible:ring-1 focus-visible:ring-blue-500"
								type="checkbox"
							/>
							<label class="ml-2 text-xs font-medium leading-[18px] text-blue-500" for="accept-offer">I accept to receive promotional content regarding CaptainJet offers.</label>
						</div>
					</div>
				</form>
			</div>

			<!-- Download PDF dialog -->
			<TransitionRoot appear :show="isJetbookDialogOpen" as="template">
				<Dialog as="div" @close="isJetbookDialogOpen = false">
					<div class="fixed inset-0 z-30 overflow-y-auto">
						<div class="min-h-screen px-4 text-center">
							<TransitionChild
								as="template"
								enter="duration-300 ease-out"
								enter-from="opacity-0"
								enter-to="opacity-100"
								leave="duration-200 ease-in"
								leave-from="opacity-100"
								leave-to="opacity-0"
							>
								<div class="fixed inset-0 bg-blue-500/80 backdrop-blur-sm [@supports(backdrop-filter:blur(0px))]:bg-blue-500/40" />
							</TransitionChild>

							<span class="inline-block h-screen align-middle" aria-hidden="true">
								&#8203;
							</span>

							<TransitionChild
								as="template"
								enter="duration-300 ease-out"
								enter-from="opacity-0 scale-95"
								enter-to="opacity-100 scale-100"
								leave="duration-200 ease-in"
								leave-from="opacity-100 scale-100"
								leave-to="opacity-0 scale-95"
							>
								<div class="relative my-8 inline-block w-full align-middle transition-all">
									<DialogPanel class="relative mx-auto w-full max-w-[545px] rounded-2xl">
										<div class="-mt-32 flex flex-col">
											<!-- Upper circle -->
											<div class="-mb-32 flex flex-col">
												<div class="relative">
													<div class="inline-flex rounded-full border border-turquoise-100 p-3">
														<div class="size-52 rounded-full bg-turquoise-100" />
													</div>

													<div class="absolute inset-0 flex items-center justify-center">
														<div class="relative z-10 h-36">
															<img
																class="white-img-shadow size-full object-cover"
																:src="guideFrontImg1x"
																:srcSet="`${guideFrontImg2x} 2x`"
																alt="My Little Jet Book: PC-14 page"
															/>
														</div>
														<div class="-ml-6 mt-5 h-36">
															<img
																class="green-img-shadow size-full object-cover"
																:src="guideBackImg1x"
																:srcSet="`${guideBackImg2x} 2x`"
																alt="My Little Jet Book: Front cover"
															/>
														</div>
													</div>
												</div>
											</div>

											<!-- Content -->
											<div class="flex-col items-center rounded-t-2xl bg-white px-6 pb-3 pt-40">
												<DialogTitle as="span" class="text-lg font-semibold uppercase tracking-widest text-blue-500">
													Your little jet book here!
												</DialogTitle>
												<DialogDescription class="mt-4 text-blue-400">
													Download your guide and learn more about aircraft.
												</DialogDescription>
											</div>

											<!-- Separator -->
											<div class="w-full scale-y-105 overflow-x-hidden">
												<div class="relative w-full scale-x-[1.015]">
													<div class="absolute inset-x-0 top-1/2 mx-4.5 -translate-y-1/2 border border-dashed border-turquoise-100" />
													<svg
														class="size-full"
														viewBox="0 0 545 32"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M0 32C8.83656 32 16 24.8366 16 16C16 7.16344 8.83656 0 0 0H544C535.163 0 528 7.16344 528 16C528 24.8366 535.163 32 544 32H0ZM544 32C544.336 32 544.669 31.9897 545 31.9693V32H544ZM544 0H545V0.030742C544.669 0.0103478 544.336 0 544 0Z"
															fill="white"
														/>
													</svg>
												</div>
											</div>

											<!-- Download -->
											<div class="flex justify-center rounded-b-2xl bg-white pb-6 pt-2">
												<base-button
													size="lg"
													class="px-5 font-display"
													variant="primary"
													icon="Download"
													icon-class="w-5 h-5 shrink-0"
													@click="downloadJetbook"
												>
													Download now
												</base-button>
											</div>
										</div>
									</DialogPanel>
								</div>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</TransitionRoot>
		</MarketingContainer>
	</div>
</template>

<style scoped lang="postcss">
.white-img-shadow {
	box-shadow:
		3px 5px 10px rgba(68, 132, 147, 0.12),
		0px 18px 30px rgba(68, 132, 147, 0.2);
}

.green-img-shadow {
	filter: drop-shadow(0px 18px 30px rgba(68, 132, 147, 0.2));
}
</style>
