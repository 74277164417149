import axios from 'axios'

import type { Ref } from 'vue'

import { route } from '@/composables/route'

type StripeInstance = ReturnType<typeof Stripe>
type ElementsOptions = Parameters<StripeInstance['elements']>[0]
type Element = ReturnType<ReturnType<StripeInstance['elements']>['create']>
type ElementOptions = Parameters<Element['update']>[0]

interface Options {
	cardElement?: Ref<HTMLDivElement | undefined>
	elementsOptions?: ElementsOptions
	cardOptions?: ElementOptions
	onIdReceived?: (id: string) => void
}

export function useStripe(options: Options) {
	const stripe = ref<StripeInstance>()
	const card = ref<Element>()
	const ready = ref(false)
	const submitting = ref(false)
	const filled = ref(false)
	const error = ref<string>()

	const { load, unload } = useScriptTag('https://js.stripe.com/v3/', () => {
		tryOnUnmounted(unload)
	}, { manual: true })

	async function loadStripe() {
		stripe.value = Stripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY!)
	}

	async function loadCardElement() {
		await until(options.cardElement).not.toBeUndefined({ timeout: 1000 })

		const elements = stripe.value!.elements(options.elementsOptions ?? {
			fonts: [
				{ cssSrc: 'https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500&display=swap' },
			],
		})

		card.value = elements.create('card', options.cardOptions)
		card.value.mount(options.cardElement!.value)
		card.value.on('ready', () => ready.value = true)
		card.value.on('change', (event) => {
			filled.value = !!event?.complete
			error.value = event?.error?.message
		})
	}

	async function initializeCardElement() {
		card.value?.destroy?.()
		filled.value = false
		ready.value = false
		submitting.value = false
		error.value = undefined

		await load()
		await loadStripe()
		await loadCardElement()
	}

	async function initializeHandleNextPaymentIntentAction() {
		await load()
		await loadStripe()
	}

	function clear() {
		card.value?.clear()
	}

	async function getId() {
		if (!stripe.value || !card.value) {
			error.value = 'Stripe is not initialized.'
			return
		}

		submitting.value = true

		const response = await axios.post<{ client_secret: string }>(route('captainjet.web.api.setup-intents.store'))
		const clientSecret = response.data.client_secret

		const result = await stripe.value.confirmCardSetup(clientSecret, {
			payment_method: {
				card: card.value,
			},
		})

		submitting.value = false

		if (result.error) {
			error.value = result.error.message
			return
		}

		if (result.setupIntent?.status === 'succeeded') {
			options.onIdReceived?.(result.setupIntent.payment_method!)

			return result.setupIntent.payment_method!
		}
	}

	async function handleNextPaymentIntentAction(clientSecret: string) {
		if (!stripe.value) {
			error.value = 'Stripe is not initialized.'

			return
		}

		submitting.value = true

		/** @ts-expect-error: `handleNextAction()` is a valid method */
		const result = await stripe.value.handleNextAction({
			clientSecret,
		})

		submitting.value = false

		return result
	}

	return {
		clear,
		error,
		getId,
		stripe,
		card,
		submitting,
		initializeCardElement,
		initialized: computed(() => ready.value),
		filled,
		initializeHandleNextPaymentIntentAction,
		handleNextPaymentIntentAction,
	}
}
