import { router } from '@inertiajs/vue3'

import { route } from '@/composables/route'

type PaymentMethodData = Domain.Payment.Data.PaymentMethodData

/**
 * Deletes the given credit card.
 */
export function deleteCreditCard(paymentMethod: PaymentMethodData) {
	router.delete(route('captainjet.web.payment-methods.destroy', { paymentMethod }))
}

/**
 * Sets the given credit card as the default.
 */
export function setDefaultCreditCard(paymentMethod: PaymentMethodData) {
	router.post(route('captainjet.web.payment-methods.default', { paymentMethod }))
}
