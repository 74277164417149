import { createApp, h } from 'vue'
import { Link, createInertiaApp } from '@inertiajs/vue3'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { modal } from 'momentum-modal'
import { createHead } from '@vueuse/head'
import onesignal from '@onesignal/onesignal-vue3'
import { flare } from '@flareapp/flare-client'
import { flareVue } from '@flareapp/flare-vue'
import importPageComponent from './vite/import-page-component'
import './register-dayjs'
import './register-tracking'
import '@lottiefiles/lottie-player'
import '@fontsource/red-hat-display/variable.css'
import '@fontsource/red-hat-text/variable.css'
import '@fontsource/inter/variable.css'
import '../css/captainjet/app.css'

async function resolve(name: string) {
	return importPageComponent(name, import.meta.glob<true, string, any>('@/pages/**/*.vue', { eager: true }))
}

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
	flare.light()
}

createInertiaApp({
	progress: { color: '#61A7B7' },
	resolve,
	setup({ el, App, props, plugin: inertia }) {
		createApp({ render: () => h(App, props) })
			.component('InertiaLink', Link)
			.use(createHead())
			// .use(sentry())
			.use(inertia)
			.use(modal, { resolve })
			.use(autoAnimatePlugin)
			.use(onesignal, {
				appId: import.meta.env.VITE_CAPTAINJET_ONE_SIGNAL_APP_ID!,
				serviceWorkerPath: '/one-signal-sdk.js',
				allowLocalhostAsSecureOrigin: import.meta.env.DEV,
			})
			.use(flareVue)
			.mount(el)
	},
})
