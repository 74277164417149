<script setup lang="ts">
import { useNewCreditCardDialog, useSelectCreditCardDialog } from '~/ts/dialog'
import { useProperty } from '@/composables/useProperty'
import { deleteCreditCard, setDefaultCreditCard } from '~/ts/payment/credit-cards'

type PaymentMethodData = Domain.Payment.Data.PaymentMethodData

const { isOpen, close, properties } = useSelectCreditCardDialog()
const { open: openNewCreditCardDialog } = useNewCreditCardDialog()

const selectedCardId = ref<string>()
const canConfirm = computed(() => !!selectedCardId.value)
const cards = useProperty<PaymentMethodData[]>('paymentMethods')
const form = useForm({})

whenever(() => properties.value?.selected, () => selectedCardId.value = properties.value?.selected)
whenever(() => !cards.value?.length, () => close())

function isCreditCardSelected(card: PaymentMethodData) {
	return card.id === selectedCardId.value
}

function selectCreditCard(card?: PaymentMethodData) {
	if (card) {
		selectedCardId.value = card.id
	}
}

function confirmSelectedCreditCard() {
	if (!canConfirm.value) {
		return
	}

	properties.value?.onSelect?.(selectedCardId.value!)
	close()
}

function addNewCreditCard() {
	close()
	openNewCreditCardDialog({
		onSave: (stripeId) => {
			selectCreditCard(cards.value.find(({ id }) => id === stripeId))
			confirmSelectedCreditCard()
		},
	})
}
</script>

<template>
	<BaseDialog
		:show="isOpen"
		:closeable="!form.processing"
		title="Select credit card"
		title-size="sm"
		@close="() => close()"
	>
		<form @submit.prevent="confirmSelectedCreditCard">
			<div class="border-t border-turquoise-100 p-6">
				<div class="space-y-4">
					<CreditCard
						v-for="card in cards"
						:key="card.id"
						:card="card"
						:can-select="true"
						:selected="isCreditCardSelected(card)"
						@delete="deleteCreditCard(card)"
						@set-default="setDefaultCreditCard(card)"
						@select="selectCreditCard(card)"
					/>
				</div>

				<div class="mt-4 flex justify-center">
					<BaseButton
						variant="secondary"
						size="md"
						icon="Plus"
						icon-class="h-3.5 w-3.5 shrink-0"
						class="px-6 tracking-tight"
						@click="addNewCreditCard"
					>
						Add new credit card
					</BaseButton>
				</div>

				<BaseButton
					type="submit"
					variant="primary"
					size="lg"
					class="mt-6 w-full font-display text-lg tracking-tight"
					:disabled="!canConfirm"
					:loading="form.processing"
				>
					Save
				</BaseButton>
			</div>
		</form>
	</BaseDialog>
</template>
