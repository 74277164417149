<script setup lang="ts">
import { router } from '@inertiajs/vue3'

import { route } from '@/composables/route'
import { match } from '~/ts/utils/match'

type LegData = Domain.BookingCaptainJet.Data.LegData
type BookingData = Domain.BookingCaptainJet.Data.BookingData

const $props = defineProps<{
	highlighted?: number
	scope?: 'upcoming' | 'archived'
	requests: Paginator<BookingData>
	archived: Paginator<LegData>
	upcoming: Paginator<LegData>
}>()

useHead({
	title: () => match($props.scope!, {
		upcoming: 'Upcoming trips',
		archived: 'Archived trips',
		default: 'Requests',
	}),
})

const tabs = reactive({
	requests: {
		title: 'Requests',
		count: $props.requests.meta.total ?? 0,
		active: computed(() => !$props.scope),
		activate: () => router.visit(route('captainjet.web.booking.index'), { preserveState: true, preserveScroll: true }),
	},
	upcoming: {
		title: 'Upcoming',
		count: $props.upcoming.meta.total ?? 0,
		active: computed(() => $props.scope === 'upcoming'),
		activate: () => router.visit(route('captainjet.web.booking.index', { scope: 'upcoming' }), { preserveState: true, preserveScroll: true }),
	},
	archived: {
		title: 'Archived',
		count: $props.archived.meta.total ?? 0,
		active: computed(() => $props.scope === 'archived'),
		activate: () => router.visit(route('captainjet.web.booking.index', { scope: 'archived' }), { preserveState: true, preserveScroll: true }),
	},
})

onMounted(() => {
	if (!$props.highlighted) {
		return
	}

	setTimeout(() => {
		document.getElementById(`booking-${$props.highlighted}`)?.scrollIntoView({ behavior: 'smooth' })
	}, 100)
})

function newFlight() {
	router.get(route('captainjet.web.index'))
}
</script>


			<script lang="ts">
			import layout from '@/layouts/default.vue'
			export default { layout }
			</script>
			<template>
		
	<page-container>
		<!-- Title and CTA -->
		<header class="flex items-center justify-between">
			<h1 class="font-display text-xl font-bold leading-9 text-blue-500">
				Trips
			</h1>
			<base-button
				size="sm"
				class="px-5"
				variant="primary"
				@click="newFlight"
			>
				Book a flight
			</base-button>
		</header>

		<nav class="mt-4 flex flex-col-reverse md:flex-row md:justify-between">
			<div class="-mx-4 mt-4 flex md:m-0">
				<div class="flex space-x-2.5 overflow-auto whitespace-nowrap px-4 py-1 md:space-x-3.5 md:overflow-visible md:p-0">
					<booking-leg-tab
						v-for="(tab, id) in tabs"
						:key="tab.title + id"
						:active="tab.active"
						:title="tab.title"
						:count="tab.count"
						@click="tab.activate"
					/>
				</div>
			</div>

			<div v-if="false" class="group relative h-10 w-full md:max-w-[300px]">
				<!-- TODO implement search -->
				<input
					class="size-full rounded-full border border-turquoise-100 bg-white pl-11 pr-5 font-medium text-blue-400 transition-colors duration-75 placeholder-shown:text-sm placeholder-shown:font-normal placeholder-shown:italic placeholder-shown:text-blue-200 focus:border-turquoise-600 group-hover:border-turquoise-300 focus:group-hover:border-turquoise-600"
					placeholder="Search date, destination"
					type="text"
				/>
				<div class="pointer-events-none absolute inset-y-0 left-4 flex items-center text-blue-200">
					<icon-search2 class="size-4 shrink-0" />
				</div>
			</div>
		</nav>

		<section class="mt-3.5 flex flex-col gap-y-4">
			<!-- Requests -->
			<template v-if="tabs.requests.active">
				<booking-leg-requested
					v-for="booking in requests.data"
					:key="booking.id"
					:booking="booking"
					:highlighted="highlighted === booking.id && requests.data.length >= 1"
				/>

				<booking-leg-empty
					v-if="requests.data.length === 0"
					@click="newFlight"
				/>
			</template>

			<!-- Upcoming -->
			<template v-if="tabs.upcoming.active">
				<booking-leg-upcoming
					v-for="leg in upcoming.data"
					:key="leg.id"
					:leg="leg"
					:highlighted="highlighted === leg.id && upcoming.data.length >= 1"
				/>

				<booking-leg-empty
					v-if="upcoming.data.length === 0"
					text="You have no upcoming flight."
					@click="newFlight"
				/>
			</template>

			<!-- Archived -->
			<template v-if="tabs.archived.active">
				<booking-leg-archived
					v-for="leg in archived.data"
					:key="leg.id"
					:leg="leg"
				/>

				<booking-leg-empty
					v-if="archived.data.length === 0"
					text="You have no archived flight."
					:call-to-action="false"
				/>
			</template>
		</section>
	</page-container>
</template>
