<script setup lang="ts">
withDefaults(defineProps<{
	error?: string | null
	clearable?: boolean
}>(), {
	error: null,
	clearable: false,
})

const emit = defineEmits(['clear'])
</script>

<template>
	<div class="flex border border-turquoise-200 bg-white focus-within:relative">
		<div class="w-full px-5 py-2">
			<slot name="label" />
			<div class="flex">
				<div class="flex-1">
					<slot />
				</div>
				<button
					v-if="clearable"
					tabindex="-1"
					class="text-blue-200"
					type="button"
					@click.prevent="emit('clear')"
				>
					<IconX class="size-2.5" />
				</button>
			</div>
		</div>
	</div>
</template>
