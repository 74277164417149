<script setup lang="ts">
import { route } from '@/composables/route'
import { usePasswordRecoveryDialog } from '~/ts/dialog'

const { isOpen, close } = usePasswordRecoveryDialog()

const form = useForm({
	email: '',
})

function recover() {
	form.post(route('captainjet.web.password.email'), {
		onSuccess: () => {
			close()
			form.reset()
		},
	})
}
</script>

<template>
	<BaseDialog
		:show="isOpen"
		title="Recover your password"
		title-size="lg"
		@close="close()"
	>
		<form @submit.prevent="recover">
			<div class="p-6 pt-0">
				<div class="space-y-3.5">
					<BaseInput
						id="email"
						v-model="form.email"
						name="email"
						label="Email"
						type="email"
						:error="form.errors.email"
						@clear="form.clearErrors('email')"
					/>
				</div>

				<base-button
					type="submit"
					size="lg"
					class="mt-6 w-full font-display text-lg"
					variant="primary"
					:disabled="form.email === ''"
					:loading="form.processing"
				>
					Send
				</base-button>
			</div>
		</form>
	</BaseDialog>
</template>
