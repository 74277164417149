<script setup lang="ts">
import { Modal } from 'momentum-modal'
import Chat from '../dialogs/Chat.vue'
import Register from '../dialogs/Register.vue'
import Login from '../dialogs/Login.vue'
import Challenge from '../dialogs/Challenge.vue'
import AircraftDetails from '../dialogs/AircraftDetails.vue'
import SelectBillingAddress from '../dialogs/SelectBillingAddress.vue'
import BillingAddress from '../dialogs/BillingAddress.vue'
import NewCreditCard from '../dialogs/NewCreditCard.vue'
import SelectCreditCard from '../dialogs/SelectCreditCard.vue'
import WireTransfer from '../dialogs/WireTransfer.vue'
import PaymentConfirmation from '../dialogs/PaymentConfirmation.vue'
import PasswordRecovery from '../dialogs/PasswordRecovery.vue'
import NewPassword from '../dialogs/NewPassword.vue'

import { NotificationRegion, setupWithInertia } from '~/ts/notifications/notifications'

setupWithInertia()
</script>

<template>
	<!-- Dialogs -->
	<Chat />
	<Register />
	<Login />
	<Challenge />
	<AircraftDetails />
	<SelectBillingAddress />
	<BillingAddress />
	<NewCreditCard />
	<SelectCreditCard />
	<WireTransfer />
	<PaymentConfirmation />
	<PasswordRecovery />
	<NewPassword />

	<!-- Inertia -->
	<Modal />

	<!-- Notifications -->
	<NotificationRegion />
</template>
