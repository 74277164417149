<script setup lang="ts">
import { match } from '~/ts/utils/match'

type BookingStatuses = Domain.BookingCaptainJet.Enums.BookingStatuses

const $props = defineProps<{
	status?: BookingStatuses
	offers?: number
}>()

const state = computed(() => match($props.status!, {
	contract_sent: {
		text: 'Contract available',
		icon: 'IconCheck',
		class: 'lg:text-emerald-600 lg:bg-emerald-50',
	},
	requested: {
		text: 'Requested',
		icon: 'IconSandClock',
		class: 'lg:text-turquoise-500 lg:bg-turquoise-50',
	},
	contract_requested: {
		text: 'Contract pending',
		icon: 'IconSandClock',
		class: 'lg:bg-turquoise-100 lg:text-turquoise-600',
	},
	quoted: {
		text: $props.offers === 1
			? 'Offer available'
			: `${$props.offers} offers`,
		icon: 'IconTicket',
		class: 'lg:text-turquoise-600 lg:bg-turquoise-200',
	},
	cancelled: {
		text: 'Cancelled',
		icon: 'IconX',
		class: 'lg:bg-red-100 lg:text-red-400',
	},
	default: undefined,
}))
</script>

<template>
	<div v-if="state" class="flex items-center rounded-xl px-3 py-1.5 text-[0.815rem] font-medium leading-none" :class="state.class">
		<Icon :name="state?.icon" class="mr-1.5 size-3.5" />
		<span v-text="state.text" />
	</div>
</template>
