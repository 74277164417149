<template>
	<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.904 10.262h1.064a.426.426 0 1 1 0 .853H2.904a.426.426 0 1 1 0-.853ZM6.944 10.262H5.88a.426.426 0 1 0 0 .853h1.064a.426.426 0 1 0 0-.853ZM8.858 10.262h1.064a.426.426 0 1 1 0 .853H8.858a.426.426 0 1 1 0-.853Z" fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.371 2.667h13.925c.756 0 1.37.614 1.373 1.37v8.378c0 .757-.614 1.371-1.37 1.371H1.37A1.372 1.372 0 0 1 0 12.416V4.037c0-.757.614-1.371 1.371-1.371Zm13.927 1.138H1.371a.234.234 0 0 0-.233.233V4.7h14.394v-.663a.234.234 0 0 0-.234-.233Zm-13.927 8.84h13.925a.232.232 0 0 0 .233-.23V6.42H1.138v5.993c0 .128.105.234.233.234Z"
			fill="currentColor"
		/>
	</svg>
</template>
