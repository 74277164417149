import { random } from '../utils/string'

import type { Notification, NotificationOptions } from './types'

import { useProperty } from '@/composables/useProperty'

const notifications = ref<Notification[]>([])

/**
 * Pushes notifications from the server, reactively.
 */
export function setupWithInertia() {
	const notifications = useProperty('notifications')

	watch(notifications, () => {
		const { push } = useNotifications()
		for (const [type, content] of Object.entries(notifications.value)) {
			if (content) {
				push({
					type: type as Notification['type'],
					content: content as Notification['content'],
				})
			}
		}
	}, { immediate: true })
}

export function createNotification(notification: Omit<Notification, 'state'>, options: NotificationOptions = {}) {
	return {
		state: {
			id: random(),
			closing: false,
			decayTime: options?.decay || 300,
		},
		...notification,
	}
}

/** Pushes a notification. */
export function pushNotification(notification: Omit<Notification, 'state'>, options: NotificationOptions = {}) {
	const actual = createNotification(notification, options)
	notifications.value.push(actual)

	if (options?.decay !== false) {
		setTimeout(() => dismissNotification(actual), options?.decay ?? 8000)
	}

	return {
		dismiss: () => dismissNotification(actual),
	}
}

/** Dismisses a notification. */
export function dismissNotification(notification: Notification) {
	const index = notifications.value.findIndex(({ state }) => state.id === notification.state.id)

	if (index === -1) {
		return
	}

	notifications.value.splice(index, 1)
}

export function useNotifications() {
	return {
		notifications: readonly(notifications),
		push: pushNotification,
		dismiss: dismissNotification,
	}
}

export { default as NotificationRegion } from '../../vue/components/Notification/Region.vue'
