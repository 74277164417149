<template>
	<svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.85991 6.96903C6.46212 6.96903 6.06433 6.83435 5.79914 6.56499C5.53395 6.36297 5.33505 6.0936 5.20246 5.7569H4.73837V5.21818H5.13616V5.15084H4.73837V4.61212H5.26875C5.33505 4.27542 5.53395 4.00606 5.79914 3.80404C6.06433 3.60202 6.46212 3.46734 6.85991 3.46734C7.07961 3.46734 7.2538 3.51356 7.45788 3.5677C7.50012 3.57891 7.54373 3.59048 7.5892 3.60202C7.78809 3.7367 7.98699 3.87138 8.11958 4.0734L8.25218 4.20808L7.65549 4.61212L7.5892 4.54478C7.54222 4.49707 7.50357 4.44936 7.46735 4.40465C7.40131 4.32313 7.34333 4.25157 7.2577 4.20808C7.12511 4.14074 6.99251 4.14074 6.85991 4.14074C6.66102 4.14074 6.46212 4.20808 6.32953 4.34276C6.19693 4.4101 6.13063 4.47744 6.06433 4.61212H7.2577L7.05881 5.15084H5.93174V5.21818H7.05881L6.85991 5.7569H6.06433C6.13063 5.89158 6.19693 5.95892 6.32953 6.0936C6.46212 6.16094 6.66102 6.22829 6.85991 6.22829C6.99251 6.22829 7.12511 6.22829 7.2577 6.16094C7.3903 6.09361 7.4566 6.02626 7.5892 5.89158L7.65549 5.7569L8.18588 6.16094L8.11958 6.29563C7.98699 6.49765 7.78809 6.69967 7.5892 6.83435C7.3903 6.90169 7.12511 6.96903 6.85991 6.96903Z" fill="currentColor" />
		<path d="M5.06979 8.98923H8.45101C8.64991 8.98923 8.8488 8.78721 8.8488 8.58519C8.8488 8.38317 8.7162 8.18115 8.45101 8.18115H5.06979C4.8709 8.18115 4.672 8.38317 4.672 8.58519C4.7383 8.78721 4.8709 8.98923 5.06979 8.98923Z" fill="currentColor" />
		<path d="M5.06979 10.2687H10.1748C10.3737 10.2687 10.5063 10.4034 10.5063 10.6727C10.5063 10.8747 10.3074 11.0768 10.1085 11.0768H5.06979C4.8046 11.0768 4.672 10.8747 4.672 10.6727C4.672 10.4034 4.8709 10.2687 5.06979 10.2687Z" fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.9648 1.58179C13.0919 1.58179 14.0864 2.45721 14.219 3.60199L14.3516 13.905C14.3516 14.2417 14.1527 14.5111 13.8212 14.6458C13.6886 14.7131 13.6223 14.7131 13.4897 14.7131C13.2245 14.7131 13.0256 14.5784 12.893 14.4437L12.6278 14.1744L12.0974 14.6458C11.8985 14.7804 11.6996 14.8478 11.5007 14.8478C11.2355 14.8478 11.0366 14.7131 10.904 14.5784L10.44 14.107L9.97587 14.5784C9.84328 14.7804 9.57808 14.8478 9.37919 14.8478C9.18029 14.8478 8.9151 14.7131 8.7825 14.5784L8.31841 14.107L7.85433 14.7131C7.65543 14.8478 7.52283 14.9151 7.25764 14.9151C6.99245 14.9151 6.79355 14.7804 6.66095 14.6458L6.19687 14.1744L5.66648 14.6458C5.46758 14.7804 5.26869 14.8478 5.06979 14.8478C4.8046 14.8478 4.60571 14.7131 4.47311 14.5784L4.00902 14.107L3.74383 14.3764C3.54493 14.5111 3.34604 14.5784 3.14714 14.5784C3.01455 14.5784 2.94825 14.5784 2.81565 14.5111C2.55046 14.3764 2.35156 14.107 2.35156 13.7703V3.66933C2.35156 2.52455 3.27974 1.58179 4.47311 1.58179H11.9648ZM12.9593 13.0296H13.0919V3.73667C12.9593 3.13061 12.4952 2.72657 11.9648 2.72657H4.47311C3.94272 2.72657 3.47863 3.13061 3.47863 3.66933V13.0296H3.67753C3.81013 12.8949 3.94272 12.8949 4.07532 12.8949C4.27421 12.8949 4.47311 12.9623 4.60571 13.0969L5.13609 13.6357L5.60018 13.1643C5.79908 12.9623 5.99797 12.8949 6.19687 12.8949C6.39576 12.8949 6.59466 12.9623 6.72725 13.0969L7.25764 13.6357L7.78803 13.1643C7.92062 12.9623 8.11952 12.8949 8.31841 12.8949C8.51731 12.8949 8.7162 12.9623 8.8488 13.0969L9.37919 13.6357L9.84328 13.1643C10.0422 12.9623 10.2411 12.8949 10.44 12.8949C10.6389 12.8949 10.8378 12.9623 10.9703 13.0969L11.4344 13.6357L11.9648 13.1643C12.0974 12.9623 12.2963 12.8949 12.4952 12.8949C12.6941 12.8949 12.8267 12.9623 12.9593 13.0296Z"
			fill="currentColor"
		/>
	</svg>
</template>
