<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import clone from 'lodash.clonedeep'

import type { SpecialRequests } from '~/ts/estimates'

import { route } from '@/composables/route'
import { requestsToData, toIcons } from '~/ts/estimates'
import { parseDateTime, parseFlightTime } from '~/ts/utils/datetime'
import { useProperty } from '@/composables/useProperty'

type LegData = Domain.BookingCaptainJet.Data.LegData

const props = defineProps<{
	bookingId: bigint
	leg: LegData
	modelValue: SpecialRequests
	number?: number
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', requests: SpecialRequests): void
}>()

const [specialRequestsDialogOpen, toggleSpecialRequestsDialog] = useToggle()
const requests = ref(clone(props.modelValue))
const requestsWithIcon = computed(() => toIcons(requests.value))

watch(requests, (requests) => {
	router.patch(
		route('captainjet.web.booking.special-requests.store', { leg: props.leg.id }),
		requestsToData(requests),
	)

	emit('update:modelValue', clone(requests))
}, { deep: true })

const useTwelveHourClock = useProperty('security.user.use_twelve_hour_clock') ?? false
</script>

<template>
	<booking-dialog-special-request
		v-model="requests"
		:show="specialRequestsDialogOpen"
		@close="toggleSpecialRequestsDialog(false)"
	/>

	<div class="rounded-2xl bg-white md:flex">
		<div class="flex-1 p-5 md:p-6">
			<div class="flex items-center justify-between">
				<div class="flex items-center space-x-3.5 font-medium tracking-widest text-warning-600">
					<icon-aircraft-flying class="size-6" />
					<span>{{ number ? `Flight n°${number}` : 'Flight' }}</span>
				</div>
				<span
					class="text-sm tracking-tighter text-blue-400"
					:title="leg.departs_at_local_time"
					v-text="parseDateTime(leg.departs_at_local_time, { ignoreTimezone: true, useTwelveHourClock }).date"
				/>
			</div>

			<div class="mt-4 flex">
				<div class="my-0.5 flex pb-6">
					<div class="flex flex-col justify-between text-xs font-medium text-blue-500">
						<div class="flex items-center">
							<span
								:title="leg.departs_at_local_time"
								v-text="parseDateTime(leg.departs_at_local_time, { ignoreTimezone: true, useTwelveHourClock }).time"
							/>
						</div>
						<div v-if="leg.flight_time" class="flex items-center text-blue-300">
							<icon-approximation v-if="true" class="mr-1 mt-px w-1.5" />
							<span
								:title="leg.flight_time.toString()"
								v-text="parseFlightTime(leg.flight_time, 'HH[h]mm')"
							/>
						</div>
						<div v-if="leg.arrives_at_local_time" class="flex items-center">
							<icon-approximation v-if="true" class="mr-1 mt-px w-1.5 text-blue-200" />
							<span
								:title="leg.arrives_at_local_time"
								v-text="parseDateTime(leg.arrives_at_local_time, { ignoreTimezone: true, useTwelveHourClock }).time"
							/>
						</div>
					</div>

					<div class="mx-5 flex flex-col items-center justify-between">
						<div class="shrink-0 rounded-full bg-warning-500 p-1">
							<div class="rounded-full bg-white p-1" />
						</div>
						<div class="flex-1 border-l-2 border-dashed border-turquoise-300" />
						<div class="shrink-0 rounded-full bg-warning-500 p-1">
							<div class="rounded-full bg-white p-1" />
						</div>
					</div>
				</div>

				<div class="flex flex-col space-y-5">
					<div class="space-y-1">
						<p class="text-lg font-medium leading-5 tracking-tight text-blue-500">
							{{ leg.departure_airport?.name }}
						</p>
						<p class="text-sm tracking-tighter text-blue-300">
							{{ leg.departure_airport?.country.name }}
						</p>
					</div>

					<div class="space-y-1">
						<p class="text-lg font-medium leading-5 tracking-tight text-blue-500">
							{{ leg.arrival_airport?.name }}
						</p>
						<p class="text-sm tracking-tighter text-blue-300">
							{{ leg.arrival_airport?.country.name }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<!-- Separator -->
		<div class="relative">
			<div class="border-t border-dashed border-turquoise-100 md:h-full md:border-l md:border-t-0">
				<div class="absolute -left-px -top-2 h-4 w-2 rounded-r-full bg-turquoise-50 md:-left-2 md:-top-px md:h-2 md:w-4 md:rounded-b-full md:rounded-tr-none" />
				<div class="absolute -right-px -top-2 h-4 w-2 rounded-l-full bg-turquoise-50 md:-bottom-px md:-left-2 md:right-auto md:top-auto md:h-2 md:w-4 md:rounded-t-full md:rounded-bl-none" />
			</div>
		</div>

		<div class="flex flex-col p-5 md:w-64 md:p-6">
			<p class="font-display font-medium uppercase leading-5 tracking-widest text-blue-500">
				Special request
			</p>
			<div class="mt-4 flex flex-1 flex-col justify-end">
				<button
					v-if="requestsWithIcon.length"
					class="mt-1 w-full focus:ring-2 focus-visible:ring-2 focus-visible:ring-turquoise-600"
					@click="toggleSpecialRequestsDialog(true)"
				>
					<p class="text-left font-medium text-turquoise-500">
						My special requests
					</p>
					<div class="group mt-2 flex">
						<div class="mr-3 flex flex-1 items-center space-x-3 overflow-hidden text-blue-200">
							<template v-for="{ label, icon } in requestsWithIcon" :key="label">
								<icon
									:name="`Icon${icon}`"
									:title="label"
									class="size-5 shrink-0"
								/>
							</template>
						</div>
						<div class="flex items-center">
							<icon-chevron-right class="h-3.5 text-turquoise-400 transition-colors group-hover:text-turquoise-500" />
						</div>
					</div>
				</button>
				<base-button
					v-else
					variant="secondary"
					size="md"
					class="w-full space-x-2"
					icon="Plus"
					icon-class="w-3.5 h-3.5"
					@click="toggleSpecialRequestsDialog(true)"
				>
					Specify my request
				</base-button>
			</div>
		</div>
	</div>
</template>
