type Callable<T> = T | ((...args: any[]) => T)
type Lookup<TValue extends string | number = string, TReturnValue = unknown> =
	Record<TValue, Callable<TReturnValue>>
	| (Partial<Record<TValue, Callable<TReturnValue>>> & { default: Callable<TReturnValue> })

export function match<TValue extends string | number = string, TReturnValue = unknown>(
	value: TValue,
	lookup: Lookup<TValue, TReturnValue>,
	...args: any[]
): TReturnValue {
	if (value in lookup || 'default' in lookup) {
		const returnValue = value in lookup
			? lookup[value]
			// @ts-expect-error: Property default does not exist.
			: lookup.default

		return typeof returnValue === 'function' ? returnValue(...args) : returnValue
	}

	const handlers = Object.keys(lookup)
		.map((key) => `"${key}"`)
		.join(', ')

	const error = new Error(`Tried to handle "${value}" but there is no handler defined. Only defined handlers are: ${handlers}.`)

	if (Error.captureStackTrace) {
		Error.captureStackTrace(error, match)
	}

	throw error
}
