<script setup lang="ts">
import type { Step } from './Steps.vue'

const props = withDefaults(defineProps<{
	steps: Step[]
	showFirstImage?: boolean
	maxScale?: number
	scaleRange?: number
	scaleCurvature?: number
}>(), {
	showFirstImage: true,
	maxScale: 1.12537313433,
	scaleRange: 1,
	scaleCurvature: 0.83,
})

const phoneAnchors = ref<HTMLElement>()
const phoneScales = ref<number[]>([])
const scrollable = ref<HTMLElement>()
const images = ref<HTMLElement[]>()

defineExpose({
	phoneAnchors,
	phoneScales,
	scrollable,
})

function updateScale() {
	if (!scrollable.value || !images.value) {
		return
	}

	// Calculate scroll progress
	const availableScroll = scrollable.value.scrollWidth - scrollable.value.clientWidth
	const scrollProgress = (images.value.length - 1) * scrollable.value.scrollLeft / availableScroll

	// Set scale of each image based on proximity to current scroll position
	for (let i = 0; i < images.value.length; ++i) {
		const proximity = (1 - Math.min(props.scaleRange, Math.abs(scrollProgress - i)) / props.scaleRange)
		const scale = 1 + (props.maxScale - 1) * proximity ** props.scaleCurvature
		images.value[i].style.transform = `scale(${scale})`
		phoneScales.value[i] = scale
	}
}

onMounted(updateScale)
</script>

<template>
	<div class="relative h-[474.5px] w-[499px] shrink-0">
		<div
			ref="scrollable"
			class="pointer-events-none absolute inset-0 flex overflow-x-scroll rounded-2xl scrollbar-hide"
			@scroll="updateScale"
		>
			<div class="flex h-full items-center gap-x-[68px] bg-blue-700 px-[166.5px]">
				<div
					v-for="(step, i) in props.steps"
					ref="images"
					:key="i"
					class="relative flex h-[335px] w-[166px] shrink-0 items-center justify-center"
					:style="{ visibility: i > 0 || props.showFirstImage ? 'visible' : 'hidden' }"
				>
					<img
						class="absolute inset-0"
						:srcSet="`${step.screenshot2x} 2x`"
						:src="step.screenshot"
						:alt="`CaptainJet app: ${step.title}`"
					/>
					<!-- Phone end anchor -->
					<div ref="phoneAnchors" class="absolute" />
				</div>
			</div>
		</div>
	</div>
</template>
