<script setup lang="ts">
const $props = defineProps<{
	content: string
}>()

useHead({
	title: 'Privacy Policy',
})
</script>


			<script lang="ts">
			import layout from '@/layouts/marketing.vue'
			export default { layout }
			</script>
			<template>
		
	<div class="font-nova">
		<BaseHeader :marketing="true" />

		<LegalDocumentHero title="Privacy Policy" />

		<LegalDocumentContent :content="$props.content" />

		<MarketingFooter />
	</div>
</template>
