<script setup lang="ts">
import LocationIcon from '../Icon/Location2.vue'

import type { Data as ImageData } from '@/components/ImageGallery.vue'

const images = Object.values(import.meta.glob<string>('~/images/captainjet/marketing/about/*/1x.webp', { eager: true, query: '?url', import: 'default' }))
const images2x = Object.values(import.meta.glob<string>('~/images/captainjet/marketing/about/*/2x.webp', { eager: true, query: '?url', import: 'default' }))
const imageDatas: ImageData[] = [
	{
		src: '',
		label: 'Koh Yao Noi, Thailand',
	},
	{
		src: '',
		label: 'Courchevel, France',
	},
	{
		src: '',
		label: 'Paris, France',
	},
	{
		src: '',
		label: 'Fjord, Greenland',
	},
	{
		src: '',
		label: 'Siwa Oasis, Egypt',
	},
]
imageDatas.forEach((data, i) => {
	data.src = images[i]
	data.srcSet = `${images2x[i]} 2x`
})
const imageIndex = ref(1)
</script>

<template>
	<div class="relative overflow-hidden bg-white py-9 lg:pb-7 lg:pt-24">
		<IllustrationAbstractLines class="absolute left-[calc(50%_-_72px)] top-[-265px] hidden w-[1335px] lg:block" />
		<MarketingContainer class="relative">
			<div class="gap-x-20 lg:flex lg:items-start lg:justify-between">
				<div class="mx-auto w-full max-w-xl shrink-0 lg:mx-0 lg:max-w-[47%]">
					<IllustrationMarketingGlobe class="mx-auto w-36 lg:mx-0" />
					<p class="mt-8 text-center font-inter text-3xl font-medium text-blue-500 lg:mt-16 lg:text-left lg:font-bold">
						About us
					</p>
					<p class="mt-10 font-medium text-blue-300">
						At CaptainJet, we pride ourselves on being at the forefront of luxury private jet travel, bringing together a decade of aviation experience, innovation and outstanding service to our clients.
					</p>
					<p class="mt-3 font-medium text-blue-300">
						Get access to over 3,800 private jets via our platform and mobile app and discover the modern face of private charter flights.
					</p>
					<p class="mt-10 font-inter font-bold text-blue-500 lg:mt-16">
						What we do
					</p>
					<p class="mt-4 font-medium text-blue-300">
						We offer a simple and quick private charter flight service with the most transparent prices on the market and no long-term binding agreements. Our team of experienced and passionate aviation professionals will have your back when needed while giving you the freedom and flexibility you deserve.
					</p>
				</div>
				<ImageGallery
					v-if="imageDatas"
					class="hidden w-[679px] lg:flex"
					:datas="imageDatas"
					:index="imageIndex"
					:label-icon="h(LocationIcon)"
					:darken="true"
					:lighten="true"
					:on-index-change="value => imageIndex = value"
				/>
			</div>
		</MarketingContainer>
	</div>
</template>
