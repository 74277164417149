<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import type { Document } from '~/ts/passengers'

import { useFilepond } from '@/composables/useFilepond'
import countries from '~/ts/phone/countries'
import Flag from '@/components/Icon/Flag.vue'

const props = defineProps<{
	modelValue: Partial<Document>
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', value: Partial<Document>): void
}>()

const document = useVModel(props, 'modelValue', emit)

/*
|--------------------------------------------------------------------------
| Attachments
|--------------------------------------------------------------------------
*/

const { FilePond, getPreviewStyle, status, files, pond, server } = useFilepond()

watch(files, (files) => {
	document.value.attachments = []
	document.value.attachments.push(...files.map((file) => file.serverId))
})

function onFileDrop(event: DragEvent) {
	if (!event.dataTransfer?.items) {
		return
	}

	const files: File[] = []
	for (let i = 0; i < event.dataTransfer.files.length; i++) {
		files.push(event.dataTransfer.files[i])
	}

	pond.value?.addFiles(files.filter(Boolean))
}
</script>

<template>
	<div class="flex-1 space-y-6 p-6 py-4" @drop.prevent="onFileDrop" @dragover.prevent>
		<!-- Type of passenger -->
		<div>
			<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
				Type
			</span>

			<div class="mt-3 flex space-x-2.5">
				<SelectableButton
					size="sm"
					class="flex-1 space-x-2.5 text-sm"
					icon="Passport"
					icon-class="w-4 h-4"
					:checked="document.type === 'passport'"
					@click="document.type = 'passport'"
				>
					Passport
				</SelectableButton>
				<SelectableButton
					size="sm"
					class="flex-1 space-x-2.5 text-sm"
					icon="IdCard"
					icon-class="w-4 h-4"
					:checked="document.type === 'id_card'"
					@click="document.type = 'id_card'"
				>
					ID Card
				</SelectableButton>
				<SelectableButton
					size="sm"
					class="flex-1 space-x-2.5 text-sm"
					icon="Gun"
					icon-class="w-4 h-4"
					:checked="document.type === 'gun_permit'"
					@click="document.type = 'gun_permit'"
				>
					Gun permit
				</SelectableButton>
			</div>
		</div>

		<!-- Country -->
		<div>
			<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
				Country
			</span>

			<Menu v-slot="{ open }" as="div" class="relative mt-3">
				<MenuButton
					:class="[
						{ 'bg-turquoise-100': open },
						{ 'bg-turquoise-50': !open },
						'flex w-full items-center justify-between rounded-xl bg-turquoise-50 px-3.5 pb-3 pt-2 focus-visible:ring-2 focus-visible:ring-turquoise-600',
					]"
				>
					<div class="text-left font-medium">
						<span class="text-sm text-blue-300">
							Country
						</span>
						<div class="text-blue-500">
							<div v-if="document.country" class="flex items-center space-x-2">
								<Flag :code="document.country.iso2.toLowerCase()" :name="document.country.name" class="mr-2 h-3 overflow-hidden rounded-sm" />
								<span v-text="document.country.name" />
							</div>
							<p v-else>
								Select a country
							</p>
						</div>
					</div>
					<IconChevronDown :class="[{ 'rotate-180': open }, 'w-3 text-turquoise-400 transition-transform duration-75']" />
				</MenuButton>

				<transition
					v-show="open"
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="scale-y-95 opacity-0 origin-top"
					enter-to-class="scale-y-100 opacity-100"
					leave-active-class="transition duration-75 ease-in origin-top"
					leave-from-class="scale-y-100 opacity-100"
					leave-to-class="scale-y-95 opacity-0"
				>
					<MenuItems static class="absolute inset-x-0 z-10 mt-2 overflow-hidden rounded-2xl shadow focus:outline-none">
						<div class="flex max-h-60 w-full flex-col divide-y divide-turquoise-100 overflow-y-auto bg-white">
							<MenuItem v-for="(country, index) in countries" :key="index" v-slot="{ active }">
								<button
									type="button"
									:class="[
										{ 'bg-turquoise-50': active || document.country?.name === country.name },
										'flex items-center space-x-2 px-5 py-3.5 hover:bg-turquoise-50',
									]"
									@click="document.country = country"
								>
									<Flag :code="country.iso2.toLowerCase()" :name="country.name" class="mr-2 h-3 overflow-hidden rounded-sm" />
									<span class="text-left text-sm font-medium text-blue-500" v-text="country.name" />
								</button>
							</MenuItem>
						</div>
					</MenuItems>
				</transition>
			</Menu>
		</div>

		<!-- Attachments -->
		<div>
			<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
				Attachments
			</span>

			<FilePond
				v-show="false"
				ref="pond"
				accepted-file-types="image/*, application/pdf"
				:allow-multiple="true"
				:server="server"
			/>

			<button
				v-if="!document.attachments?.length"
				class="mt-3 flex h-32 w-full flex-col items-center rounded-xl border border-turquoise-200 p-4 transition hover:border-turquoise-300"
				@click.prevent="pond?.browse()"
			>
				<IllustrationTravelDocument class="h-14" />
				<p class="mt-4 text-blue-300">
					Drop your files here
				</p>
			</button>

			<div v-else class="mt-3 flex h-32 w-full grow gap-3 overflow-x-auto pb-3">
				<button
					v-if="document.attachments?.length"
					class="group relative flex h-full w-32 shrink-0 items-center justify-center overflow-hidden rounded-xl border border-turquoise-100 bg-turquoise-50/50 px-3 py-2 text-gray-400 transition hover:border-turquoise-300 hover:bg-turquoise-50 hover:text-turquoise-500"
					@click.prevent="pond?.browse()"
				>
					<IconPlus class="size-3" />
				</button>
				<div
					v-for="file in files"
					:key="file.id"
					class="relative flex h-full w-40 shrink-0 flex-col overflow-hidden rounded-xl px-3 py-2 text-sm font-medium transition"
					:style="getPreviewStyle(file)"
					:class="[
						{
							'items-start text-white': file.previewUrl,
							'items-center border': !file.previewUrl,
						},
						{
							'bg-danger-100': status(file) === 'error',
							'text-danger-100': status(file) === 'error' && file.previewUrl,
							'border-danger-200 text-danger-600': status(file) === 'error' && !file.previewUrl,
							'border-turquoise-200 bg-turquoise-100 text-blue-300': status(file) === 'loading',
							'border-turquoise-100 bg-white text-blue-300': status(file) === 'idle',
						},
					]"
				>
					<div class="flex w-full items-center">
						<!-- Error/retry icon -->
						<IconExclamationCircle v-if="status(file) === 'error' && file.previewUrl" class="mr-2 size-5 shrink-0" />
						<button v-if="status(file) === 'error' && !file.previewUrl" class="py-0.5 opacity-50 transition hover:opacity-80" @click.prevent="file.retry()">
							<IconReload class="mr-2 size-4 shrink-0 scale-[1.2]" />
						</button>
						<!-- Status Icons -->
						<IconLoading v-if="status(file) === 'loading'" class="mr-2 size-5 shrink-0" />
						<!-- Filename -->
						<span class="mr-2 truncate" :title="file.filename" v-text="file.filename" />
						<!-- Remove button -->
						<button class="ml-auto border-l border-gray-200 py-0.5 pl-2.5 opacity-50 transition hover:opacity-80" @click.prevent="file.remove()">
							<IconX class="size-3" />
						</button>
					</div>

					<div v-if="status(file) === 'error' && file.previewUrl" class="pointer-events-none absolute inset-0 flex items-center justify-center">
						<button class="pointer-events-auto mt-1.5 rounded-full bg-red-100 p-1 text-red-500 transition hover:text-red-400" @click.prevent="file.retry()">
							<IconReload class="size-4.5" />
						</button>
					</div>

					<div v-else-if="status(file) === 'idle' && !file.previewUrl" class="flex h-full items-center justify-center">
						<IconDocumentAircraft class="h4.5 w-4.5 opacity-80" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
