<script setup lang="ts">
import { clamp } from '@vueuse/core'
import SeatIcon from '../Icon/Seat.vue'
import SearchAircraftIcon from '../Icon/SearchAircraft.vue'
import TicketIcon from '../Icon/Ticket2.vue'
import CreditCardIcon from '../Icon/CreditCard.vue'
import DocumentIcon from '../Icon/DocumentSigned.vue'
import EuroIcon from '../Icon/Euro.vue'
import MastercardIcon from '../Icon/Mastercard.vue'
import ItineraryIcon from '../Icon/Itinerary.vue'

import type { VNode } from 'vue'
import type { Vector2 } from '~/ts/utils/math'

import { lerp, smoothDamp } from '~/ts/utils/math'
import londonImg900 from '~/images/captainjet/marketing/steps/london/900w.webp'
import londonImg1440 from '~/images/captainjet/marketing/steps/london/1440w.webp'
import londonImg1920 from '~/images/captainjet/marketing/steps/london/1920w.webp'
import screenshotImg from '~/images/captainjet/marketing/steps/screenshots/1/407w.webp'
import screenshotImg2x from '~/images/captainjet/marketing/steps/screenshots/1/814w.webp'
import aircraftImg from '~/images/captainjet/marketing/steps/aircraft/1x.webp'
import aircraftImg2x from '~/images/captainjet/marketing/steps/aircraft/2x.webp'
import aircraftFleetImg from '~/images/captainjet/marketing/steps/aircraft-fleet/1x.webp'
import aircraftFleetImg2x from '~/images/captainjet/marketing/steps/aircraft-fleet/2x.webp'

export interface Step {
	title: string
	paragraphs: VNode[]
	screenshot: string
	screenshot2x: string
	aircraftImg: string
	aircraftImg2x: string
	aircraftIcon?: VNode
	mainIcon: VNode
}

const stepScreenshots = Object.values(import.meta.glob<string>('~/images/captainjet/marketing/steps/screenshots/*/187w.webp', { eager: true, query: '?url', import: 'default' }))
const stepScreenshots2x = Object.values(import.meta.glob<string>('~/images/captainjet/marketing/steps/screenshots/*/374w.webp', { eager: true, query: '?url', import: 'default' }))
const steps: Step[] = [
	{
		title: 'Create your trip',
		paragraphs: [
			h('p', 'Your Jet, Your Way. Choose from over 3,800 aircraft and book a ground transfer in just few clicks. Personalise your trip by adding your favourite catering and enjoy a glass of champagne when you board. Indicate special requests such as bulk luggage and travel with confidence.'),
		],
		screenshot: screenshotImg,
		screenshot2x: screenshotImg2x,
		aircraftImg,
		aircraftImg2x,
		mainIcon: h(SeatIcon),
	},
	{
		title: 'Select your aircraft',
		paragraphs: [
			h('p', 'Customise your trip with advanced search filters: select your preferred private jet type (small, medium or large), choose between non-smoking versus smoking cabin, opt for Internet on board and optimise your journey by selecting the best fitting aircraft.'),
			h('p', 'In tailor-made mode, you only need to tell us where you fly, we will make sure to provide you with the most suitable selection of aircrafts for charter.'),
		],
		screenshot: '',
		screenshot2x: '',
		aircraftImg: aircraftFleetImg,
		aircraftImg2x: aircraftFleetImg2x,
		mainIcon: h(SearchAircraftIcon),
	},
	{
		title: 'Quick & transparent quotes',
		paragraphs: [
			h('p', 'Receive your first quotation within minutes of your request. We believe that strongest relationships are based on trust, so we are fully transparent about the price for each quote.'),
		],
		screenshot: '',
		screenshot2x: '',
		aircraftImg,
		aircraftImg2x,
		aircraftIcon: h(EuroIcon),
		mainIcon: h(TicketIcon),
	},
	{
		title: 'Easy payment through the app',
		paragraphs: [
			h('p', 'CaptainJet makes paying for your private charter flight easier than a breeze. Secure, encrypted, self-service system supports digital contracts, signatures, and credit card payments, issuing instant confirmation as your transaction is processed.'),
		],
		screenshot: '',
		screenshot2x: '',
		aircraftImg,
		aircraftImg2x,
		aircraftIcon: h(MastercardIcon),
		mainIcon: h(CreditCardIcon),
	},
	{
		title: 'Flight briefings via the app',
		paragraphs: [
			h('p', 'Get all the pertinent information about your private flight from the CaptainJet’s app: stay in the loop regarding meeting points, weather at your destination, pilot contact details, catering information, your booked car transfers and more.'),
		],
		screenshot: '',
		screenshot2x: '',
		aircraftImg,
		aircraftImg2x,
		aircraftIcon: h(ItineraryIcon),
		mainIcon: h(DocumentIcon),
	},
]
steps.forEach((step, i) => {
	if (!step.screenshot) {
		step.screenshot = stepScreenshots[i]
	}

	if (!step.screenshot2x) {
		step.screenshot2x = stepScreenshots2x[i]
	}
})

const windowSize = useWindowSize()
const container = ref<HTMLElement>()
const phoneAnchor = ref<HTMLElement>()
const phone = ref<HTMLElement>()
const phoneProgress = ref(0)
const phoneTargetCoords = ref<Vector2>()
const phoneTargetScale = ref<number>()
const phoneSmoothing = ref(0)
const phoneVel = ref<Vector2>({ x: 0, y: 0 })
const phoneScaleVel = ref(0)
const showPhone = ref(false)
const secretScrollable = ref<HTMLElement>()
const scrollSnapElements = ref<HTMLElement[]>()
const content = ref<HTMLElement>()
const contentSpacerTop = ref<HTMLElement>()
const contentSpacerBottom = ref<HTMLElement>()
const contentStepScrollDistance = 650
const contentProgress = ref(0)
const aircraftTrack = ref<SVGElement>()
const aircraftTrackGap = 290
const aircraftTrackFadeRange = 50
const aircraftOffset = ref<number>()
const carousel = ref()
const info = ref()
const stepIndex = ref(0)

function setPhonePosition(value: string) {
	if (phone.value) {
		phone.value.style.position = value
	}
}

function getPhonePosition(): string {
	return phone.value ? phone.value.style.position : 'unset'
}

// Phone coordinates are relative to the container
function setPhoneCoords(coords: Vector2) {
	if (phone.value) {
		phone.value.style.top = `${coords.y - 0.5 * phone.value.clientHeight}px`
		phone.value.style.left = `${coords.x - 0.5 * phone.value.clientWidth}px`
	}
}

function getPhoneCoords(): Vector2 {
	return phone.value
		? {
				x: phone.value.offsetLeft + 0.5 * phone.value.clientWidth,
				y: phone.value.offsetTop + 0.5 * phone.value.clientHeight,
			}
		: {
				x: 0,
				y: 0,
			}
}

function modifyPhoneCoords(change: Vector2) {
	const coords = getPhoneCoords()
	setPhoneCoords({
		x: coords.x + change.x,
		y: coords.y + change.y,
	})
}

function setPhoneScale(value: number) {
	if (phone.value) {
		phone.value.style.transform = `scale(${value})`
	}
}

function getPhoneScale(): number {
	return phone.value ? phone.value.getBoundingClientRect().width / phone.value.offsetWidth : 1
}

function updatePhone() {
	const startAnchor = phoneAnchor.value
	const endAnchor = carousel.value.phoneAnchors[0]
	const endScale = carousel.value.phoneScales[0]
	if (!container.value || !startAnchor || !content.value || !endAnchor || !endScale) {
		return
	}

	// Calculate progress
	const startAnchorRect = startAnchor.getBoundingClientRect()
	const contentRect = content.value.getBoundingClientRect()
	const contentCenterY = contentRect.y + 0.5 * contentRect.height
	const progress = clamp((0.5 * windowSize.height.value - startAnchorRect.y) / (contentCenterY - startAnchorRect.y), 0, 1)
	const isAtTarget = progress >= 1 - 0.001

	// Calculate target coordinates and scale (for use with smoothing)
	const containerRect = container.value.getBoundingClientRect()
	const endAnchorRect = endAnchor.getBoundingClientRect()
	phoneTargetCoords.value = isAtTarget
		? endAnchorRect
		: {
				x: lerp(startAnchorRect.x - containerRect.x, endAnchorRect.x - containerRect.x, progress),
				y: lerp(startAnchorRect.y - containerRect.y, endAnchorRect.y - containerRect.y, progress),
			}
	phoneTargetScale.value = lerp(2.45, endScale, progress)

	// Reduce smoothing to bring the the phone and its duplicate target into
	// alignment over the first small section of the content progress.
	const alignmentRange = 0.16
	phoneSmoothing.value = lerp(0.07, 0.02, Math.min(contentProgress.value / alignmentRange, 1))

	// Lock to window once target is reached so the
	// effect of scrolling doesn't show in the smoothing.
	const prevPosition = getPhonePosition()
	const position = isAtTarget ? 'fixed' : 'absolute'
	setPhonePosition(position)

	if (prevPosition === 'absolute' && position === 'fixed') {
		// Convert from container absolute coords to fixed window coords
		modifyPhoneCoords({
			x: containerRect.x,
			y: containerRect.y,
		})
	} else if (prevPosition === 'fixed' && position === 'absolute') {
		// Reverse of above
		modifyPhoneCoords({
			x: -containerRect.x,
			y: -containerRect.y,
		})
	}

	// Hide the phone when it reaches its target
	showPhone.value = contentProgress.value <= alignmentRange
	phoneProgress.value = progress
}

// Smooth the phone to its target coordinates and scale
useRafFn(({ delta }) => {
	if (!phoneTargetCoords.value || !phoneTargetScale.value) {
		return
	}

	// ——— Coordinates ———
	const coords = getPhoneCoords()
	const smoothedData = {
		x: smoothDamp(
			coords.x,
			phoneTargetCoords.value.x,
			phoneVel.value.x,
			phoneSmoothing.value,
			Number.MAX_SAFE_INTEGER,
			delta / 1000,
		),
		y: smoothDamp(
			coords.y,
			phoneTargetCoords.value.y,
			phoneVel.value.y,
			phoneSmoothing.value,
			Number.MAX_SAFE_INTEGER,
			delta / 1000,
		),
	}

	setPhoneCoords({
		x: smoothedData.x.smoothed,
		y: smoothedData.y.smoothed,
	})
	phoneVel.value = {
		x: smoothedData.x.velocity,
		y: smoothedData.y.velocity,
	}

	// ——— Scale ———
	const scale = getPhoneScale()
	const scaleSmoothData = smoothDamp(
		scale,
		phoneTargetScale.value,
		phoneScaleVel.value,
		phoneSmoothing.value,
		Number.MAX_SAFE_INTEGER,
		delta / 1000,
	)

	setPhoneScale(scaleSmoothData.smoothed)
	phoneScaleVel.value = scaleSmoothData.velocity
})

/** Recalculates the offset of the aircraft from the start of the aircraft track. */
function updateAircraftOffset() {
	if (!aircraftTrack.value) {
		return
	}

	const aircraftTrackRect = aircraftTrack.value.getBoundingClientRect()
	const aircraftRect = info.value.aircraft.getBoundingClientRect()
	aircraftOffset.value = aircraftRect.x + 0.5 * info.value.aircraft.clientWidth - aircraftTrackRect.x
}

function updateContent() {
	requestAnimationFrame(() => {
		if (!secretScrollable.value || !aircraftTrack.value || !aircraftOffset.value) {
			return
		}

		// Calculate progress
		const secretScrollableRect = secretScrollable.value.getBoundingClientRect()
		const progress = clamp(-secretScrollableRect.y / ((steps.length - 1) * contentStepScrollDistance), 0, 1)

		// Update carousel and aircraft track positions
		const carouselScrollable = carousel.value.scrollable
		carouselScrollable.scrollLeft = progress * (carouselScrollable.scrollWidth - carouselScrollable.clientWidth)
		aircraftTrack.value.style.strokeDashoffset = `${-aircraftOffset.value + progress * (steps.length - 1) * aircraftTrackGap}px`

		// Update current step
		stepIndex.value = Math.round(progress * (steps.length - 1) - 0.25)
		contentProgress.value = progress
	})
}

function overflowContentSpacers() {
	if (secretScrollable.value && contentSpacerTop.value && contentSpacerBottom.value) {
		secretScrollable.value.style.marginTop = `${173 - contentSpacerTop.value.clientHeight}px`
		secretScrollable.value.style.marginBottom = `${89 - contentSpacerBottom.value.clientHeight}px`
	}
}

function handleScroll() {
	updatePhone()
	updateContent()
}

function handleResize() {
	updatePhone()
	updateAircraftOffset()
	updateContent()
	overflowContentSpacers()
}

onMounted(() => {
	updatePhone()
	updateAircraftOffset()
	updateContent()
	overflowContentSpacers()
	window.addEventListener('scroll', handleScroll)
	window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
	window.removeEventListener('resize', handleResize)
})

function handleNavigate(stepChange: number) {
	if (!scrollSnapElements.value) {
		return
	}

	// Invoke smooth scroll to the next or previous step.
	const scrollSnapElement = scrollSnapElements.value[stepIndex.value + stepChange] ?? null
	if (scrollSnapElement) {
		window.scroll({
			top: scrollSnapElement.getBoundingClientRect().y + window.scrollY,
			behavior: 'smooth',
		})
	}
}

/**
 * Returns a value for the aircraft-track linear
 * gradient offset stops that is relative to the aircraft.
 */
function getAircraftTrackFadeOffsetStop(additionalOffset = 0) {
	if (aircraftTrack.value && aircraftOffset.value) {
		return (aircraftOffset.value + additionalOffset) / aircraftTrack.value.clientWidth
	}
}
</script>

<template>
	<section ref="container" class="relative hidden bg-blue-600 lg:block">
		<div class="relative flex h-[619px] justify-center overflow-hidden">
			<img
				class="header-img absolute max-w-none object-cover"
				:srcSet="`${londonImg900} 900w, ${londonImg1440} 1440w, ${londonImg1920} 1920w`"
				sizes="100vw"
				alt="Aerial view of London city at dawn"
			/>
			<!-- Phone start anchor -->
			<div ref="phoneAnchor" class="absolute top-[288px]" />
		</div>
		<!-- Secretly scrollable area -->
		<div
			ref="secretScrollable"
			class="relative"
			:style="{
				// If content scroll distance is less than screen
				// height, we need to add that extra space at the end.
				paddingBottom: `calc(100vh - ${contentStepScrollDistance}px)`,
			}"
		>
			<!-- Scroll-snap steps -->
			<div
				v-for="n in steps.length"
				ref="scrollSnapElements"
				:key="n"
				class="snap-start"
				:style="{
					height: `${contentStepScrollDistance}px`,
				}"
			/>
			<!-- Visible layer on top of secret scroll area -->
			<div class="absolute inset-0">
				<!-- Sticky, scrolls with screen while animating -->
				<div ref="content" class="sticky top-0 flex h-screen flex-col items-center font-inter">
					<div ref="contentSpacerTop" class="max-h-[173px] w-1 flex-[173]" />
					<p class="text-center font-nova text-base font-medium uppercase leading-[14px] tracking-[0.155em] text-warning-500">
						Feel the difference
					</p>
					<p class="mt-3.5 text-center text-[32px] font-medium leading-[42px] text-white">
						We make your trip easier
					</p>
					<div class="relative mt-[91px] flex justify-center gap-[88px] self-stretch pl-[7.92%] pr-[14.38%]">
						<!-- Aircraft track -->
						<svg
							ref="aircraftTrack"
							class="absolute left-0 top-[111px]"
							width="100%"
							height="29"
						>
							<defs>
								<linearGradient
									id="aircraftTrackFade"
									x1="0%"
									x2="100%"
									gradientUnits="userSpaceOnUse"
								>
									<!-- Transparent dots directly underneath aircraft -->
									<stop stop-color="#122336" :offset="getAircraftTrackFadeOffsetStop()" stop-opacity="0" />
									<!-- Dots are solid a short range in front of the aircraft -->
									<stop stop-color="#122336" :offset="getAircraftTrackFadeOffsetStop(aircraftTrackFadeRange)" stop-opacity="1" />
									<!-- Hide more dots than there are steps -->
									<stop stop-color="#122336" :offset="getAircraftTrackFadeOffsetStop(((steps.length - 1) * (1 - contentProgress) + 0.5) * aircraftTrackGap)" stop-opacity="1" />
									<stop stop-color="#122336" :offset="getAircraftTrackFadeOffsetStop(((steps.length - 1) * (1 - contentProgress) + 0.5) * aircraftTrackGap)" stop-opacity="0" />
								</linearGradient>
							</defs>
							<!-- Base line -->
							<line
								x1="0"
								y1="50%"
								x2="100%"
								y2="50%"
								stroke="#122336"
								stroke-width="2px"
								stroke-dasharray="8"
							/>
							<!-- 'Step' dots -->
							<line
								x1="0"
								y1="50%"
								x2="100%"
								y2="50%"
								stroke="url(#aircraftTrackFade)"
								stroke-width="29px"
								:stroke-dasharray="`0 ${aircraftTrackGap}`"
								stroke-linecap="round"
							/>
						</svg>
						<MarketingStepsCarousel
							ref="carousel"
							:steps="steps"
							:show-first-image="!showPhone"
						/>
						<MarketingStepsInfo
							ref="info"
							:max-number="steps.length"
							:number="stepIndex + 1"
							:title="steps[stepIndex].title"
							:paragraphs="steps[stepIndex].paragraphs"
							:aircraft-img="steps[stepIndex].aircraftImg"
							:aircraft-img2x="steps[stepIndex].aircraftImg2x"
							:aircraft-icon="steps[stepIndex].aircraftIcon"
							:main-icon="steps[stepIndex].mainIcon"
							:on-click-previous="() => handleNavigate(-1)"
							:on-click-next="() => handleNavigate(1)"
						/>
					</div>
					<div ref="contentSpacerBottom" class="max-h-[89px] w-1 flex-[89]" />
				</div>
			</div>
		</div>
		<div
			ref="phone"
			class="left-0 top-0 h-[335px] w-[166px]"
			:style="{ visibility: showPhone ? 'visible' : 'hidden' }"
		>
			<img
				class="absolute inset-0"
				:srcSet="`${screenshotImg2x} 2x`"
				:src="screenshotImg"
				alt="iPhone showing 'Search' page of CaptainJet app"
			/>
		</div>
	</section>
	<MarketingMobileSteps :steps="steps" />
</template>

<style scoped lang="postcss">
.header-img {
	--blur: 6px;

	filter: blur(var(--blur));

	/* Prevent blur edges from being visible on
	screen, by pushing them slightly offscreen */
	left: calc(-2 * var(--blur));
	height: calc(100% + 2 * var(--blur));
	width: calc(100% + 4 * var(--blur));
}
</style>
