<script setup lang="ts">
import type { CorrespondantData } from '~/ts/chat/types'

import { useProperty } from '@/composables/useProperty'
import { match } from '~/ts/utils/match'
import placeholder from '~/images/chat/profile-placeholder.svg?url'

type MessageData = Domain.Chat.Data.MessageData
type UserData = SupportCaptainJet.Data.UserData

const props = defineProps<{
	correspondant?: CorrespondantData
	position: 'left' | 'right'
	message?: MessageData
	client?: boolean
}>()

defineEmits(['trash'])

const avatarUrl = computed(() => {
	if (!props.message?.author && !props.correspondant) {
		return
	}

	return props.message?.author?.profile_picture_url
		?? props.correspondant?.profile_picture_url
		?? placeholder
})

const displayName = computed(() => {
	const fullName = props.message?.author?.full_name ?? props.correspondant?.name
	return match(props.position, {
		left: match(props.client as any, {
			true: `${fullName} from CaptainJet`,
			false: `${fullName}`,
		}),
		right: match(props.client as any, {
			true: fullName ? `${fullName} (me)` : 'Me',
			false: fullName ?? 'Anonymous user',
		}),
	})
})

const user = useProperty<UserData>('security.user')
const sendReadTitle = computed(() => {
	const toDate = (date: string) => new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(date))
	const dateCreated = props.message?.created_at ? toDate(props.message.created_at) : ''
	const dateRead = props.message?.read_at ? toDate(props.message?.read_at) : ''

	return match(props.position, {
		left: match(props.client as any, {
			true: '',
			false: dateRead ? `Sent on ${dateCreated}\nRead by client on ${dateRead}` : `Sent on ${dateCreated}`,
		}),
		right: match(props.client as any, {
			true: dateCreated ? `Sent on ${dateCreated}` : 'Sending...',
			false: `Sent by client on ${dateCreated}`,
		}),
	})
})

const canSeeWhispers = computed(() => {
	if (!props.correspondant?.content) {
		return false
	}

	return user?.value.is_admin
})

const shouldDisplayUnreadBubble = computed(() => {
	const user = useProperty<UserData>('security.user')

	if (!props.message) {
		return false
	}

	if (props.message?.read_at) {
		return false
	}

	if (props.message.author?.id === user.value?.id) {
		return false
	}

	return true
})
</script>

<template>
	<div
		v-if="message?.body || message?.attachments?.length || correspondant"
		class="flex h-auto w-full max-w-[66%]"
		:class="{
			'self-end': position === 'right',
			'self-start': position === 'left',
		}"
	>
		<div v-if="avatarUrl && position === 'left'" class="relative mr-4 size-10 shrink-0 self-end rounded-full border-2 border-turquoise-500">
			<div class="size-full overflow-hidden rounded-full">
				<img
					:src="avatarUrl"
					class="aspect-square size-full"
					:alt="displayName"
				/>
			</div>
			<div class="absolute -bottom-0.5 -right-0.5 flex size-3.5 items-center justify-center rounded-full bg-turquoise-500 text-white">
				<IconAircraftFlying class="size-2" />
			</div>
		</div>

		<!-- Bubble and author -->
		<div class="flex min-w-0 flex-col" :class="{ 'ml-auto': position === 'right', 'mr-auto': position === 'left' }">
			<!-- Author display name -->
			<span
				class="flex items-center text-xs font-medium text-gray-500"
				:class="{
					'self-end': position === 'right',
					'self-start': position === 'left',
				}"
			>
				{{ displayName }}
				<template v-if="correspondant?.name">
					is typing...
				</template>
			</span>

			<!-- Bubble -->
			<p
				v-if="message?.body || correspondant"
				class="relative mt-2 block whitespace-pre-line break-words rounded-2xl p-4 text-sm font-medium leading-5 text-blue-500"
				style="word-break: break-word"
				:class="{
					'self-end rounded-br-none bg-blue-100': position === 'right',
					'self-start rounded-bl-none bg-turquoise-200': position === 'left',
				}"
			>
				<span v-if="message?.body" v-text="message.body" />

				<!-- Correspondant typing animation -->
				<template v-if="correspondant">
					<span v-if="canSeeWhispers">
						<span class="mr-1 opacity-50">{{ correspondant.content }}</span>
						<span
							v-for="i in 3"
							:key="i"
							:style="{ animationDelay: `${300 * i}ms` }"
							class="mr-0.5 scale-150 animate-pulse font-bold"
						>.</span>
					</span>
					<span v-else class="flex translate-y-0.5 items-center justify-center">
						<IconDot
							v-for="i in 3"
							:key="i"
							:style="{ animationDelay: `${100 * i}ms` }"
							class="size-3 animate-bounce will-change-transform"
						/>
					</span>
				</template>

				<!-- Unread badge -->
				<span
					v-if="shouldDisplayUnreadBubble"
					class="absolute bottom-0 size-2 animate-pulse rounded-full bg-red-600/50"
					style="animation-duration: 3s"
					:class="{
						'right-0': position === 'left',
						'left-0': position === 'right',
					}"
				/>
			</p>

			<!-- Attachments -->
			<template v-if="message">
				<a
					v-for="attachment in message.attachments"
					:key="attachment.path"
					:download="attachment.name"
					:href="attachment.url!"
					target="_blank"
					class="relative mt-2 flex max-w-64 flex-col overflow-hidden rounded-2xl bg-turquoise-100 text-sm text-blue-500 opacity-90 transition hover:opacity-100"
				>
					<img
						v-if="attachment.mime_type?.startsWith('image') && attachment.url"
						:src="attachment.url!"
						class="relative h-64 object-cover object-center"
					/>
					<span
						class="relative z-10 flex items-center space-x-1 px-4 py-2 font-medium"
						:class="{
							'justify-end': position === 'right',
							'justify-start': position === 'left',
						}"
					>
						<span class="truncate" :title="attachment.name" v-text="attachment.name" />
						<IconDocumentDownload class="size-5 shrink-0 transition" />
					</span>
				</a>
			</template>

			<!-- Status indicators -->
			<span
				v-if="(position === 'right' || user?.is_admin) && message"
				class="mt-1 flex items-center gap-1.5 text-xs font-medium text-gray-500"
				:title="sendReadTitle"
				:class="{
					'self-end': position === 'right',
					'self-start': position === 'left',
				}"
			>
				<button
					v-if="user?.is_admin && message.created_at"
					title="Permanently delete message"
					:class="{
						'order-0': position === 'right',
						'order-2': position === 'left',
					}"
					@click="$emit('trash')"
				>
					<IconTrash class="w-4 text-gray-300 transition hover:text-red-400" />
				</button>

				<IconLoading v-if="!message.created_at" class="order-1 w-4 text-gray-300" />
				<IconCheckAll v-if="message.read_at && user?.is_admin" class="order-1 w-4 text-gray-300" />
				<IconCheck v-else-if="message.created_at" class="order-1 w-4 text-gray-300" />
			</span>
		</div>
	</div>
</template>
