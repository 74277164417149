<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 1335 1179"
		fill="#FDF5E9"
		fill-rule="evenodd"
		clip-rule="evenodd"
	>
		<path d="M363.99 124.94c-88.01 50.1-166.64 136.89-210.36 222.24-25.38 49.54-65.03 171.47-35.06 303.37 29.96 131.83 129.48 273.78 382.75 363.39 268.18 94.89 462.03 56.88 576.29-29.06 114.3-85.96 149-219.86 99.06-317.13-43-83.74-121.06-97.19-202.17-107.44l-12.16-1.52c-77.15-9.57-155.6-19.31-206.78-87.3-49.4-65.64-48.02-120.78-46.73-172.05.11-4.58.23-9.12.3-13.64.48-27.58-.43-54.15-10.82-80.92-10.4-26.76-30.3-53.8-67.99-82.2C549.2 61.5 452 74.83 364 124.93Zm-.77-1.36c88.3-50.26 186.23-63.84 268.04-2.15 37.86 28.54 57.98 55.8 68.5 82.88 10.51 27.07 11.4 53.9 10.93 81.52-.07 4.52-.19 9.06-.3 13.63-1.29 51.25-2.66 105.9 46.42 171.1 50.77 67.44 128.57 77.1 205.88 86.7l12 1.5c81.06 10.25 159.96 23.74 203.36 108.28 50.37 98.1 15.26 232.78-99.5 319.09-114.8 86.33-309.23 124.29-577.75 29.28-253.69-89.76-353.66-232.08-383.75-364.51-30.07-132.36 9.7-254.67 35.2-304.44 43.84-85.59 122.67-172.61 210.97-222.88Z" />
		<path d="M369.69 176.61c-81.25 47.23-153.42 127.88-193.24 207.13-23.22 46.6-58.7 158.35-30.81 278.86 28.25 121.2 120.53 251.13 352.93 332.97 248.98 87.44 429.82 53.84 536.55-25.21 106.16-78.8 139.28-202.56 93.11-295.22-16.06-32.22-37.79-53.6-62.89-68.48-23.13-13.7-49.22-21.78-76.67-27.6-18.6-3.96-37.79-6.87-57.08-9.8-9.2-1.39-18.4-2.78-27.59-4.3-56.88-9.38-112.58-23.26-153.57-68.9l.58-.51-.57.51a180.6 180.6 0 0 1-8.84-10.62c-45.6-59.68-46.25-109.86-46.84-156.16-.06-4.48-.11-8.92-.21-13.34-.55-25-2.39-48.96-12.84-72.94-10.44-23.96-29.51-48.01-64.66-73.1-75.97-54.23-166.11-40.52-247.36 6.71Zm-.78-1.35c81.52-47.39 172.38-61.36 249.05-6.63 35.32 25.22 54.6 49.47 65.18 73.75 10.58 24.27 12.42 48.49 12.97 73.53.1 4.42.15 8.86.21 13.34.6 46.27 1.23 95.98 46.52 155.24 2.83 3.69 5.75 7.2 8.75 10.53 40.6 45.2 95.81 59.03 152.66 68.4 9.15 1.5 18.34 2.9 27.51 4.3 19.32 2.92 38.59 5.84 57.24 9.8 27.52 5.85 53.8 13.98 77.14 27.8 25.34 15.02 47.29 36.62 63.48 69.12 46.58 93.48 13.06 218.02-93.57 297.17-107.27 79.45-288.68 112.98-538 25.43-232.82-82-325.54-212.28-353.93-334.08-27.99-120.97 7.6-233.1 30.94-279.92 39.94-79.5 112.32-160.39 193.85-207.78Z" />
		<path d="M375.2 228.2c-74.55 44.31-140.26 118.83-175.92 192.1-21.22 43.6-52.38 145.23-26.58 254.35 26.55 110.58 111.58 228.47 323.11 302.54 229.8 80 397.61 50.81 496.83-21.35 98-71.64 129.54-185.24 87.15-273.32-14.33-29.77-34-49.95-56.73-64.38-35.34-22.43-78.5-30.4-122.45-38.51a2147.8 2147.8 0 0 1-23.6-4.45c-51.8-10.16-102.86-24.14-141.09-66.04-2.83-3.1-5.6-6.34-8.28-9.76-42-53.58-44.63-98.8-47.04-140.17-.25-4.38-.5-8.7-.8-13-1.55-22.44-4.29-43.82-14.75-65.02-10.45-21.2-28.66-42.3-61.27-64.06-70.84-47.3-154.04-33.26-228.58 11.06Zm-.8-1.35c74.81-44.48 158.7-58.78 230.24-11.02 32.78 21.88 51.2 43.17 61.81 64.66 10.6 21.5 13.35 43.13 14.9 65.62.3 4.3.55 8.63.8 13 2.42 41.34 5.03 86.12 46.71 139.31 2.66 3.39 5.4 6.6 8.22 9.67 37.85 41.5 88.46 55.4 140.22 65.56 7.83 1.53 15.69 2.98 23.53 4.43 43.94 8.1 87.45 16.13 123.07 38.75 22.95 14.56 42.83 34.96 57.29 65.01 42.78 88.89 10.86 203.26-87.64 275.26-99.75 72.55-268.13 101.67-498.25 21.57-211.95-74.23-297.43-192.48-324.12-303.65-25.9-109.59 5.37-211.6 26.7-255.4 35.79-73.53 101.7-148.28 176.52-192.77Z" />
		<path d="M380.79 279.83c-67.8 41.44-127.03 109.82-158.69 177.02-19.13 40.64-46.32 132.18-22.33 229.85 24.55 100.02 102.62 205.8 293.29 272.12 210.61 72.55 365.4 47.77 457.09-17.5 89.86-64.48 119.79-167.9 81.2-251.42-12.63-27.32-30.22-46.3-50.57-60.29-31.89-21.91-71.16-30.74-111.33-39.77-6.47-1.46-12.96-2.91-19.45-4.43-46.7-10.94-93.13-25.03-128.59-63.2a160.34 160.34 0 0 1-7.74-8.88c-38.14-47.44-42.79-87.61-47-123.98-.5-4.36-1-8.67-1.55-12.92-2.56-19.87-6.22-38.66-16.71-57.08-10.49-18.42-27.84-36.54-57.9-55-65.74-40.36-141.93-25.96-209.72 15.48Zm-.82-1.33c68.06-41.6 144.92-56.27 211.36-15.47 30.22 18.56 47.78 36.85 58.43 55.55 10.65 18.7 14.34 37.72 16.9 57.65.56 4.27 1.06 8.58 1.56 12.94 4.21 36.34 8.82 76.1 46.67 123.18 2.48 3.08 5.05 6 7.66 8.8 35.12 37.8 81.13 51.8 127.81 62.74 6.46 1.51 12.93 2.97 19.39 4.42 40.15 9.02 79.75 17.92 111.92 40.02 20.56 14.13 38.34 33.32 51.09 60.92 38.96 84.31 8.67 188.5-81.7 253.34-92.24 65.66-247.6 90.36-458.51 17.7-191.09-66.45-269.6-172.6-294.3-273.22-24.1-98.12 3.2-190.04 22.44-230.88l.7.33-.7-.33c31.78-67.47 91.22-136.09 159.28-177.69Z" />
		<path d="M386.36 331.48c-61.03 38.55-113.77 100.79-141.43 161.93-17.05 37.67-40.07 119.07-18.1 205.34 22.77 89.41 93.55 183.5 263.47 241.7 191.3 65.5 333.34 44.95 417.36-13.66 81.87-57.1 110-150.5 75.25-229.5-10.94-24.87-26.44-42.65-44.41-56.2-28.5-21.5-63.98-31.25-100.48-41.28-4.99-1.37-10-2.75-15.02-4.16-41.62-11.73-83.41-25.93-116.1-60.35a154.43 154.43 0 0 1-7.18-8.02c-34.14-41.17-40.78-76.2-46.72-107.5a787.8 787.8 0 0 0-2.56-13.12c-3.59-17.3-8.17-33.49-18.7-49.13-10.5-15.64-27-30.8-54.49-45.94-60.68-33.43-129.85-18.66-190.89 19.9Zm-.83-1.32c61.3-38.7 131.1-53.75 192.47-19.94 27.65 15.24 44.35 30.54 55.04 46.44 10.7 15.9 15.33 32.32 18.92 49.68.9 4.32 1.73 8.7 2.57 13.15 5.94 31.3 12.52 65.94 46.38 106.8 2.3 2.77 4.7 5.4 7.12 7.93 32.37 34.1 73.8 48.2 115.39 59.92 5 1.41 10.01 2.79 15 4.16 36.47 10.02 72.27 19.85 101.02 41.54 18.16 13.7 33.84 31.68 44.9 56.81 35.09 79.77 6.6 173.95-75.79 231.42-84.56 58.99-227.17 79.45-418.75 13.85-170.34-58.33-241.56-152.78-264.48-242.79-22.09-86.71 1.05-168.49 18.19-206.37l.7.33-.7-.32c27.78-61.42 80.73-123.9 142.02-162.6Z" />
		<path d="M391.93 383.15c-54.26 35.67-100.48 91.76-124.18 146.81-14.94 34.73-33.8 105.98-13.86 180.83 21 78.82 84.58 160.91 233.66 211.28 172.09 58.14 301.15 41.96 377.62-9.8 73.74-49.9 100.17-133.1 69.3-207.61-9.28-22.39-22.67-38.98-38.26-52.12-25.18-21.2-57.03-32-90.03-43.17-3.39-1.15-6.8-2.3-10.2-3.47-36.52-12.52-73.67-26.84-103.59-57.52a150.33 150.33 0 0 1-6.63-7.15l.57-.5-.57.5c-29.88-34.7-38.42-64.4-45.95-90.59a614.73 614.73 0 0 0-4.07-13.72c-4.6-14.72-10.12-28.31-20.67-41.18-10.54-12.87-26.17-25.07-51.07-36.92-55.66-26.48-117.81-11.33-172.07 24.33Zm300.46 165.23.56-.54-.56.54ZM391.07 381.85c54.5-35.83 117.25-51.25 173.6-24.43 25.05 11.91 40.88 24.24 51.61 37.33 10.73 13.1 16.32 26.9 20.95 41.7 1.41 4.5 2.73 9.1 4.09 13.8 7.53 26.17 15.98 55.54 45.62 89.97 2.12 2.46 4.33 4.8 6.57 7.07 29.63 30.4 66.46 44.62 102.98 57.13 3.4 1.17 6.82 2.33 10.22 3.48 32.95 11.16 65.08 22.04 90.5 43.45 15.77 13.28 29.31 30.07 38.7 52.71 31.18 75.27 4.43 159.22-69.86 209.5-77.03 52.13-206.65 68.21-379 9.98-149.48-50.5-213.5-132.94-234.67-212.34-20.06-75.31-1.09-146.93 13.94-181.85l.72.3-.72-.3c23.82-55.34 70.24-111.67 124.75-147.5Z" />
		<path d="M397.47 434.87C350 467.64 310.34 517.56 290.58 566.5l-.72-.29.72.3c-12.82 31.78-27.52 92.86-9.62 156.32 19.24 68.2 75.6 138.28 203.83 180.85 152.9 50.75 268.96 38.95 337.9-5.95 65.6-42.72 90.26-115.63 63.33-185.7-7.64-19.9-18.89-35.3-32.1-48.04-22.02-21.22-50.52-33.27-80.32-45.86l-4.66-1.97c-31.42-13.32-63.92-27.77-91.05-54.7l.54-.56-.54.56c-2.05-2.02-4.11-4.1-6.09-6.28l.58-.52-.58.52c-25.16-27.87-35.33-51.96-44.18-72.91a466.82 466.82 0 0 0-6.57-15.07c-5.64-12.13-12.1-23.12-22.67-33.22-10.58-10.1-25.33-19.35-47.62-27.93-50.71-19.5-105.83-3.97-153.3 28.8Zm-.89-1.29c47.7-32.93 103.36-48.74 154.74-28.97 22.43 8.62 37.37 17.97 48.14 28.25 10.77 10.28 17.32 21.47 23 33.7 2.28 4.89 4.41 9.94 6.62 15.18 8.85 20.93 18.9 44.75 43.88 72.4 1.95 2.16 3.98 4.2 6.02 6.22 26.9 26.7 59.14 41.05 90.57 54.37l4.75 2.01c29.71 12.56 58.47 24.71 80.7 46.14 13.36 12.87 24.74 28.46 32.48 48.6 27.2 70.8 2.24 144.47-63.94 187.57-69.5 45.26-186.1 56.95-339.24 6.12-128.62-42.7-185.44-113.11-204.85-181.9-18.02-63.9-3.22-125.36 9.69-157.34 19.87-49.25 59.74-99.4 107.44-132.34Z" />
		<path d="M403.55 484.8c-40.82 30.48-74.28 75.43-90.14 118.26-10.68 28.85-21.21 79.76-5.4 131.82 17.51 57.6 66.66 115.63 174.02 150.43 133.72 43.35 236.76 35.93 298.18-2.1 57.44-35.55 80.24-98.1 57.37-163.8-6.05-17.4-15.14-31.6-25.97-43.97-18.58-21.21-43.38-34.84-69.73-48.98l-2.32-1.25c-25.63-13.73-52.54-28.16-76.18-50.66a159.35 159.35 0 0 1-5.53-5.4l.56-.54-.56.54c-19.46-20.27-30.61-37.58-40.24-52.52-4-6.22-7.75-12.02-11.72-17.47-6.76-9.27-14.2-17.54-24.77-25.09-10.56-7.54-24.28-14.39-43.61-20.78-45.09-14.9-93.13 1.01-133.96 31.5Zm-.93-1.26c41.04-30.65 89.62-46.86 135.38-31.73 19.43 6.43 33.3 13.33 44.03 21 10.73 7.66 18.3 16.06 25.12 25.44a371.76 371.76 0 0 1 11.86 17.66c9.6 14.9 20.61 32 39.97 52.16 1.77 1.85 3.62 3.6 5.47 5.35 23.5 22.35 50.24 36.7 75.9 50.45l2.26 1.22c26.32 14.11 51.38 27.86 70.17 49.32 10.94 12.5 20.14 26.87 26.27 44.48 23.1 66.4.04 129.72-58.02 165.65-61.98 38.37-165.56 45.67-299.48 2.26-107.74-34.93-157.34-93.28-175.03-151.47-15.95-52.5-5.33-103.77 5.43-132.81l.73.27-.73-.27c15.97-43.13 49.62-88.32 90.67-118.98Z" />
		<path d="M591.71 528.02c-15.44-13.83-33.45-23.14-67.35-27.48-41.09-5.27-82.08 11.1-115.86 38.03-33.77 26.92-60.23 64.35-72.26 101.03-8.5 25.94-14.86 66.63-1.16 107.3 15.81 47 57.71 92.96 144.2 120.04 114.55 35.87 204.54 32.87 258.46 1.75 24.65-14.23 42.14-34.37 51.15-58.6 9.01-24.22 9.57-52.6.25-83.32-4.52-14.88-11.42-27.88-19.84-39.9-14.05-20.08-33.33-35.16-54.54-50.1-4.36-3.08-8.8-6.14-13.29-9.24-17.35-11.97-35.46-24.47-52.59-39.89l.52-.58-.52.58-.68-.6c-1.43-1.29-2.88-2.58-4.3-3.93-11.85-11.35-21.03-21.66-29.18-30.87l-.65-.74c-7.89-8.9-14.82-16.74-22.36-23.48ZM645 581.98l-.54.57.54-.57c1.38 1.33 2.81 2.6 4.24 3.88l.7.62c17.04 15.35 35.05 27.78 52.39 39.74 4.5 3.1 8.95 6.18 13.34 9.27 21.23 14.95 40.7 30.17 54.92 50.48 8.5 12.13 15.48 25.28 20.05 40.35 9.4 31 8.87 59.72-.28 84.32-9.15 24.6-26.9 45.01-51.83 59.4-54.49 31.44-145 34.31-259.71-1.62-86.85-27.2-129.2-73.45-145.21-121.01-13.84-41.1-7.4-82.16 1.16-108.3l.74.24-.74-.24c12.13-37 38.78-74.66 72.77-101.76 33.99-27.1 75.4-43.7 117.03-38.36 34.16 4.37 52.48 13.8 68.2 27.86 7.6 6.81 14.59 14.7 22.45 23.58l.68.78c8.15 9.2 17.3 19.47 29.1 30.77Z" />
		<path d="M577.17 558.57c-17.58-8.57-37.4-12.57-65.95-10.82-36.35 2.24-70.23 19.04-97.12 42.96-26.9 23.92-46.74 54.91-55.03 85.42-6.25 23.03-8.42 53.47 3.06 82.8 14.22 36.35 48.8 70.18 114.38 89.63 95.45 28.3 172.3 29.76 218.76 5.6 20.55-10.7 35.4-26.42 43.38-46.58 7.97-20.17 9.09-44.84 2.04-73.46-3.04-12.35-7.75-24.15-13.7-35.86-18.05-35.5-50.72-62.68-85.73-91.8l-6.87-5.72-1.58-1.3c-.95-.78-1.9-1.56-2.83-2.36l-1.02-.86c-19.28-16.34-34.47-29.22-51.79-37.65Zm53.81 37.32-.5.6.5-.6c.93.79 1.86 1.56 2.8 2.32l1.6 1.33 6.96 5.78c34.92 29.05 67.84 56.43 86.04 92.23 6 11.8 10.75 23.7 13.83 36.2 7.1 28.85 6 53.87-2.11 74.4-8.13 20.55-23.26 36.55-44.1 47.39-47.03 24.46-124.4 22.85-219.94-5.49-65.92-19.55-100.94-53.65-115.38-90.55-11.63-29.73-9.43-60.53-3.11-83.78 8.37-30.83 28.4-62.08 55.49-86.17 27.1-24.1 61.3-41.1 98.06-43.35 28.77-1.77 48.87 2.26 66.74 10.96 17.5 8.53 32.82 21.52 52 37.79l1.12.94Z" />
		<path d="M619.9 613.84c-31.81-23.72-73.13-34.65-121.78-18.9-62.78 20.3-106.83 69.05-116.21 117.7-4.69 24.3-.72 48.57 13.87 69.3 14.6 20.75 39.87 38.03 77.96 48.24 38.18 10.23 73.2 16.88 103.56 18.81 30.38 1.94 56.03-.83 75.53-9.38 19.46-8.52 32.8-22.81 38.62-44.01 5.82-21.25 4.1-49.51-6.77-85.98-10.59-35.49-32.93-72.05-64.78-95.78Zm-122.26-20.4c49.17-15.9 91-4.85 123.2 19.15 32.16 23.97 54.67 60.83 65.33 96.58 10.92 36.6 12.72 65.2 6.78 86.84-5.95 21.69-19.64 36.33-39.5 45.03-19.82 8.7-45.75 11.46-76.25 9.51-30.5-1.95-65.63-8.62-103.87-18.86-38.33-10.28-63.97-27.73-78.83-48.85s-18.88-45.83-14.12-70.5c9.5-49.3 54.04-98.44 117.26-118.9Z" />
	</svg>
</template>
