<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M24.917 8.618a1.59 1.59 0 0 0-.489-.79 1.618 1.618 0 0 0-.85-.387l-1.655-.238-.728-1.543a1.708 1.708 0 0 0-.627-.673 1.733 1.733 0 0 0-.889-.254c-.334.01-.659.113-.935.299a1.74 1.74 0 0 0-.627.75L17.39 7.32l-1.636.236c-.31.035-.602.16-.84.358a1.56 1.56 0 0 0-.498.76 1.528 1.528 0 0 0 .467 1.7l1.18 1.214-.324 1.793a1.773 1.773 0 0 0 .674 1.616c.28.224.628.348.988.354.28.008.553-.076.78-.238l1.47-.791 1.512.794a1.67 1.67 0 0 0 1.75-.147 1.685 1.685 0 0 0 .67-1.653l-.285-1.753 1.204-1.242a1.656 1.656 0 0 0 .415-1.702Zm-1.542.635-1.35 1.39a1.133 1.133 0 0 0-.334 1.026l.39 1.92a.139.139 0 0 1-.05.108.095.095 0 0 1-.042 0 .08.08 0 0 1-.043 0l-1.72-.899a1.007 1.007 0 0 0-1.045 0l-1.72.902a.137.137 0 0 1-.057.02h-.017c-.04-.043-.063-.068-.063-.11l.306-1.94a1.164 1.164 0 0 0-.372-1.013l-1.344-1.375a.282.282 0 0 1-.037-.114l.048-.04c.02-.036.026-.045.084-.048l1.882-.283a1.152 1.152 0 0 0 .86-.635l.834-1.801c.017-.034.045-.04.097-.04.052 0 .069 0 .097.085l.831 1.753a1.06 1.06 0 0 0 .86.635l1.854.264a.238.238 0 0 1 .086.06.256.256 0 0 0 0 .05v.004c.006.037.007.046-.035.081Z"
			fill="currentColor"
		/>
		<path d="m20.69 15.966-.808-.423v6.42c0 .05-.02.096-.055.13a.19.19 0 0 1-.13.055H6.65a.733.733 0 0 0 0-.136v-.567a.79.79 0 0 0-.235-.562.807.807 0 0 0-1.134 0 .79.79 0 0 0-.235.562v.567c0 .045.005.091.014.136H1.8a.186.186 0 0 1-.183-.184V11.232a.18.18 0 0 1 .053-.129.185.185 0 0 1 .13-.053h3.283a.773.773 0 0 0-.023.185v.567c0 .21.085.412.235.561a.807.807 0 0 0 1.135 0 .79.79 0 0 0 .235-.561v-.567a.773.773 0 0 0-.023-.185h7.564l-.143-.144a2.494 2.494 0 0 1-.682-1.384v-.071H1.8c-.477 0-.935.187-1.272.521A1.772 1.772 0 0 0 0 11.233v10.73c0 .473.19.925.528 1.259.337.334.794.522 1.271.522h17.897c.477 0 .934-.188 1.272-.522.337-.334.527-.786.528-1.259v-5.73l-.072-.015a2.442 2.442 0 0 1-.733-.252Z" fill="currentColor" />
		<path d="M5.862 17.248a.81.81 0 0 0-.74.49.787.787 0 0 0-.062.304v.567c0 .21.085.412.235.561a.807.807 0 0 0 1.135 0 .79.79 0 0 0 .235-.561v-.567a.787.787 0 0 0-.235-.562.803.803 0 0 0-.568-.232ZM6.43 14.077a.79.79 0 0 1 .235.562v.567a.79.79 0 0 1-.235.561.807.807 0 0 1-1.135 0 .79.79 0 0 1-.235-.561v-.567a.79.79 0 0 1 .235-.562.807.807 0 0 1 1.135 0ZM15.003 14.315l.063.199c.037.159 0 .397-.573 1.04l-.376.406.035.031a.417.417 0 0 1 .12.526.418.418 0 0 1-.129.146.484.484 0 0 1-.086.085l.092.284.07.22c.124.383.245.76.274.84v.168l-.075.425-.068.088-.084.088-.146.037h-.298l-.02-.284a.457.457 0 0 0 0-.136c-.023-.05-.401-.78-.728-1.4l-.573.58c-.12.118-.25.224-.39.318l.098.769a.376.376 0 0 1-.046.224v.184h-.573v-.221l-.318-.666a.354.354 0 0 1-.1-.105l-.63-.318h-.118l-.083-.176-.075-.176.135-.156.123-.124h.152l.762.102c.092-.142.196-.275.313-.397l.573-.593-.049-.048-.224-.124c-.509-.282-1.017-.564-1.114-.614h-.301l-.092-.178-.077-.176.135-.15.077-.082.158-.043.346-.068h.126l.049.017c.045.018.185.066.366.127.278.095.652.222.923.324a.86.86 0 0 1 .07-.085.573.573 0 0 1 .269-.179h.048c.082 0 .162.02.232.06h.029l.083.079.037.043.336-.375c.023-.02.22-.181.415-.317.162-.137.361-.222.573-.244h.04l.095.025h.129Z" fill="currentColor" />
	</svg>
</template>
