<template>
	<svg
		width="20"
		height="26"
		viewBox="0 0 20 26"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M9.62517 7.80231H4.44282C4.09016 7.80231 3.79771 7.51416 3.79771 7.15721C3.79771 6.80025 4.08586 6.5121 4.44282 6.5121H9.62517C9.98213 6.5121 10.2703 6.80025 10.2703 7.15721C10.2703 7.51416 9.98213 7.80231 9.62517 7.80231Z" />
		<path d="M15.3537 10.7698H4.44282C4.08586 10.7698 3.79771 11.0579 3.79771 11.4149C3.79771 11.7719 4.08586 12.06 4.44282 12.06H15.3537C15.7107 12.06 15.9988 11.7719 15.9988 11.4149C15.9988 11.0579 15.7107 10.7698 15.3537 10.7698Z" />
		<path d="M13.4096 19.8013C13.4311 19.8142 13.4827 19.8443 13.6332 19.754C14.7371 19.0908 15.8055 19.3745 15.9429 19.4109C15.949 19.4126 15.9532 19.4137 15.9556 19.4142L15.5685 20.6442L15.5771 20.6485C15.5513 20.6399 14.9492 20.4765 14.3084 20.8593C14.0289 21.027 13.745 21.1087 13.4698 21.1087C13.2332 21.1087 12.9967 21.0485 12.7817 20.9238C12.3172 20.6614 11.9774 20.1324 11.8398 19.4916C11.4398 19.7755 10.8679 20.2615 10.3604 20.7647C10.1066 21.0184 9.69806 21.0141 9.44862 20.7604C9.19488 20.5066 9.19918 20.098 9.45292 19.8486C9.47012 19.8271 9.9561 19.3497 10.5109 18.8895C11.513 18.0509 12.0677 17.8144 12.5494 18.0165C12.8634 18.1498 13.0526 18.4552 13.0526 18.8379C13.0526 19.3583 13.2633 19.7196 13.4096 19.8013Z" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M1.81059 0.710449H17.9898C18.9876 0.710449 19.7961 1.52328 19.8004 2.52105V23.8999C19.8004 24.8976 18.9876 25.7104 17.9898 25.7104H1.81059C0.812832 25.7104 0 24.8976 0 23.8999V2.52105C0 1.52328 0.812832 0.710449 1.81059 0.710449ZM17.9855 23.9902C18.0371 23.9902 18.0758 23.9472 18.0758 23.8999H18.0801V2.52105C18.0801 2.46944 18.0371 2.43073 17.9898 2.43073H1.81059C1.75899 2.43073 1.72028 2.47374 1.72028 2.52105V23.8999C1.72028 23.9515 1.76329 23.9902 1.81059 23.9902H17.9855Z" />
	</svg>
</template>
