<script lang="ts" setup>
const props = withDefaults(defineProps<{
	type?: string
	fields?: number
	autofocus?: boolean
	disabled?: boolean
	required?: boolean
	modelValue: string
	error?: string
}>(), {
	type: 'number',
	fields: 6,
	autofocus: true,
	disabled: false,
	required: false,
})

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
	(e: 'submit'): void
}>()

const KEY_CODE = {
	backspace: 8,
	delete: 46,
	left: 37,
	up: 38,
	right: 39,
	down: 40,
}

const autofocusIndex = ref(0)
const values = ref<string[]>([])
const iRefs = ref<HTMLInputElement[]>([])

function syncValues() {
	values.value = props.modelValue
		? [...props.modelValue]
		: []
}

onMounted(syncValues)
watch(() => props.modelValue, syncValues)

function onFocus(e: any) {
	e.target.select(e)
}

function setInputRef(el: HTMLInputElement, index: number) {
	if (!iRefs.value[index]) {
		iRefs.value.push(el)
	}
}

function triggerChange(values: string[]) {
	emit('update:modelValue', values.join(''))

	if (values.length === 0) {
		iRefs.value[0]?.focus()
	}

	if (values.length === props.fields) {
		emit('submit')
	}
}

function onValueChange(e: any) {
	const index = Number.parseInt(e.target.dataset.id)
	if (props.type === 'number') {
		e.target.value = e.target.value.replace(/[^\d]/gi, '')
	}
	if (e.target.value === '' || (props.type === 'number' && !e.target.validity.valid)) {
		return
	}
	let next: HTMLInputElement
	const value = e.target.value as string
	const _values = Object.assign([], values.value) as Array<string>
	if (value.length > 1) {
		let nextIndex = value.length + index - 1
		if (nextIndex >= props.fields) {
			nextIndex = props.fields - 1
		}
		next = iRefs.value[nextIndex]
		const split = value.split('')
		split.forEach((item, i) => {
			const cursor = index + i
			if (cursor < props.fields) {
				_values[cursor] = item
			}
		})
		values.value = _values
	} else {
		next = iRefs.value[index + 1]
		_values[index] = value
		values.value = _values
	}
	if (next) {
		next.focus()
		next.select()
	}

	triggerChange(_values)
}

function onKeyDown(e: any) {
	const index = Number.parseInt(e.target.dataset.id)
	const prevIndex = index - 1
	const nextIndex = index + 1
	const prev = iRefs.value[prevIndex]
	const next = iRefs.value[nextIndex]

	switch (e.keyCode) {
		case KEY_CODE.backspace: {
			e.preventDefault()
			const vals = [...values.value]
			if (values.value[index]) {
				vals[index] = ''
				values.value = vals
				triggerChange(vals)
			} else if (prev) {
				vals[prevIndex] = ''
				prev.focus()
				values.value = vals
				triggerChange(vals)
			}
			break
		}
		case KEY_CODE.delete: {
			e.preventDefault()
			const vals = [...values.value]
			if (values.value[index]) {
				vals[index] = ''
				values.value = vals
				triggerChange(vals)
			} else if (prev) {
				vals[prevIndex] = ''
				prev.focus()
				values.value = vals
				triggerChange(vals)
			}
			break
		}
		case KEY_CODE.left: {
			e.preventDefault()
			if (prev) {
				prev.focus()
			}
			break
		}
		case KEY_CODE.right: {
			e.preventDefault()
			if (next) {
				next.focus()
			}
			break
		}
		default: {
			break
		}
	}
}
</script>

<template>
	<div class="flex flex-col">
		<div class="flex items-center justify-center space-x-2">
			<template v-for="(_, index) in Array(fields)" :key="index">
				<input
					:ref="(el: any) => setInputRef(el, index)"
					:type="type === 'number' ? 'tel' : type"
					:autofocus="autofocus && index === autofocusIndex"
					class="h-12 w-10 rounded-md border border-blue-100 bg-turquoise-50 text-center focus:border-transparent focus:ring-2 focus:ring-turquoise-500"
					:data-id="index"
					:value="values[index]"
					:disabled="disabled"
					:required="required"
					@input="onValueChange"
					@focus="onFocus"
					@keydown="onKeyDown"
				/>
				<!-- dash icon ' - ' -->
				<svg
					v-if="index === 2"
					class="h-0.5 w-2.5 text-blue-500"
					viewBox="0 0 10 2"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<line
						x1="0.542969"
						y1="1"
						x2="9.44722"
						y2="1"
						stroke="currentColor"
						stroke-width="2"
					/>
					<!-- dash icon ' - ' -->
					<svg
						v-if="index === 2"
						class="h-0.5 w-2.5 text-blue-500"
						viewBox="0 0 10 2"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<line
							x1="0.542969"
							y1="1"
							x2="9.44722"
							y2="1"
							stroke="currentColor"
							stroke-width="2"
						/>
					</svg>
				</svg>
			</template>
		</div>

		<div v-if="error" class="mt-4 text-center text-sm font-medium text-danger-500">
			<span v-text="error" />
		</div>
	</div>
</template>
