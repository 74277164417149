<template>
	<svg viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.4188 8.49209C17.4188 8.49209 10.2568 1.31319 10.1969 1.24411C9.90553 0.906773 9.48001 0.692322 9.0018 0.692322C8.50363 0.692322 8.06294 0.92605 7.77155 1.28829C7.70928 1.3662 0.58482 8.49209 0.58482 8.49209C-0.03869 9.11938 -0.03869 10.137 0.58482 10.7635C1.20833 11.3908 2.21984 11.3908 2.84255 10.7635L7.40511 6.17409V21.6933C7.40511 22.5848 8.12043 23.3077 9.0018 23.3077C9.88398 23.3077 10.5985 22.5848 10.5985 21.6933V6.17409L15.1611 10.7635C15.7838 11.3908 16.7953 11.3908 17.4188 10.7635C18.0423 10.137 18.0423 9.11938 17.4188 8.49209Z"
			fill="currentColor"
		/>
	</svg>
</template>
