<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
		<path d="M13.45.96c0-.52.43-.94.95-.94 6.2 0 11.21 5.02 11.21 11.21a.95.95 0 1 1-1.9 0 9.32 9.32 0 0 0-9.31-9.32.95.95 0 0 1-.95-.95Z" fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.7 8.12a3.1 3.1 0 0 0 .98-3.28 6.32 6.32 0 0 0-1.86-2.86A7.19 7.19 0 0 0 5.9.28C4.88 0 3.64.05 2.82 1.06l-.16.2c-.52.6-.96 1.14-1.3 1.6-.4.53-.7 1.04-.9 1.6C.06 5.63.25 6.8.67 8.4c.3 1.41 1.33 3.38 2.7 5.4 1.39 2.07 3.22 4.3 5.23 6.22 2 1.9 4.22 3.56 6.43 4.4 2.2.84 4.52.91 6.47-.58 1.38-.87 2.09-1.8 2.3-2.77a3.67 3.67 0 0 0-.19-2.07 7 7 0 0 0-2.56-3.49 4.05 4.05 0 0 0-2.2-.76 3.4 3.4 0 0 0-2.8 1.45l-.01.03c-.1.14-.17.23-.24.3l-.08.06h-.14a.94.94 0 0 0-.2-.02l-.07-.04a5.17 5.17 0 0 1-.68-.45 20.9 20.9 0 0 1-2.05-1.8c-1.53-1.52-3-3.31-3.56-4.52a2 2 0 0 1-.24-.8c0-.1.03-.15.06-.18.04-.06.11-.13.27-.24l.08-.05c.13-.09.35-.23.5-.37ZM5.41 2.11c-.67-.18-.98-.03-1.13.16h-.01l-.16.2C3.6 3.1 3.2 3.58 2.88 4c-.34.46-.53.79-.63 1.09-.2.57-.17 1.23.26 2.85l.01.05c.22 1.03 1.07 2.76 2.41 4.76a37.15 37.15 0 0 0 4.98 5.9c1.9 1.82 3.92 3.29 5.8 4 1.84.7 3.41.64 4.67-.34a1 1 0 0 1 .08-.06c1.15-.7 1.42-1.28 1.5-1.6.07-.34-.02-.63-.14-1.03l-.02-.06a5.11 5.11 0 0 0-1.83-2.48 2.17 2.17 0 0 0-1.15-.44c-.32 0-.67.1-1.06.44l-.18.24c-.1.14-.24.34-.42.53-.2.2-.45.4-.8.53-.32.11-.65.14-1 .1a1.5 1.5 0 0 1-.44-.07 2.58 2.58 0 0 1-.48-.2c-.3-.14-.62-.36-.95-.6-.67-.5-1.46-1.21-2.25-2-1.55-1.53-3.23-3.52-3.94-5.05a3.82 3.82 0 0 1-.41-1.6c0-.52.16-.96.41-1.3.24-.33.53-.54.74-.68l.23-.16c.09-.05.12-.08.17-.12a1 1 0 0 1 .02-.01c.43-.37.53-.8.39-1.36a4.46 4.46 0 0 0-1.32-1.97 5.32 5.32 0 0 0-2.11-1.25Z"
			fill="currentColor"
		/>
		<path d="M14.4 4.23a.95.95 0 0 0 0 1.9 5.1 5.1 0 0 1 5.1 5.1.95.95 0 1 0 1.9 0 7 7 0 0 0-7-7Z" fill="currentColor" />
	</svg>
</template>
