<script setup lang="ts">
import { route } from '@/composables/route'
import backgroundImage from '~/images/captainjet/company-links/bg-desktop.webp'

const mouse = useMouse()
const windowSize = useWindowSize()
const parallaxAmount = 0.01
</script>

<template>
	<div
		class="flex flex-1 flex-col gap-14 overflow-hidden py-14 pl-16 pr-12 xl:flex-row xl:justify-evenly"
		:style="{
			perspective: '50cm',
		}"
	>
		<!-- Background -->
		<CompanyLinksFadeIn>
			<div class="absolute inset-0 overflow-hidden">
				<img
					:src="backgroundImage"
					alt=""
					class="absolute inset-0 size-full object-cover transition-transform duration-1000 ease-out"
					:style="{
						// Make a parallax effect.
						transform: `
							scale(${1 + parallaxAmount})
							translateX(${-parallaxAmount * (mouse.x.value - 0.5 * windowSize.width.value)}px)
							translateY(${-parallaxAmount * (mouse.y.value - 0.5 * windowSize.height.value)}px)
						`,
					}"
				/>
			</div>
		</CompanyLinksFadeIn>

		<!-- Text -->
		<CompanyLinksFadeUp class="flex max-w-3xl flex-[0.3] flex-col text-white">
			<InertiaLink
				title="CaptainJet"
				:href="route('captainjet.web.index')"
				class="self-start"
			>
				<CompanyLinksIconLogo class="w-56" />
			</InertiaLink>
			<div class="mt-24 flex flex-col">
				<h2 class="font-inter text-6xl leading-tight">
					When experience meets <b>digital innovation</b>
				</h2>
				<span class="mt-7 font-medium uppercase leading-4 tracking-[2.5px]">
					Friendly • Reliable • Digital
				</span>
			</div>
		</CompanyLinksFadeUp>

		<!-- Frosted panel -->
		<CompanyLinksFrostedPanel class="max-w-6xl flex-[0.7]" />
	</div>
</template>
