type LegData = Domain.BookingCaptainJet.Data.LegData
type LegSpecialRequestsData = Domain.BookingCaptainJet.Data.LegSpecialRequestsData

export interface SpecialRequests {
	flightAttendant: boolean
	additionalDetails: string
	transfers: {
		departure: boolean
		arrival: boolean
	}
	luggage: {
		large_items: boolean
		golf_bag: boolean
		skis: boolean
		bike: boolean
		weapon: boolean
		wheelchair: boolean
	}
	caterings: {
		snacks_fruits: boolean
		breakfast: boolean
		lunch: boolean
		dinner: boolean
	}
}

export interface LegWithRequests {
	leg: LegData
	requests: SpecialRequests
}

export function requestsFromLegData(leg: LegData): SpecialRequests {
	return {
		flightAttendant: !!leg.special_requests?.flight_attendant,
		additionalDetails: leg.special_requests?.additional_details ?? '',
		transfers: {
			departure: !!leg.special_requests?.transfers?.includes('departure'),
			arrival: !!leg.special_requests?.transfers?.includes('arrival'),
		},
		luggage: {
			large_items: !!leg.special_requests?.luggage?.includes('large_items'),
			golf_bag: !!leg.special_requests?.luggage?.includes('golf_bag'),
			skis: !!leg.special_requests?.luggage?.includes('skis'),
			bike: !!leg.special_requests?.luggage?.includes('bike'),
			weapon: !!leg.special_requests?.luggage?.includes('weapon'),
			wheelchair: !!leg.special_requests?.luggage?.includes('wheelchair'),
		},
		caterings: {
			snacks_fruits: !!leg.special_requests?.caterings?.includes('snacks_fruits'),
			breakfast: !!leg.special_requests?.caterings?.includes('breakfast'),
			lunch: !!leg.special_requests?.caterings?.includes('lunch'),
			dinner: !!leg.special_requests?.caterings?.includes('dinner'),
		},
	}
}

/**
 * Contains labels and icons for special requests.
 */
export const metadata = {
	transfers: {
		departure: { label: 'Departure transfer', icon: 'CarArrowRight' },
		arrival: { label: 'Arrival transfer', icon: 'CarArrowLeft' },
	},
	luggage: {
		large_items: { label: 'Large item', icon: 'BagExpand' },
		golf_bag: { label: 'Golf bag', icon: 'BagGlof' },
		skis: { label: 'Skis', icon: 'Skis' },
		bike: { label: 'Bike', icon: 'Bike' },
		weapon: { label: 'Weapon', icon: 'Gun' },
		wheelchair: { label: 'Wheelchair', icon: 'Wheelchair' },
	},
	caterings: {
		snacks_fruits: { label: 'Snack/Fruits', icon: 'Cake' },
		breakfast: { label: 'Breakfast', icon: 'Coffee' },
		lunch: { label: 'Lunch', icon: 'Lunch' },
		dinner: { label: 'Dinner', icon: 'Dinner' },
	},
}

export function formatToList(...requests: string[]): string[] {
	return requests.map((request) => {
		return metadata.luggage[request as keyof (typeof metadata)['luggage']]?.label
			?? metadata.caterings[request as keyof (typeof metadata)['caterings']]?.label
			?? metadata.transfers[request as keyof (typeof metadata)['transfers']]?.label
	})
}

/**
 * Transforms a request object into a valid back-end request.
 */
export function requestsToData(requests: SpecialRequests): LegSpecialRequestsData {
	return {
		transfers: Object.entries(requests.transfers).filter(([, value]) => value).map(([key]) => key) as LegSpecialRequestsData['transfers'],
		caterings: Object.entries(requests.caterings).filter(([, value]) => value).map(([key]) => key) as LegSpecialRequestsData['caterings'],
		luggage: Object.entries(requests.luggage).filter(([, value]) => value).map(([key]) => key) as LegSpecialRequestsData['luggage'],
		flight_attendant: requests.flightAttendant ?? false,
		additional_details: requests.additionalDetails || '',
	}
}

/**
 * Transforms a request object into an array of label and icons.
 */
export function toIcons(requests: SpecialRequests, limit: number = 6): Array<{ label: string, icon: string }> {
	return Object.entries(requests)
		.flatMap(([category, items]) => Object.entries(items).flatMap(([key, value]) => {
			if (!value) {
				return []
			}

			const data = metadata as any

			if (!data[category]?.[key]) {
				return []
			}

			return [data[category][key]]
		}))
		.slice(0, limit)
}
