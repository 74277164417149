<script setup lang="ts">
import type { VNode } from 'vue'

const props = defineProps<{
	icon: VNode
	title: string
	paragraphs: VNode[]
}>()

const container = ref<HTMLElement>()

defineExpose({
	container,
})
</script>

<template>
	<div ref="container" class="container flex shrink-0 snap-center flex-col rounded-xl bg-blue-700 p-6 text-white">
		<!-- Icon -->
		<component :is="props.icon" class="size-[25px]" />
		<!-- Title -->
		<p class="mt-3.5 font-inter text-lg font-bold leading-[22px]">
			{{ props.title }}
		</p>
		<!-- Paragraphs -->
		<div class="mt-[21px] font-nova text-base font-medium leading-[23px] text-blue-100">
			<component :is="() => props.paragraphs" />
		</div>
	</div>
</template>

<style scoped lang="postcss">
.container {
	/* Fill screen width while matching container x-padding (24px) */
	width: calc(100vw - 48px);
}
</style>
