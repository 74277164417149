<script setup lang="ts">
import { useProperty } from '@/composables/useProperty'

const displayablePhoneNumber = useProperty('security.features.phone_number')
const phoneNumber = computed(() => displayablePhoneNumber.value?.replaceAll(' ', ''))

function scrollToTop() {
	scrollTo({
		top: 0,
		behavior: 'smooth',
	})
}
</script>

<template>
	<div class="px-4.5 pb-10 pt-12 lg:p-10">
		<div class="lg:flex lg:items-center lg:justify-center lg:space-x-3.5">
			<div class="flex flex-col gap-y-2">
				<MarketingAppQrCode />
			</div>

			<div class="mt-2 flex space-x-2.5 lg:hidden">
				<BaseButton
					as="a"
					href="/#booking"
					variant="secondary"
					size="lg"
					class="flex-1 space-x-2 whitespace-nowrap !p-3 !font-semibold"
					icon="SearchAircraft"
					icon-class="h-5 w-5"
				>
					Book your flight
				</BaseButton>
				<BaseButton
					v-if="phoneNumber"
					as="a"
					:href="`tel:${phoneNumber}`"
					variant="secondary"
					size="lg"
					class="flex-1 space-x-2 whitespace-nowrap !p-3 !font-semibold"
					icon="Telephone"
					icon-class="h-5 w-5"
				>
					Call us
				</BaseButton>
			</div>
		</div>
		<div class="mt-8 hidden flex-col items-center space-y-1 text-blue-500 underline decoration-blue-100 decoration-dotted lg:flex">
			<a
				class="font-semibold transition duration-75 hover:text-blue-300 focus-visible:ring-2 focus-visible:ring-blue-500"
				href="/#booking"
				@click.prevent="scrollToTop"
			>
				Book your flight now
			</a>
			<a
				v-if="phoneNumber"
				class="font-semibold transition duration-75 hover:text-blue-300 focus-visible:ring-2 focus-visible:ring-blue-500"
				:href="`tel:${phoneNumber}`"
				v-text="displayablePhoneNumber"
			/>
		</div>
	</div>
</template>

<style lang="postcss">
.panel,
.panel::before {
	box-shadow: 0px 5px 10px 0px #dfe8ef;
}
</style>
