<script setup lang="ts">
import hero from '~/images/captainjet/about/hero.jpg'

const $props = defineProps<{
	title: string
}>()
</script>

<template>
	<div class="relative bg-blue-500 pt-14">
		<div class="relative z-20 mt-24 lg:mt-0">
			<PageContainer max-width-class="max-w-7xl" class="px-6 pb-12 lg:py-20">
				<div class="flex items-center">
					<div class="lg:w-1/2 lg:pr-8">
						<h1 class="mt-5 font-inter text-[40px] font-medium text-white" v-text="$props.title" />
					</div>
				</div>
			</PageContainer>
		</div>

		<div class="hero-linear-lg absolute inset-0 right-auto z-10 hidden w-1/2 lg:block" />
		<div class="hero-linear-sm absolute inset-0 z-10 lg:hidden" />

		<div class="absolute inset-0 -ml-20 flex h-[320px] lg:m-0 lg:h-full lg:justify-end">
			<img class="size-full object-cover mix-blend-luminosity lg:w-2/3" :src="hero" alt="Jet in dark hangar" />
		</div>
	</div>
</template>

<style>
.hero-linear-sm {
	background: linear-gradient(0deg, #000a13 66.29%, rgba(0, 10, 19, 0) 106.57%);
}

.hero-linear-lg {
	background: linear-gradient(
		90deg,
		#000a13 66.29%,
		rgba(0, 10, 19, 0) 106.57%
	);
}
</style>
