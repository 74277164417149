<template>
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 17">
		<circle
			cx="8.5"
			cy="8.5"
			r="8.5"
			fill="#fff"
		/>
		<path fill="#4F6B89" d="m10.273 7.277-.337.732h-4.67l.267-.732h4.74Zm-.635 1.442-.345.738H5.266l.267-.738h4.105Zm1.502-3.37-.42.898a3.04 3.04 0 0 0-.432-.309 2.29 2.29 0 0 0-.505-.22 1.912 1.912 0 0 0-.578-.085c-.38 0-.717.102-1.013.305-.295.204-.529.51-.7.916-.167.405-.251.91-.251 1.513 0 .604.084 1.108.252 1.513.17.405.404.709.7.913.295.2.633.302 1.012.302.208 0 .402-.028.582-.082.182-.055.348-.126.497-.213.152-.09.288-.185.409-.284l.43.895a2.533 2.533 0 0 1-.874.514c-.332.116-.68.174-1.044.174a2.82 2.82 0 0 1-1.58-.45c-.458-.301-.816-.73-1.077-1.286-.26-.559-.39-1.224-.39-1.996 0-.774.13-1.44.39-2 .26-.558.62-.987 1.076-1.288.46-.3.986-.451 1.58-.451.38 0 .732.063 1.059.188.326.123.619.3.877.533Z" />
	</svg>
</template>
