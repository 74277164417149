<script setup lang="ts">
import { router } from '@inertiajs/vue3'

import { route } from '@/composables/route'
import { match } from '~/ts/utils/match'

type LegData = Domain.BookingCaptainJet.Data.LegData

const props = defineProps<{
	leg: LegData
	highlighted?: boolean
}>()
const badge = computed(() => match(props.leg.status!, {
	pending: {
		text: 'Pending',
		icon: 'SandClock',
		class: 'lg:text-blue-300 lg:bg-blue-50',
		bgClass: 'bg-blue-200',
		textClass: 'text-blue-400',
	},
	confirmed: {
		text: 'Confirmed',
		icon: 'FlightUp',
		class: 'lg:text-success-500 lg:bg-success-100',
		bgClass: 'bg-success-500',
		textClass: 'text-success-500',
	},
	in_progress: {
		text: 'Waiting for flight briefing',
		icon: 'SandClock',
		class: 'lg:text-turquoise-600 lg:bg-turquoise-100',
		bgClass: 'bg-turquoise-600',
		textClass: 'text-turquoise-600',
	},
	default: undefined,
}))

function attachPassengers() {
	if (!props.leg.can_add_passengers) {
		return
	}

	router.get(route('captainjet.web.leg.passengers.show', { leg: props.leg, back: 'bookings' }))
}

function hasMaxPassengers() {
	return (props.leg.passengers?.length ?? 0) < props.leg.passenger_count!
}

function hasDocuments() {
	return props.leg.passengers?.some((passenger) => !passenger.selected_travel_document)
}
</script>

<template>
	<InertiaLink :href="route('captainjet.web.leg.show', { leg })">
		<booking-leg-input
			:leg="leg"
			type="upcoming"
			:badge-text="badge?.text"
			:badge-class="badge?.class"
			:badge-icon="badge?.icon"
			:class="{ highlight: highlighted }"
		>
			<template #default>
				<div class="flex space-x-4 self-center text-sm md:self-end">
					<div v-if="!hasMaxPassengers()" class="flex shrink-0 items-center justify-center px-4 py-2 lg:flex-none">
						<div class="flex items-center space-x-1.5 font-display font-medium text-success-400">
							<icon-seat class="size-5" />
							<span v-text="leg.passengers?.length" />
							<span>passenger{{ leg.passengers?.length === 1 ? '' : 's' }}</span>
							<icon-true class="h-2.5" />
						</div>
					</div>

					<base-button
						v-else
						variant="secondary"
						size="sm"
						class="max-w-xs shrink-0 space-x-2 px-3 lg:h-[42px] lg:max-w-none lg:flex-none lg:px-4 lg:text-base"
						icon="Plus"
						icon-class="w-3.5 h-3.5"
						:title="!leg.can_add_passengers ? 'You can no longer add passengers to this trip.' : ''"
						:disabled="!leg.can_add_passengers"
						@click.prevent.stop="attachPassengers"
					>
						<span>Add passenger</span>
					</base-button>

					<div v-if="!hasDocuments()" class="flex shrink-0 items-center justify-center px-4 py-2 lg:flex-none">
						<div class="flex items-center space-x-1.5 font-display font-medium text-success-400">
							<icon-document-globe class="size-5" />
							<span>Documents</span>
							<icon-true class="h-2.5" />
						</div>
					</div>

					<div v-else class="flex shrink-0 items-center justify-center px-4 py-2 lg:flex-none">
						<div class="flex items-center space-x-1.5 font-display font-medium text-red-400">
							<icon-document-globe class="size-5" />
							<span>Documents</span>
							<icon-x class="h-2.5" />
						</div>
					</div>

				<!-- <BaseButton
						v-else
						variant="secondary"
						size="sm"
						class="max-w-xs shrink-0 space-x-2 px-3 lg:h-[42px] lg:max-w-none lg:flex-none lg:px-4 lg:text-base"
						icon="Plus"
						icon-class="w-3.5 h-3.5"
						@click="toggleAddPassengerDialog(true)"
					>
						Add document
					</BaseButton> -->
				</div>
			</template>
		</booking-leg-input>
	</InertiaLink>
</template>

<style scoped lang="postcss">
.highlight {
	animation: highlight-animation ease-in-out 1.5s 2;
}

@keyframes highlight-animation {
	0%,
	50%,
	100% {
		@apply bg-white;
	}
	25%,
	75% {
		@apply bg-orange-50;
		@apply ring ring-orange-100;
	}
}
</style>
