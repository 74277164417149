<script setup lang="ts">
import { parseDateTime } from '~/ts/utils/datetime'
import { useProperty } from '@/composables/useProperty'

defineProps<{
	name: string
	departsAt: string
	departureAirport: any // @TODO: Replace by new Data object...
	arrivalAirport: any
	passengers?: number
}>()

const useTwelveHourClock = useProperty('security.user.use_twelve_hour_clock') ?? false
</script>

<template>
	<div class="rounded-2xl border border-blue-50 bg-blue-50">
		<div class="p-5 pb-3">
			<!-- Flight name and date -->
			<div class="flex items-center justify-between text-sm">
				<!-- Flight name -->
				<div class="flex items-center space-x-3.5 font-medium tracking-widest text-warning-600">
					<IconAircraftFlying class="size-6 shrink-0" />
					<span class="uppercase" v-text="name" />
				</div>
				<!-- Departure date -->
				<span
					class="tracking-tight text-blue-400"
					:title="parseDateTime(departsAt, { ignoreTimezone: true, useTwelveHourClock }).datetime"
					v-text="parseDateTime(departsAt, { ignoreTimezone: true, useTwelveHourClock }).date"
				/>
			</div>

			<!-- Trip details -->
			<div class="mt-4 flex">
				<!-- Departure hour -->
				<span
					class="text-xs font-medium leading-5 text-blue-500"
					v-text="parseDateTime(departsAt, { ignoreTimezone: true, useTwelveHourClock }).time"
				/>

				<!-- Dots -->
				<div class="flex">
					<div class="relative mx-5 flex flex-1 flex-col items-center justify-between py-0.5">
						<div class="shrink-0 rounded-full bg-warning-500 p-1">
							<div class="rounded-full bg-white p-1" />
						</div>

						<div class="flex-1 border-l-2 border-dashed border-turquoise-300" />

						<div class="shrink-0 rounded-full bg-warning-500 p-1">
							<div class="rounded-full bg-white p-1" />
						</div>
					</div>
				</div>

				<!-- Airports name -->
				<div class="flex flex-col space-y-5">
					<div class="space-y-1">
						<p class="text-lg font-medium leading-5 tracking-tight text-blue-500" v-text="departureAirport.servedCity" />
						<span class="text-sm tracking-tight text-blue-300" v-text="departureAirport.name" />
					</div>

					<div class="space-y-1">
						<p class="text-lg font-medium leading-5 tracking-tight text-blue-500" v-text="arrivalAirport.servedCity" />
						<span class="text-sm tracking-tight text-blue-300" v-text="arrivalAirport.name" />
					</div>
				</div>
			</div>
		</div>

		<!-- Separator -->
		<div class="relative">
			<div class="border-t border-dashed border-turquoise-300" />
			<div
				class="absolute -left-0.5 -top-2 mt-px h-4 w-2.5 rounded-r-full bg-white"
			/>
			<div
				class="absolute -right-0.5 -top-2 mt-px h-4 w-2.5 rounded-l-full bg-white"
			/>
		</div>

		<!-- Passengers count -->
		<div class="flex items-center space-x-2 px-5 py-3">
			<IconUserCircle class="size-4.5 shrink-0 text-blue-300" />
			<div class="flex items-center font-medium">
				<span class="font-display text-blue-400">Passengers</span>
				<span class="ml-4 text-blue-500" v-text="passengers" />
			</div>
		</div>
	</div>
</template>
