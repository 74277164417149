<template>
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1 5.18182C1 3.97683 1.98497 3 3.2 3H20.8C22.015 3 23 3.97683 23 5.18182V15C23 16.205 22.015 17.1818 20.8 17.1818H8.7L5.4 21V17.1818H3.2C1.98497 17.1818 1 16.205 1 15V5.18182ZM6.77507 11.1816C7.53447 11.1816 8.15007 10.571 8.15007 9.81792C8.15007 9.0648 7.53447 8.45428 6.77507 8.45428C6.01568 8.45428 5.40007 9.0648 5.40007 9.81792C5.40007 10.571 6.01568 11.1816 6.77507 11.1816ZM13.6498 9.81792C13.6498 10.571 13.0342 11.1816 12.2748 11.1816C11.5154 11.1816 10.8998 10.571 10.8998 9.81792C10.8998 9.0648 11.5154 8.45428 12.2748 8.45428C13.0342 8.45428 13.6498 9.0648 13.6498 9.81792ZM17.7751 11.1816C18.5345 11.1816 19.1501 10.571 19.1501 9.81792C19.1501 9.0648 18.5345 8.45428 17.7751 8.45428C17.0157 8.45428 16.4001 9.0648 16.4001 9.81792C16.4001 10.571 17.0157 11.1816 17.7751 11.1816Z"
			fill="currentColor"
		/>
	</svg>
</template>
