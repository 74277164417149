<template>
	<svg
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		class="-translate-y-0.5"
	>
		<path d="M18.29 12.965a5.154 5.154 0 0 1 2.435-4.29 5.299 5.299 0 0 0-4.124-2.23c-1.735-.181-3.417 1.04-4.302 1.04-.9 0-2.262-1.021-3.728-.99a5.537 5.537 0 0 0-4.622 2.818c-1.998 3.46-.507 8.544 1.407 11.34.958 1.37 2.077 2.9 3.54 2.846 1.435-.06 1.97-.914 3.7-.914 1.714 0 2.216.914 3.71.88 1.538-.026 2.507-1.376 3.43-2.759a11.327 11.327 0 0 0 1.57-3.195 4.984 4.984 0 0 1-3.016-4.546Zm-2.823-8.358A5.034 5.034 0 0 0 16.619 1a5.122 5.122 0 0 0-3.314 1.715 4.834 4.834 0 0 0-1.182 3.473 4.285 4.285 0 0 0 3.344-1.581Z" fill="currentColor" />
	</svg>
</template>
