<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
		<path d="M7.08 10.613a1.272 1.272 0 1 1 1.839 1.139v.626a.568.568 0 0 1-.567.567.568.568 0 0 1-.567-.567v-.626a1.276 1.276 0 0 1-.705-1.139Z" fill="currentColor" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.726 5.783h1.09c1.035 0 1.873.842 1.873 1.876v6.968a1.875 1.875 0 0 1-1.872 1.873H2.887a1.875 1.875 0 0 1-1.872-1.873V7.66c0-1.034.841-1.876 1.872-1.876h1.088a21.911 21.911 0 0 1-.017-.89A4.398 4.398 0 0 1 8.352.5a4.398 4.398 0 0 1 4.394 4.394c0 .314-.006.612-.02.89Zm-4.374-4.15a3.263 3.263 0 0 0-3.26 3.26c0 .318.005.615.016.89h6.482c.014-.272.023-.572.023-.89a3.263 3.263 0 0 0-3.261-3.26Zm5.465 13.734a.74.74 0 0 0 .739-.74h-.003V7.66a.74.74 0 0 0-.74-.74H2.888a.74.74 0 0 0-.739.74v6.968c0 .408.331.74.74.74h10.929Z"
			fill="currentColor"
		/>
	</svg>
</template>
