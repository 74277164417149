<template>
	<svg
		width="13"
		height="10"
		viewBox="0 0 13 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M3.24998 0.794128H0.448273C0.192117 0.794128 0 1.00013 0 1.24047V3.14599C0.912556 3.24899 1.61699 4.03867 1.61699 5.00001C1.61699 5.96136 0.912556 6.75103 0 6.85403V8.75955C0 8.99989 0.192117 9.20589 0.448273 9.20589H3.24998V0.794128Z" fill="#497D89" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.87438 0.794128H11.8182C12.4709 0.794128 13 1.36149 13 2.06136V7.93866C13 8.63853 12.4709 9.20589 11.8182 9.20589H3.87438V0.794128ZM7.23726 4.80193V5.00723C7.23726 5.03764 7.23903 5.06805 7.24257 5.09847H8.40726L8.28494 5.45773H7.30107C7.37198 5.75426 7.50139 5.98997 7.6893 6.16484C7.87721 6.33592 8.10057 6.42146 8.35939 6.42146C8.54021 6.42146 8.71039 6.37584 8.86994 6.2846C9.02948 6.18956 9.16244 6.0603 9.2688 5.89682L9.65703 6.23327C9.49748 6.47278 9.3078 6.65526 9.08798 6.78072C8.86817 6.90237 8.6253 6.9632 8.35939 6.9632C7.95166 6.9632 7.60244 6.82634 7.31171 6.55262C7.02098 6.27509 6.8313 5.91013 6.74266 5.45773H6.23744V5.09847H6.6948C6.69126 5.06805 6.68948 5.03764 6.68948 5.00723V4.80193C6.68948 4.76772 6.69126 4.7354 6.6948 4.70499H6.23744V4.34003H6.74266C6.83485 3.89523 7.0263 3.53597 7.31703 3.26224C7.6113 2.98472 7.9623 2.84596 8.37003 2.84596C8.63594 2.84596 8.8788 2.90679 9.09862 3.02844C9.31844 3.15009 9.50812 3.33068 9.66767 3.57018L9.2688 3.91234C9.15889 3.74126 9.02594 3.6101 8.86994 3.51886C8.71394 3.42762 8.54553 3.382 8.36471 3.382C8.10589 3.382 7.88253 3.46944 7.69462 3.64432C7.50671 3.81539 7.37553 4.0473 7.30107 4.34003H8.65189L8.52957 4.70499H7.24257C7.23903 4.7354 7.23726 4.76772 7.23726 4.80193Z"
			fill="#497D89"
		/>
	</svg>
</template>
