<script setup lang="ts">
import { useMagicKeys } from '@vueuse/core'
import { vIntersectionObserver } from '@vueuse/components'

import type { Attachment } from '~/ts/chat/types'

import { useFilepond } from '@/composables/useFilepond'
import { useChat } from '~/ts/chat/chat'

const props = defineProps<{
	token?: string
	client?: boolean
}>()

const emit = defineEmits<{ (e: 'error', error: string): void, (e: 'trash'): void }>()

const logarea = ref<HTMLDivElement>()
const { FilePond, getPreviewStyle, status, files, pond, server } = useFilepond({ onError: (error) => emit('error', error) })
const { enter } = useMagicKeys()

const attachments = computed<Attachment[]>(() => {
	return files.value
		.filter((file) => status(file) === 'idle')
		.map((file) => ({
			path: file.serverId,
			mime_type: file.fileType,
			name: file.filename,
		}))
})

const { onKeyDown, input, submit, onMessageSeen, scrollToBottom, state, canSendMessage, isAtBottom, hasPrevious, messages, trash, correspondantTyping } = useChat({
	token: toRef(props, 'token'),
	client: props.client,
	logarea,
	attachments,
	onSubmit: () => {
		scrollToBottom()
		files.value.forEach((file) => file.remove())
		input.value = ''
	},
	onFail: (message) => {
		input.value = message.body ?? ''
	},
	onTrash: () => emit('trash'),
	onError: (error) => emit('error', error),
})

const { textarea } = useTextareaAutosize({ watch: [input] })
</script>

<template>
	<div class="flex flex-col">
		<!-- Chatlog -->
		<div class="relative flex size-full flex-1 flex-col overflow-hidden border-t border-turquoise-100/80">
			<div ref="logarea" v-auto-animate="{ duration: 75 }" class="flex h-full flex-1 flex-col gap-6 overflow-y-auto overflow-x-hidden p-6">
				<!-- Conversation start indicator -->
				<div v-if="!hasPrevious && messages.length" class="my-2 text-center text-sm text-gray-400">
					This is the start of your conversation.
				</div>

				<!-- Conversation bubbles -->
				<template v-for="(message) in messages" :key="message.context">
					<ChatBubble
						v-intersection-observer="([{ isIntersecting }]) => isIntersecting && onMessageSeen(message.id)"
						:message="message"
						:position="message.is_client ? 'right' : 'left'"
						:client="client"
						@trash="trash(message)"
					/>
				</template>

				<ChatBubble
					v-if="correspondantTyping"
					:correspondant="correspondantTyping"
					:position="!client ? 'right' : 'left'"
				/>
			</div>

			<div v-if="state === 'idle' && messages.length === 0" class="absolute inset-0 flex flex-col items-center justify-center space-y-6 font-medium text-gray-300">
				<IconLetterBox class="size-24" />
				You don't have any message
			</div>

			<div v-if="state !== 'idle' && messages.length === 0" class="absolute inset-0 flex flex-col items-center justify-center space-y-6 font-medium text-gray-300">
				<IconLoading class="size-8" />
				Please wait while the conversation is loading.
			</div>

			<!-- Scroll to bottom -->
			<button
				v-if="!isAtBottom && messages.length > 20"
				class="rounded-t-6 absolute bottom-0 flex w-full items-baseline justify-end space-x-2 bg-turquoise-100 px-4 py-1 text-xs font-medium text-turquoise-600"
				@click="scrollToBottom({ smooth: true, force: true })"
			>
				<span>Jump to present</span>
				<IconChevronDown class="size-2" />
			</button>
		</div>

		<!-- Actions -->
		<form class="flex flex-col border-t border-turquoise-100/80 p-4 pt-3" @submit.prevent="submit">
			<!-- Attachment list -->
			<FilePond
				v-show="false"
				ref="pond"
				:allow-multiple="true"
				:server="server"
			/>

			<!-- Attachments -->
			<div v-if="files.length" class="mb-4 grid grid-cols-4 gap-2">
				<div
					v-for="file in files"
					:key="file.id"
					class="relative flex flex-col overflow-hidden rounded-xl px-3 py-2 text-sm font-medium transition"
					:style="getPreviewStyle(file)"
					:class="[
						{
							'h-26 items-start text-white': file.previewUrl,
							'items-center self-end border': !file.previewUrl,
						},
						{
							'bg-danger-100': status(file) === 'error',
							'text-danger-100': status(file) === 'error' && file.previewUrl,
							'border-danger-200 text-danger-600': status(file) === 'error' && !file.previewUrl,
							'border-turquoise-200 bg-turquoise-100 text-blue-300': status(file) === 'loading',
							'border-turquoise-100 bg-white text-blue-300': status(file) === 'idle',
						},
					]"
				>
					<div class="flex w-full items-center">
						<!-- Error/retry icon -->
						<IconExclamationCircle v-if="status(file) === 'error' && file.previewUrl" class="mr-2 size-5 shrink-0" />
						<button v-if="status(file) === 'error' && !file.previewUrl" class="py-0.5 opacity-70 transition hover:opacity-100" @click.prevent="file.retry()">
							<IconReload class="mr-2 size-4 shrink-0 scale-[1.2]" />
						</button>
						<!-- Status Icons -->
						<IconLoading v-if="status(file) === 'loading'" class="mr-2 size-5 shrink-0" />
						<IconDocumentAircraft v-if="status(file) === 'idle' && !file.previewUrl" class="mr-2 size-5 shrink-0" />
						<!-- Filename -->
						<span class="mr-2 truncate" :title="file.filename" v-text="file.filename" />
						<!-- Remove button -->
						<button class="ml-auto border-l border-gray-200 py-0.5 pl-2.5 opacity-70 transition hover:opacity-100" @click.prevent="file.remove()">
							<IconX class="size-3" />
						</button>
					</div>

					<div v-if="status(file) === 'error' && file.previewUrl" class="pointer-events-none absolute inset-0 flex items-center justify-center">
						<button class="pointer-events-auto mt-1.5 rounded-full bg-red-100 p-1 text-red-500 transition hover:text-red-400" @click.prevent="file.retry()">
							<IconReload class="size-4.5" />
						</button>
					</div>
				</div>
			</div>

			<div class="flex items-end gap-2">
				<!-- Attachment -->
				<button
					class="shrink-0 rounded-full border border-gray-100 bg-white p-3.5 text-turquoise-500 transition hover:text-turquoise-600 focus:outline-none focus:ring-2 focus:ring-turquoise-400 active:scale-90"
					@click.prevent="pond?.browse()"
				>
					<IconPlus class="size-3.5" />
				</button>

				<!-- Text input -->
				<div class="group relative flex w-full items-center overflow-hidden rounded-3xl border border-gray-100 bg-white transition focus-within:ring-2 focus-within:ring-turquoise-400">
					<textarea
						ref="textarea"
						v-model="input"
						:row="1"
						:max="2000"
						placeholder="Type something..."
						class="h-6 max-h-24 w-full resize-none overflow-hidden border-none py-3 pl-4 pr-2 font-medium text-blue-500 placeholder:font-normal placeholder:italic placeholder:text-gray-400 focus:outline-none focus:ring-0"
						@keydown="onKeyDown"
					/>

					<!-- Send button -->
					<button
						class="m-2 flex size-8 items-center justify-center self-end rounded-full p-2 transition focus:outline-none"
						:disabled="!canSendMessage"
						:class="[
							canSendMessage
								? 'bg-turquoise-500 hover:bg-turquoise-600 focus:ring focus:ring-turquoise-600 focus:ring-offset-2 active:scale-90'
								: 'bg-gray-200',
							{ 'scale-90': canSendMessage && enter },
						]"
						type="submit"
						@click.prevent="submit"
					>
						<IconSendChatArrow class="size-4 text-white" />
					</button>
				</div>
			</div>
		</form>
	</div>
</template>
