<script lang="ts" setup>
import SignaturePad from 'signature_pad'

const $props = defineProps<{
	modelValue: string
	currentSignatureUrl?: string
}>()

const $emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
}>()

onMounted(async () => {
	const wrapper = document.getElementById('signature-pad') as HTMLDivElement
	const clearButton = document.getElementById('clear-signature') as HTMLButtonElement
	const canvas = wrapper.querySelector('canvas') as HTMLCanvasElement
	const signaturePad = new SignaturePad(canvas)

	function resizeCanvas() {
		const ratio = Math.max(window.devicePixelRatio || 1, 1)

		if (canvas) {
			canvas.width = canvas.offsetWidth * ratio
			canvas.height = canvas.offsetHeight * ratio
			canvas.getContext('2d')?.scale(ratio, ratio)
		}
		signaturePad.clear()
	}

	useEventListener('resize', resizeCanvas)
	resizeCanvas()

	// If we already have a signature URL, we fetch it and convert it
	// to a data URL, so we can write it inside the signature pad.
	if ($props.currentSignatureUrl) {
		const blob = await fetch($props.currentSignatureUrl).then((r) => r.blob())
		const dataUrl = await new Promise<string>((resolve) => {
			const reader = new FileReader()
			reader.addEventListener('load', () => resolve(reader.result!.toString()))
			reader.readAsDataURL(blob)
		})
		signaturePad.fromDataURL(dataUrl)
	}

	if ($props.modelValue !== '') {
		signaturePad.fromDataURL($props.modelValue)
	}

	async function updateValue() {
		const data = signaturePad.toDataURL('image/svg+xml')

		if (!signaturePad.isEmpty() && $props.modelValue !== data && data.includes('base64,')) {
			$emit('update:modelValue', window.atob(data.split('base64,').at(1)!))
		}
	}

	useEventListener(canvas, 'mouseout', updateValue)
	useEventListener(canvas, 'mousemove', updateValue)
	useEventListener(canvas, 'touchend', updateValue)
	useEventListener(clearButton, 'click', () => {
		signaturePad.clear()
		$emit('update:modelValue', '')
	})
})
</script>

<template>
	<div>
		<div class="flex items-center justify-between">
			<p class="font-medium uppercase tracking-widest text-blue-300">
				Sign here
			</p>
			<BaseButton
				id="clear-signature"
				variant="secondary"
				type="button"
				size="xs"
				class="px-4"
			>
				Redo
			</BaseButton>
		</div>
		<div class="mt-3.5 w-full">
			<div id="signature-pad" class="relative h-36">
				<canvas class="absolute inset-0 size-full rounded-lg border border-dashed border-blue-200" />
			</div>
		</div>
	</div>
</template>
