<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26">
		<path fill="currentColor" d="M12.625 7.803H7.443a.644.644 0 1 1 0-1.29h5.182a.644.644 0 1 1 0 1.29ZM18.354 10.77H7.443a.644.644 0 1 0 0 1.29h10.91a.644.644 0 1 0 0-1.29ZM16.41 19.802c.021.013.073.043.223-.047 1.104-.664 2.172-.38 2.31-.344l.013.004-.387 1.23.008.004a1.709 1.709 0 0 0-1.269.21c-.28.168-.563.25-.838.25-.237 0-.473-.06-.688-.185-.465-.262-.805-.791-.942-1.432-.4.284-.972.77-1.48 1.273a.641.641 0 0 1-.911-.004.641.641 0 0 1 .004-.912c.017-.021.503-.499 1.058-.959 1.002-.839 1.557-1.075 2.038-.873.314.133.504.439.504.821 0 .52.21.882.357.964Z" />
		<path
			fill="currentColor"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.81.71h16.18c.998 0 1.806.814 1.81 1.812V23.9c0 .998-.812 1.81-1.81 1.81H4.81c-.997 0-1.81-.812-1.81-1.81V2.522C3 1.524 3.813.71 4.81.71Zm16.175 23.28a.09.09 0 0 0 .09-.09h.005V2.522a.09.09 0 0 0-.09-.09H4.81a.09.09 0 0 0-.09.09V23.9c0 .052.043.09.09.09h16.175Z"
		/>
	</svg>
</template>
